import { useState } from "react";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";

import useSnackbar from "../../../../../hooks/useSnackbar";

export default function useHandleManagerHistory({
  teamId,
  shipmentId,
}: {
  teamId: number;
  shipmentId: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { data: managerHistoryList } =
    ADMIN_BID_QUERY.useGetForwardingManagerHistory({
      teamId: teamId,
      bidId: shipmentId,
    });

  const [opensManagerHistoryModal, setOpensManagerHistoryModal] =
    useState(false);

  const handleManagerHistoryModalOpen = () => {
    const hasForwardingManagerHistory = managerHistoryList?.length;

    if (!hasForwardingManagerHistory) {
      handleSnackbarOpen("변경 이력이 없습니다.", "warning");
      return;
    }

    setOpensManagerHistoryModal(true);
  };

  return {
    opensManagerHistoryModal,
    setOpensManagerHistoryModal,
    handleManagerHistoryModalOpen,
    managerHistoryList,
  };
}
