import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import ProfitTable from "./ProfitTable";
import Styled from "./index.styles";

const Profit = ({ trelloDetail }: { trelloDetail: TrelloBidDetail }) => {
  const { purchaseManagementInfo, salesManagementInfo } =
    trelloDetail.profitManagementInfo;

  return (
    <Styled.container>
      <div className="title">{"프로핏 관리"}</div>

      <ProfitTable
        teamId={trelloDetail.teamId}
        bidInvoice={trelloDetail.bidInvoice}
        bidId={trelloDetail.id}
        projectStatus={trelloDetail.projectStatus}
        purchaseManagementInfo={purchaseManagementInfo}
        salesManagementInfo={salesManagementInfo}
      />
    </Styled.container>
  );
};

export default Profit;
