import styled from "styled-components";

const modalBody = styled.div`
  > .select-wrapper {
    text-align: center;
    margin-bottom: 40px;

    > .select {
      width: 150px;
    }
  }
`;

export default {
  modalBody,
};
