import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  ExportShipmentInfoUpdate,
  ImportShipmentInfoUpdate,
} from "@sellernote/_shared/src/types/forwarding/adminBid";

import useSnackbar from "../../../../../../hooks/useSnackbar";
import { QuotationChangeTypeAfterCargoUpdate } from "../../../types";

// TODO: 현재 API 배포가 안돼서 성공 실패 로직은 실제 배포 확인 후 추가
export default function useShipmentInfoUpdateRequest({
  shipmentId,
  requestPayload,
  shipmentDetail,
}: {
  shipmentId: number;
  requestPayload:
    | ImportShipmentInfoUpdate
    | ExportShipmentInfoUpdate
    | undefined;
  shipmentDetail: AdminBidDetail;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const queryClient = useQueryClient();

  const [showsQuotationResetModal, setShowsQuotationResetModal] =
    useState(false);

  const { mutate: checkShipmentResetByInfoUpdate } =
    ADMIN_BID_QUERY.useCheckShipmentResetByInfoUpdate();

  const { mutate: updateImportShipmentInfoWithoutRecalculation } =
    ADMIN_BID_QUERY.useImportShipmentInfoUpdateWithoutReCalCulation();

  const { mutate: updateExportShipmentInfoWithoutRecalculation } =
    ADMIN_BID_QUERY.useExportShipmentInfoUpdateWithoutReCalCulation();

  const isImport = shipmentDetail.isImport;

  const handleImportShipmentInfoWithoutRecalculationUpdate = (
    type: QuotationChangeTypeAfterCargoUpdate
  ) => {
    updateImportShipmentInfoWithoutRecalculation(
      {
        pathParams: { shipmentId },
        ...requestPayload,
      },
      {
        onSuccess: () => {
          if (type === "quotationUpdate") {
            history.push(`/bid/change-user-quotation/${shipmentDetail.id}`);
            return;
          }

          history.push(
            `/bid/applyBid/${shipmentDetail.freightType}/${shipmentDetail.id}`
          );
          return;
        },

        onError: () => {
          setShowsQuotationResetModal(false);
          handleSnackbarOpen("수입 화물 정보 수정에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleImportRecalculationCheckAndUpdate = () => {
    const handleExportShipmentItemUpdate = () => {
      updateImportShipmentInfoWithoutRecalculation(
        {
          pathParams: { shipmentId },
          ...requestPayload,
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("수입 화물 정보가 수정되었습니다.");
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: shipmentId,
              })
            );
          },

          onError: () => {
            handleSnackbarOpen("수입 화물 정보 수정에 실패했습니다.", "error");
          },
        }
      );
    };

    /** 확정 견적이 없는 경우 체크 여부 없이 강제 수정을 진행 */
    if (shipmentDetail.quotationsUser.length === 0) {
      handleExportShipmentItemUpdate();
      return;
    }

    checkShipmentResetByInfoUpdate(
      {
        pathParams: { shipmentId },
        ...requestPayload,
      },
      {
        onSuccess: ({ data }) => {
          /** 응답값이 true면 견적 리셋 방법 선택 모달 오픈 */
          if (data) {
            setShowsQuotationResetModal(true);
            return;
          }

          handleExportShipmentItemUpdate();
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleExportShipmentInfoWithoutRecalculationUpdate = (
    type: QuotationChangeTypeAfterCargoUpdate
  ) => {
    updateExportShipmentInfoWithoutRecalculation(
      {
        pathParams: { shipmentId },
        ...requestPayload,
      },
      {
        onSuccess: () => {
          if (type === "quotationUpdate") {
            history.push(`/bid/change-user-quotation/${shipmentDetail.id}`);
            return;
          }

          history.push(
            `/bid/applyBid/${shipmentDetail.freightType}/${shipmentDetail.id}`
          );
          return;
        },

        onError: () => {
          setShowsQuotationResetModal(false);
          handleSnackbarOpen("수출 화물 정보 수정에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleExportRecalculationCheckAndUpdate = () => {
    const handleExportShipmentItemUpdate = () => {
      updateExportShipmentInfoWithoutRecalculation(
        {
          pathParams: { shipmentId },
          ...requestPayload,
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("수출 화물 정보가 수정되었습니다.");
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: shipmentId,
              })
            );
          },

          onError: () => {
            handleSnackbarOpen("수출 화물 정보 수정에 실패했습니다.", "error");
          },
        }
      );
    };

    /** 확정 견적이 없는 경우 체크 여부 없이 강제 수정을 진행 */
    if (shipmentDetail.quotationsUser.length === 0) {
      handleExportShipmentItemUpdate();
      return;
    }

    checkShipmentResetByInfoUpdate(
      {
        pathParams: { shipmentId },
        ...requestPayload,
      },
      {
        onSuccess: ({ data }) => {
          /** 응답값이 true면 견적 리셋 방법 선택 모달 오픈 */
          if (data) {
            setShowsQuotationResetModal(true);
            return;
          }

          /** 응답값이 false면 견적 리셋없이 바로 수정 */
          updateExportShipmentInfoWithoutRecalculation(
            {
              pathParams: { shipmentId },
              ...requestPayload,
            },
            {
              onSuccess: () => {
                handleSnackbarOpen("요청에 성공했습니다.");
                queryClient.invalidateQueries(
                  ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                    bidId: shipmentId,
                  })
                );
              },

              onError: () => {
                handleSnackbarOpen("요청에 실패했습니다.", "error");
              },
            }
          );
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return {
    handleRecalculationCheckAndUpdate: isImport
      ? handleImportRecalculationCheckAndUpdate
      : handleExportRecalculationCheckAndUpdate,
    handleShipmentInfoWithoutRecalculationUpdate: isImport
      ? handleImportShipmentInfoWithoutRecalculationUpdate
      : handleExportShipmentInfoWithoutRecalculationUpdate,
    showsQuotationResetModal,
    setShowsQuotationResetModal,
  };
}
