import { useState } from "react";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

const useCheckCargoManifest = ({ shipmentId }: { shipmentId: number }) => {
  /** 적하목록 모달 */
  const [showsModal, setShowsModal] = useState(false);

  const handleModalOpen = () => setShowsModal(true);

  const handleModalClose = () => {
    setShowsModal(false);
    setTriggersCheckCargoManifest(false);
  };

  /** 적하목록 정보가 존재하는지 확인하는 API 요청 트리거 */
  const [triggersCheckCargoManifest, setTriggersCheckCargoManifest] =
    useState(false);

  const handleCargoManifestCheck = () => setTriggersCheckCargoManifest(true);

  const { data } = TRELLO_BID_QUERY.useCheckCargoManifestExist({
    shipmentId,
    enabled: triggersCheckCargoManifest,
    onSuccess: handleModalOpen,
  });

  return {
    hasCargoManifest: Boolean(data?.result),
    handleCargoManifestCheck,
    showsModal,
    handleModalClose,
  };
};

export default useCheckCargoManifest;
