import { ReactNode } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

function ItemAddSelect({
  itemType,
  value,
  onSelectChange,
  onContainerItemAdd,
}: {
  itemType: "container" | "goods";
  value: string;
  onSelectChange:
    | ((event: SelectChangeEvent<string>, child: ReactNode) => void)
    | undefined;
  onContainerItemAdd: () => void;
}) {
  return (
    <Box display={"flex"}>
      <FormControl
        sx={{
          width: 60,
          "& .MuiInputBase-root": {
            fontSize: "0.75rem",
            padding: "0px 4px",
            minHeight: "30px",
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
          },

          "& .MuiSelect-select": {
            padding: "2px 4px",
          },
        }}
      >
        <Select
          id={`${itemType}-add-select`}
          value={value}
          onChange={onSelectChange}
        >
          <MenuItem value={"1"}>1</MenuItem>
          <MenuItem value={"5"}>5</MenuItem>
          <MenuItem value={"10"}>10</MenuItem>
          <MenuItem value={"20"}>20</MenuItem>
        </Select>
      </FormControl>

      <IconButton
        aria-label={`${itemType}-add`}
        size="small"
        color="primary"
        onClick={onContainerItemAdd}
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
}

export default ItemAddSelect;
