import { Autocomplete, Box, Typography } from "@mui/material";

import { useUpdatedScheduleForm } from "../hooks";
import getCommonTitleStyle from "../utils";
import useShipNameOptionList from "./utils";

import TextField from "../../../../../../../../../components/TextField";

export default function ShipName({
  index,
  shipInfo,
  isFormDisabled,
  highlightsShipName,
  isAIR,
  isConsolOrLCL,
}: {
  index: number;
  shipInfo: {
    name: string;
    IMO: number | null;
  };
  isFormDisabled: boolean;
  highlightsShipName: boolean;
  isAIR: boolean;
  isConsolOrLCL: boolean;
}) {
  const { shipNameOptionList } = useShipNameOptionList({
    includesTBDOption: isConsolOrLCL,
  });

  const { handleFormUpdate } = useUpdatedScheduleForm();

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: 400 }}>
      <Typography style={getCommonTitleStyle(index)} variant="h6">
        모선명
      </Typography>

      <Autocomplete
        size="small"
        disablePortal
        value={{
          IMO: shipInfo.IMO,
          label: `${shipInfo.name}(${shipInfo.IMO})` ?? "",
          value: shipInfo.name ?? "",
        }}
        options={shipNameOptionList}
        style={{ width: "100%", marginTop: 40 }}
        /** 개발자도구에서 equal 경고 문구가 나와 기준을 IMO로 수정 */
        isOptionEqualToValue={(option, value) => option.IMO === value.IMO}
        /** label이 겹치는 경우 중복 리스트가 생성되는걸 막기 위해 key를 포함한 커스텀 리스트 추가 */
        renderOption={(
          props,
          option: {
            IMO: number | null;
            value: string;
            label: string;
          }
        ) => (
          <li {...props} key={`${option.IMO}${option.label}`}>
            {`${option.label}(${option.IMO})`}
          </li>
        )}
        /** AIR 타입 의뢰인 경우 모선명을 사용하지 않음 */
        renderInput={(params) => (
          <TextField
            {...params}
            label="모선명"
            highlightsText={highlightsShipName}
          />
        )}
        onChange={(_, item) => {
          if (!item) return;

          handleFormUpdate({
            index,
            updateFunction: (prev) => ({
              ...prev,
              shipName: item.value,
              shipIMO: item.IMO,
            }),
          });
        }}
        /** AIR 타입 의뢰의 경우 모선명 사용 X */
        disabled={isFormDisabled || isAIR}
      />
    </Box>
  );
}
