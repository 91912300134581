import { Autocomplete, Box, TextField, Typography } from "@mui/material";

import { useUpdatedScheduleForm } from "../hooks";
import { PortOption } from "../types";
import getCommonTitleStyle from "../utils";

import DatePicker from "../../../../../../../../../components/DatePicker";

/**
 * 형제 컴포넌트 <Arrival />와 구조가 같음
 * 복잡한 로직이 추가될 예정이라 일단 분리해서 사용
 */
export default function Departure({
  index,
  portOptionList,
  departureInfo,
  isFormDisabled,
  highlightsDate,
}: {
  index: number;
  portOptionList: PortOption[];
  departureInfo: {
    port: {
      id: number;
      name: string;
      nameEN: string;
    };
    type: null | string;
    date: null | string;
  };
  isFormDisabled: boolean;
  highlightsDate: boolean;
}) {
  const { handleFormUpdate } = useUpdatedScheduleForm();

  const { port, type, date } = departureInfo;

  const hasATDType = type === "ATD";

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: 400 }}>
      <Typography style={getCommonTitleStyle(index)} variant="h6">
        출발지 / ETD
      </Typography>

      <Autocomplete
        size="small"
        disablePortal
        value={{
          value: port.name ?? port.nameEN,
          label: port.name ?? port.nameEN,
          id: port.id,
          name: port.name,
          nameEN: port.nameEN,
        }}
        options={portOptionList}
        style={{ width: "100%", marginTop: 12 }}
        renderInput={(params) => <TextField {...params} label="항구" />}
        onChange={(_, item) => {
          if (!item) return;

          handleFormUpdate({
            index,
            updateFunction: (prev) => ({
              ...prev,
              departurePort: {
                id: item.id,
                name: item.name,
                nameEN: item.nameEN,
              },
            }),
          });
        }}
        /** 첫 번째 출발지 Port는 수정할 수 없음 */
        disabled={isFormDisabled || index === 0}
      />

      <Box
        style={{
          marginTop: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          width={110}
          border={1}
          sx={{
            borderColor: "grey.400",
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography style={{ fontWeight: hasATDType ? "bold" : "inherit" }}>
            {departureInfo.type}
          </Typography>
        </Box>

        <DatePicker
          value={date}
          setDate={(value) => {
            if (!value) return;

            /**
             * 날짜 수정시 사용하는 날짜 타입(ETD, ATD)의 value를 업데이트 시킴
             * ETD, ATD 둘 다 null인 경우, ETD의 value를 업데이트
             */
            handleFormUpdate({
              index,
              updateFunction: (prev) => ({
                ...prev,
                [departureInfo.type ?? "ETD"]: value,
              }),
            });
          }}
          when={"start"}
          disabled={isFormDisabled}
          highlightsText={highlightsDate}
          width={270}
        />
      </Box>
    </Box>
  );
}
