import { Box, Card, Checkbox, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useRecoilState } from "recoil";

import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";
import { bidExporterProps } from "@sellernote/_shared/src/types/forwarding/bidExporter";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";

const ExporterCard = (
  exporterCardInfo: bidExporterProps & { isImport?: boolean }
) => {
  const [exporterId, setExporterId] = useRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_EXPORTER_ID
  );

  const handleExporterInfoUpdate = () => {
    setExporterId(exporterCardInfo.id);
  };

  return (
    <Card variant="outlined">
      <Checkbox
        size="small"
        onClick={handleExporterInfoUpdate}
        checked={exporterCardInfo.id === exporterId}
      />

      <Tooltip title={exporterCardInfo.companyName}>
        <Typography variant="subtitle1" component={"span"}>
          {exporterCardInfo.isImport ? "수출자 회사명" : "수입자 회사명"} :
          {omitWithEllipsis({
            src: exporterCardInfo.companyName,
            maxLength: 25,
            ellipsis: "...",
          })}
        </Typography>
      </Tooltip>

      <Box sx={{ color: grey[500], padding: 1.5 }}>
        <Typography variant="body2">
          담당자 이름 : {exporterCardInfo.personName}
        </Typography>

        <Typography variant="body2">
          담당자 연락처 : {exporterCardInfo.personPhone}
        </Typography>

        <Typography variant="body2">
          담당자 이메일 : {exporterCardInfo.personEmail}
        </Typography>
      </Box>
    </Card>
  );
};

export default ExporterCard;
