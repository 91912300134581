import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

import useHandleManifestInfo from "./hooks";

import CargoManifestInfoForm from "./CargoManifestInfoForm";

export default function CargoManifestInfo({
  cargoManifestInfo,
  shipmentId,
  isImport,
}: {
  cargoManifestInfo: CargoManifestInfoProps;
  shipmentId: number;
  isImport: boolean;
}) {
  const {
    form,
    handleContainerFormChange,
    handleFormChange,
    handleEstimatedTimeChange,
  } = useHandleManifestInfo({ cargoManifestInfo });

  return (
    <CargoManifestInfoForm
      form={form}
      cargoManifestInfo={cargoManifestInfo}
      onFormChange={handleFormChange}
      onContainerFormChange={handleContainerFormChange}
      onEstimatedTimeChange={handleEstimatedTimeChange}
      shipmentId={shipmentId}
      isImport={isImport}
    />
  );
}
