import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { Modal, Spin } from "antd";
import { useSetRecoilState } from "recoil";

import {
  GET_TRELLO_BID_FINISHED_LIST_REQ,
  GET_TRELLO_BID_LIST_REQ,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/SHIP_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";

import useCommentList from "../../../../hooks/useCommentList";
import useHandleDialog from "../hooks";

import BasicInfo from "../components/BasicInfo";
import CargoStatusTable from "../components/CargoStatusTable";
import FreeTime from "../components/FreeTime";
import Profit from "../components/Profit";
import PurchaseManagement from "../components/PurchaseManagement";
import SalesManagement from "../components/SalesManagement";
import ShipmentSchedule from "../components/ShipmentSchedule";
import ShipNameManagement from "../components/ShipNameManagement";
import TrelloModalExitDialog from "../components/TrelloModalExitDialog";
import UploadDocuments from "../components/UploadDocuments";

const ShipdaAdminTrelloDetail = ({
  showModal,
  setShowModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidId: number | undefined;
  trelloListData?: TrelloBidList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) => {
  const queryClient = useQueryClient();

  const [windowWidth] = useWindowResize();

  // 모달의 넓이를 최대한 넓게 해달라는 요청 반영
  const modalWidth = windowWidth - 50;

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const { data: trelloDetailData, isFetching: isTrelloDetailFetching } =
    TRELLO_BID_QUERY.useGetTrelloBidDetail({
      bidId,
    });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    region: trelloDetailData?.locale ?? "KR",
  });

  const { mutate: confirmShipmentSchedule } =
    TRELLO_BID_QUERY.useConfirmShipmentSchedule();

  const { CommentList } = useCommentList({
    commentList: trelloDetailData?.management.comments,
    dataId: trelloDetailData?.id,
    dataType: "trello",
  });

  const {
    opensDialog,
    handleDialogClose,
    handleDialogOpen,
    isShipmentScheduleChanged,
  } = useHandleDialog();

  const handleCloseModal = useCallback(() => {
    setShowModal(false);

    const { region, corpSizeType, blKind, ...trelloParams } =
      fetchTrelloListParams || {};

    if (trelloListData && trelloDetailData) {
      const trelloListParams = {
        ...trelloParams,
        ...(region === "all" ? [] : { region }),
        ...(corpSizeType === "all" ? [] : { corpSizeType }),
        ...(blKind === "all" ? [] : { blKind }),
        status: "inProgress",
        perPage: 300,
        serviceType:
          trelloDetailData.serviceType === "consolidation"
            ? "consolidation"
            : undefined,
      } as GET_TRELLO_BID_LIST_REQ;

      const finishedTrelloListParams = {
        ...trelloParams,
        ...(region === "all" ? [] : { region }),
        ...(corpSizeType === "all" ? [] : { corpSizeType }),
        ...(blKind === "all" ? [] : { blKind }),
        status: "finished",
        projectStatus: "finished",
        perPage: 200,
        serviceType:
          trelloDetailData.serviceType === "consolidation"
            ? "consolidation"
            : undefined,
      } as GET_TRELLO_BID_FINISHED_LIST_REQ;

      // concat으로 진행중 + 종료로 된 데이터를 다시 분리
      const notFinishedTrelloListData = [...trelloListData].filter((v) => {
        return v.projectStatus !== "finished";
      });

      // 종료 상태인 의뢰인 경우
      if (trelloDetailData?.projectStatus === "finished") {
        // 진행중 리스트에 데이터가 있는지 확인
        const targetTrelloListItem = notFinishedTrelloListData.find((v) => {
          return v.id === trelloDetailData.id;
        });

        if (targetTrelloListItem) {
          // 진행중 => 강제 종료로 변경된 경우 진행중과 종료 둘 다 데이터가 남아있기 때문에 진행중에서는 제거해야함
          const newTrelloListData = notFinishedTrelloListData.filter((v) => {
            return v.id !== trelloDetailData.id;
          });

          const oldQueryData = queryClient.getQueryData(
            TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
              ...trelloListParams,
            })
          );

          if (typeof oldQueryData === "object") {
            queryClient.setQueryData(
              TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
                ...trelloListParams,
              }),
              { ...oldQueryData, data: newTrelloListData }
            );
          }
        }

        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getTrelloBidFinishedList({
            ...finishedTrelloListParams,
          })
        );

        return;
      }

      // 진행 중 상태인 의뢰인 경우
      const oldQueryData = queryClient.getQueryData(
        TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
          ...trelloListParams,
        })
      );

      // 변경한 의뢰번호를 확인해서 인덱스를 찾음
      const targetDataIndex = notFinishedTrelloListData.findIndex((v) => {
        return v.id === trelloDetailData.id;
      });

      // 만약 인덱스를 찾지 못한다면 전체 데이터를 다시 요청(정산 요청을 하게 되는 경우 정산 테이블로 빠지게 되는 경우가 있음) find에서 찾지 못 할 경우 -1를 리턴
      if (targetDataIndex === -1) {
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloBidList());
        return;
      }

      notFinishedTrelloListData[targetDataIndex] = {
        accountPayables: trelloDetailData.accountPayables,
        attachmentFlag:
          notFinishedTrelloListData[targetDataIndex]?.attachmentFlag,
        attachments: notFinishedTrelloListData[targetDataIndex].attachments,
        containCustoms: trelloDetailData.containCustoms,
        endAddress: trelloDetailData.endAddress,
        endAddressDetail: trelloDetailData.endAddressDetail,
        endCountry: trelloDetailData.endCountry,
        invoices: notFinishedTrelloListData[targetDataIndex].invoices,
        freightType: trelloDetailData.freightType,
        id: trelloDetailData.id,
        incoterms: trelloDetailData.incoterms,
        /** 파란색 테두리를 없애기 위해 의도적으로 null 처리, 실제 API는 line 202에서 요청 */
        management: {
          ...trelloDetailData.management,
          externalApiUpdatedAt: null,
        },
        poNumber: notFinishedTrelloListData[targetDataIndex].poNumber,
        projectStatus: trelloDetailData.projectStatus,
        serviceType: trelloDetailData.serviceType,
        startAddress: trelloDetailData.startAddress,
        startCountry: trelloDetailData.startCountry,
        team: trelloDetailData.team,
        region: trelloDetailData.locale,
        isImport: trelloDetailData.isImport,
        freeTimeLFD: notFinishedTrelloListData[targetDataIndex].freeTimeLFD,
        opCheckPoint: notFinishedTrelloListData[targetDataIndex].opCheckPoint,
        closingInvoice:
          notFinishedTrelloListData[targetDataIndex].closingInvoice,
      };

      // oldQueryData의 타입이 unknown이라서 스프레드 사용 불가 타입체크를 추가
      if (typeof oldQueryData === "object") {
        queryClient.setQueryData(
          TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
            ...trelloListParams,
          }),
          { ...oldQueryData, data: notFinishedTrelloListData }
        );
      }

      /** 파란색 테두리(유니패스, 마린트래픽, 선사에 의해 스케줄 변경)가 있는 경우 스케줄 변경 확인 API 호출 */
      if (trelloDetailData.management.externalApiUpdatedAt) {
        confirmShipmentSchedule(
          {
            pathParams: {
              shipmentId: trelloDetailData?.id,
            },
          },
          {
            onError: () =>
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.trelloBidList()
              ),
          }
        );
      }

      return;
    }
  }, [
    confirmShipmentSchedule,
    fetchTrelloListParams,
    queryClient,
    setShowModal,
    trelloDetailData,
    trelloListData,
  ]);

  const { projectStatus, management } = trelloDetailData ?? {};

  // 출항완료 이후 상태인지 확인. (출항완료 포함)
  const isProjectStatusAfterMoving =
    projectStatus === "moving" ||
    projectStatus === "portEntryAndPrepareCustoms" ||
    projectStatus === "payment" ||
    projectStatus === "completeCustoms" ||
    projectStatus === "delivering" ||
    projectStatus === "finished" ||
    projectStatus === "inSettlement" ||
    projectStatus === "settlementRejected" ||
    projectStatus === "settlementConfirmed" ||
    projectStatus === "settlementSecondConfirmed" ||
    projectStatus === "settlementComplete";

  // BL 입력 완료 여부 확인
  const isCompleteBLInput =
    typeof management?.liner?.seaRatesAvailable === "boolean";

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  if (!trelloDetailData || !shipList || !exchangeRateList || !partnerList) {
    return (
      <Modal
        destroyOnClose={true}
        style={{ fontFamily: "Roboto" }}
        bodyStyle={{ height: 800, overflow: "scroll" }}
        visible={showModal}
        width={modalWidth}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Spin />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        destroyOnClose={true}
        style={{ fontFamily: "Roboto" }}
        bodyStyle={{ height: 800, overflow: "scroll" }}
        visible={showModal}
        width={modalWidth}
        onCancel={() => {
          /** 스케줄 변경이 있으면, 모달을 닫을 건지 확인하는 Dialog가 노출 */
          if (isShipmentScheduleChanged) {
            return handleDialogOpen();
          }

          handleCloseModal();
        }}
        footer={null}
      >
        <BasicInfo trelloDetail={trelloDetailData} partnerList={partnerList} />

        <ShipNameManagement trelloDetail={trelloDetailData} />

        {isProjectStatusAfterMoving && isCompleteBLInput && (
          <FreeTime trelloDetail={trelloDetailData} />
        )}

        <ShipmentSchedule trelloDetail={trelloDetailData} />

        <CargoStatusTable trelloDetail={trelloDetailData} />

        <UploadDocuments trelloDetail={trelloDetailData} />

        <SalesManagement trelloDetailData={trelloDetailData} />

        <PurchaseManagement
          trelloDetailData={trelloDetailData}
          partnerList={partnerList}
          exchangeRateList={exchangeRateList}
        />

        <Profit trelloDetail={trelloDetailData} />

        {CommentList}
      </Modal>

      <TrelloModalExitDialog
        opensDialog={opensDialog}
        onDialogClose={handleDialogClose}
        onModalClose={handleCloseModal}
      />
    </>
  );
};

export default ShipdaAdminTrelloDetail;
