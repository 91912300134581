import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton } from "@mui/material";

export default function PreviewFooter({
  onPreviewIndexChange,
  isPreviousButtonDisabled,
  isNextButtonDisabled,
}: {
  onPreviewIndexChange: (v: "+" | "-") => void;
  isPreviousButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
}) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
      <IconButton
        aria-label="previous-image"
        onClick={() => onPreviewIndexChange("-")}
        disabled={isPreviousButtonDisabled}
      >
        <ArrowBackIosIcon />
      </IconButton>

      <IconButton
        aria-label="next-image"
        onClick={() => onPreviewIndexChange("+")}
        disabled={isNextButtonDisabled}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
}
