import { useCallback, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useAtomValue } from "jotai";

import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../jotaiStates/auth";
import ManageShipNameModal from "../ManageShipNameModal";
import {
  DISABLED_OPTION_LIST,
  FINANCE_USER_DISABLED_OPTION_LIST,
} from "./SHIP_NAME_BUTTON_DISABLED_OPTION_LIST";

export default function ShipNameManagement({
  trelloDetail,
}: {
  trelloDetail: TrelloBidDetail;
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const isFreightTypeAir = trelloDetail.freightType === "AIR";

  const [showsManageShipNameModal, setShowsManageShipNameModal] =
    useState(false);

  const checkIfButtonIsDisabled = useCallback(() => {
    if (
      currentAdminAuthInfo?.authority === "finance" &&
      FINANCE_USER_DISABLED_OPTION_LIST.includes(trelloDetail.projectStatus)
    ) {
      return true;
    }

    return DISABLED_OPTION_LIST.includes(trelloDetail.projectStatus);
  }, [currentAdminAuthInfo?.authority, trelloDetail.projectStatus]);

  return (
    <Grid container justifyContent={"space-between"}>
      {!isFreightTypeAir && (
        <Grid item>
          <Button
            color="success"
            variant="contained"
            disabled={checkIfButtonIsDisabled()}
            onClick={() => setShowsManageShipNameModal(true)}
          >
            모선명 관리
          </Button>
        </Grid>
      )}

      <ManageShipNameModal
        showsManageShipNameModal={showsManageShipNameModal}
        setShowsManageShipNameModal={setShowsManageShipNameModal}
      />
    </Grid>
  );
}
