import { useMemo } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Grid, Typography } from "@mui/material";

import { AdminBidList } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { CustomsAdminBidList } from "@sellernote/_shared/src/types/forwarding/customsAdminBid";
import { getCountryCodeKR } from "@sellernote/_shared/src/utils/common/country";
import { isNotEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { getSupplyType } from "@sellernote/_shared/src/utils/forwarding/bid";

import TagForAdminBidList from "./TagForAdminBidList";

function ShipmentTransInfo({
  adminBidListData,
}: {
  adminBidListData: AdminBidList | CustomsAdminBidList;
}) {
  const {
    startPort,
    startViaPort,
    endPort,
    endViaPort,
    incoterms,
    startCountry,
    endCountry,
    endAddress,
    startAddress,
    freightType,
    isImport,
  } = adminBidListData;

  const startPointLabel = useMemo(() => {
    const isIncotermsC = incoterms.startsWith("C");

    if (isImport && isIncotermsC) {
      // 수입 C조건에만 적용
      const startCountryName = startPort
        ? getCountryCodeKR(startPort.country)
        : "-";
      const endViaPortName = endViaPort
        ? endViaPort.name || endViaPort.nameEN
        : "-";

      const startPortName = startPort
        ? `${startPort.name || startPort.nameEN}`
        : "-";
      return `(${startCountryName} ${startPortName}), ${endViaPortName}`;
    }

    const isStartedFromInland = !!startAddress;
    const startCountryName = getCountryCodeKR(startCountry);

    const targetProt = isStartedFromInland ? startViaPort : startPort;
    if (!targetProt) {
      // 정상적인 경우 targetPort는 항상 있으나, dev테스트시 정규화되지 않은 데이터가 들어가있는 경우 오류가 날 수 있어 방어코드 추가
      return "-";
    }

    return isStartedFromInland
      ? `${startCountryName} 내륙(${targetProt.name || targetProt.nameEN})`
      : `${startCountryName} ${targetProt.name || targetProt.nameEN}`;
  }, [
    endViaPort,
    incoterms,
    isImport,
    startAddress,
    startCountry,
    startPort,
    startViaPort,
  ]);

  const endPointLabel = useMemo(() => {
    const isIncotermsC = incoterms.startsWith("C");
    const endCountryName = getCountryCodeKR(endCountry);

    if (isImport && isIncotermsC) {
      // 수입 C조건에만 적용
      if (startViaPort && isNotEmptyObjectOrArray(startViaPort)) {
        return startViaPort.name || startViaPort.nameEN;
      }

      if (endPort && isNotEmptyObjectOrArray(endPort)) {
        return endPort.name || endPort.nameEN;
      }

      if (endAddress && endViaPort && isNotEmptyObjectOrArray(endViaPort)) {
        return `${endCountryName} 내륙:${endAddress}`;
      }

      return "-";
    }

    const isEndedToInland = !!endAddress;

    const targetProt = isEndedToInland ? endViaPort : endPort;
    if (!targetProt) {
      // 정상적인 경우 targetPort는 항상 있으나, dev테스트시 정규화되지 않은 데이터가 들어가있는 경우 오류가 날 수 있어 방어코드 추가
      return "-";
    }

    return isEndedToInland
      ? `${endCountryName} 내륙(${endViaPort.name || endViaPort.nameEN})`
      : `${endCountryName} ${endPort.name || endPort.nameEN}`;
  }, [
    endAddress,
    endCountry,
    endPort,
    endViaPort,
    incoterms,
    isImport,
    startViaPort,
  ]);

  const FreightTypeTag = useMemo(() => {
    if (adminBidListData.freightType === "FCL") {
      return (
        <TagForAdminBidList color="orange">
          {adminBidListData.freightType}
        </TagForAdminBidList>
      );
    }
    if (adminBidListData.freightType === "LCL") {
      return (
        <TagForAdminBidList color="blue">
          {adminBidListData.freightType}
        </TagForAdminBidList>
      );
    }
    return (
      <TagForAdminBidList color="green">
        {adminBidListData.freightType}
      </TagForAdminBidList>
    );
  }, [adminBidListData.freightType]);

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      spacing={0.3}
    >
      <Grid
        item
        container
        direction={"row"}
        spacing={0.5}
        textAlign={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        justifyItems={"center"}
      >
        <Grid item>
          <Typography>{startPointLabel}</Typography>
        </Grid>

        <Grid item>
          <DoubleArrowIcon sx={{ marginTop: 0.5 }} color={"disabled"} />
        </Grid>

        <Grid item>
          <Typography>{endPointLabel}</Typography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={0.5}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item>
          <TagForAdminBidList color="pink">
            {adminBidListData.incoterms}
          </TagForAdminBidList>
        </Grid>

        <Grid item>{FreightTypeTag}</Grid>

        <Grid item>
          <TagForAdminBidList color="purple">
            {adminBidListData.supply}

            {getSupplyType(freightType)}
          </TagForAdminBidList>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ShipmentTransInfo;
