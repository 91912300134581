import { useMemo } from "react";
import { UseFormWatch } from "react-hook-form";

import { HouseDeliveryOrderDocumentForm } from "@sellernote/_shared/src/types/forwarding/trello";

import filledLogo from "../../../../../../../images/createDocument/filledLogo.png";
import stamp from "../../../../../../../images/createDocument/stamp.png";

export default function useHouseDeliveryOrderDocumentPreview(
  watch: UseFormWatch<HouseDeliveryOrderDocumentForm>,
  containersNumberInfo: string[] | null | undefined,
  docNum: string
) {
  const {
    blNumber,
    shipper,
    consignee,
    notiInfo,
    departureInfo,
    arrivalInfo,
    arrivalDate,
    voyageNumber,
    prnm,
    packageInfo,
    weight,
    cbm,
  } = watch();

  const containerTable = useMemo(() => {
    if (!containersNumberInfo) {
      return `<div style="display: flex;">
      <div style="text-align: center;min-width:160px;padding: 8px 10px;">CNTR No 정보가 없습니다.</div>
      <div style="text-align: center;padding: 8px 10px;min-width: 151px;word-wrap: break-word;min-height:203px;">
        ${prnm?.toUpperCase() || ""}
      </div>
      <div style="text-align: center;min-width:80px;padding: 8px 10px;word-wrap: break-word;">${
        packageInfo?.toUpperCase() || ""
      }</div>
      <div style="text-align: center;min-width:80px;padding: 8px 10px;word-wrap: break-word;">${
        weight?.toUpperCase() || ""
      }</div>
      <div style="text-align: center;min-width:80px;padding: 8px 10px;word-wrap: break-word;">${
        cbm?.toUpperCase() || ""
      }</div>
  </div>`;
    }
    let containerTableRow = ``;

    containersNumberInfo.forEach((v) => {
      containerTableRow += `<div style="height: 29px;word-wrap: break-word;">${v.toUpperCase()}</div>`;
    });

    return `<div style="display: flex;">
    <div style="text-align: center;min-width:160px;padding: 8px 10px;">${containerTableRow}</div>
    <div style="text-align: center;padding: 8px 10px;min-width: 151px;word-wrap: break-word;min-height:203px;">
      ${prnm?.toUpperCase() || ""}
    </div>
    <div style="text-align: center;min-width:80px;padding: 8px 10px;word-wrap: break-word;">${
      packageInfo?.toUpperCase() || ""
    }</div>
    <div style="text-align: center;min-width:80px;padding: 8px 10px;word-wrap: break-word;">${
      weight?.toUpperCase() || ""
    }</div>
    <div style="text-align: center;min-width:80px;padding: 8px 10px;word-wrap: break-word;">${
      cbm?.toUpperCase() || ""
    }</div>
</div>`;
  }, [cbm, containersNumberInfo, packageInfo, prnm, weight]);

  const houseDeliveryOrderHtml = useMemo(() => {
    return `<!DOCTYPE html>
    <html lang="ko">
      <head>
        <link
          href="https://fonts.googleapis.com/css?family=IBM Plex Mono"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto Mono"
          rel="stylesheet"
        />
        <link
          href="https://webfontworld.github.io/sunn/SUIT.css"
          rel="stylesheet"
        />
        <meta charset="UTF-8" />
        <title>Title</title>
        <style type="text/css"></style>
      </head>
    
      <body
        style="
          font-family: SUIT;
          width: 595px;
          background-color: #ffffff;
          font-family: IBM Plex Mono;
        "
      >
        <div
          style="padding: 24px; line-height: 12px; color: #000000; font-size: 10px"
        >
          <div
            style="
              padding: 10px;
              border: 1px solid #737373;
              font-size: 20px;
              line-height: 26px;
              text-align: center;
              font-weight: 500;
            "
          >
            DELIVERY ORDER
          </div>
    
          <div style="margin-top: 8px; border: 1px solid #737373">
            <div style="display: flex; height: 101px">
              <div style="width: 320px; border-right: 1px solid #737373">
                <div style="padding: 8px 11px 10px 10px">
                  <div style="font-family: SUIT; margin-bottom: 7px">Shipper</div>
    
                  <div
                    style="
                      font-family: Roboto Mono;
                      word-break: break-all;
                      white-space: normal;
                      display: inline-block;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    ${shipper?.toUpperCase() || ""}
                  </div>
                </div>
              </div>
    
              <div style="width: 228px">
                <div style="border-bottom: 1px solid #737373; height: 51px">
                  <div style="font-family: SUIT; padding: 7.5px 10px 8px">
                    B/L NO.
                  </div>
    
                  <div
                    style="
                      font-family: Roboto Mono;
                      padding: 0px 10px;
                      font-size: 12px;
                    "
                  >
                    ${blNumber?.toUpperCase() || ""}
                  </div>
                </div>
    
                <div style="height: 50px">
                  <div style="font-family: SUIT; padding: 7.5px 10px 8px">
                    D/O NO.
                  </div>
    
                  <div
                    style="
                      font-family: Roboto Mono;
                      padding: 0px 10px;
                      font-size: 12px;
                    "
                  >
                   ${docNum?.toUpperCase() || ""}
                  </div>
                </div>
              </div>
            </div>
    
            <div style="display: flex; border-top: 1px solid #737373">
              <div style="width: 320px; border-right: 1px solid #737373">
                <div
                  style="
                    padding: 8px 11px 10px 10px;
                    border-bottom: 1px solid #737373;
                  "
                >
                  <div style="font-family: SUIT; margin-bottom: 7px">Consignee</div>
    
                  <div
                    style="
                      font-family: Roboto Mono;
                      word-break: break-all;
                      white-space: normal;
                      display: inline-block;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    ${consignee?.toUpperCase() || ""}
                  </div>
                </div>
                <div style="height: 52px">
                  <div style="padding: 8px 11px 10px 10px">
                    <div style="font-family: SUIT; margin-bottom: 7px">
                      Notify Party
                    </div>
    
                    <div
                      style="
                        font-family: Roboto Mono;
                        word-break: break-all;
                        white-space: normal;
                        display: inline-block;
                        font-size: 12px;
                        line-height: 16px;
                      "
                    >
                      ${notiInfo?.toUpperCase() || ""}
                    </div>
                  </div>
                </div>
              </div>
    
              <div style="width: 228px; height: 153px">
                <div style="padding: 20.5px 0px 0px 16px">
                  <img
                    alt=""
                    src="${filledLogo}"
                    style="width: 78.4px; height: 32px"
                  />
    
                  <div style="display: flex; margin-top: 16px">
                    <div
                      style="
                        width: 24px;
                        margin-right: 10px;
                        font-family: SUIT;
                        line-height: 12px;
                      "
                    >
                      Tel.
                    </div>
    
                    <div style="font-family: Roboto Mono; line-height: 13px">
                      1544-7789
                    </div>
                  </div>
    
                  <div style="display: flex; margin-top: 8px">
                    <div
                      style="
                        width: 24px;
                        margin-right: 10px;
                        font-family: SUIT;
                        line-height: 12px;
                      "
                    >
                      Fax.
                    </div>
    
                    <div style="font-family: Roboto Mono; line-height: 13px">
                      0505-901-7210
                    </div>
                  </div>
    
                  <div
                    style="
                      display: flex;
                      margin-top: 8px;
                      justify-content: flex-start;
                    "
                  >
                    <div
                      style="
                        min-width: 24px;
                        margin-right: 10px;
                        font-family: SUIT;
                        line-height: 12px;
                      "
                    >
                      Add.
                    </div>
    
                    <div style="font-family: Roboto Mono; line-height: 13px">
                      89, Saemunan-ro, Jongno-gu, Seoul, Republic of Korea
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div style="display: flex; height: 53px; border-top: 1px solid #737373">
              <div style="border-right: 1px solid #737373; margin: 0px -1px">
                <div style="width: 160px">
                  <div
                    style="
                      padding: 8px 10px 4px 10px;
                      font-family: SUIT;
                      line-height: 12px;
                    "
                  >
                    Port of Loading
                  </div>
    
                  <div
                    style="
                      padding: 4px 10px 10px 10px;
                      line-height: 16px;
                      font-family: Roboto Mono;
                      word-break: break-all;
                      white-space: normal;
                      display: inline-block;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    ${departureInfo?.toUpperCase() || ""}
                  </div>
                </div>
              </div>
    
              <div>
                <div>
                  <div
                    style="
                      padding: 8px 10px 4px 10px;
                      font-family: SUIT;
                      line-height: 12px;
                    "
                  >
                    Final Destination
                  </div>
    
                  <div
                    style="
                      padding: 4px 10px 10px 10px;
                      line-height: 16px;
                      font-family: Roboto Mono;
                      word-break: break-all;
                      white-space: normal;
                      display: inline-block;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    ${arrivalInfo?.toUpperCase() || ""}
                  </div>
                </div>
              </div>
            </div>
    
            <div style="display: flex; height: 69px; border-top: 1px solid #737373">
              <div style="border-right: 1px solid #737373; margin: 0px -1px">
                <div style="width: 160px">
                  <div
                    style="
                      padding: 8px 10px 4px 10px;
                      font-family: SUIT;
                      line-height: 12px;
                    "
                  >
                    Arrival Date
                  </div>
    
                  <div
                    style="
                      padding: 4px 10px 10px 10px;
                      line-height: 16px;
                      font-family: Roboto Mono;
                      word-break: break-all;
                      white-space: normal;
                      display: inline-block;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    ${arrivalDate?.toUpperCase() || ""}
                  </div>
                </div>
              </div>
    
              <div>
                <div>
                  <div
                    style="
                      font-family: SUIT;
                      padding: 8px 10px 4px 10px;
                      line-height: 12px;
                    "
                  >
                    VSL / VYG
                  </div>
    
                  <div
                    style="
                      font-family: Roboto Mono;
                      padding: 4px 10px 10px 10px;
                      font-size: 12px;
                      line-height: 16px;
                    "
                  >
                    ${voyageNumber?.toUpperCase() || ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <table
            style="
              table-layout: fixed;
              border-collapse: collapse;
              width: 547px;
              margin-top: 24px;"
          >
            <thead>
              <tr>
                <th
                  style="
                    border: 1px solid #737373;
                    font-weight: 400;
                    font-family: SUIT;
                    width: 159px;
                    padding: 0px;
                    height: 27px;
                  "
                >
                  CNTR No.
                </th>
                <th
                  style="
                    border: 1px solid #737373;
                    font-weight: 400;
                    font-family: SUIT;
                    width: 148px;
                    padding: 0px;
                    height: 27px;
                  "
                >
                  Description
                </th>
                <th
                  style="
                    border: 1px solid #737373;
                    font-weight: 400;
                    font-family: SUIT;
                    width: 78px;
                    padding: 0px;
                    height: 27px;
                    font-size: 10px;
                  "
                >
                  No. of Package
                </th>
                <th
                  style="
                    border: 1px solid #737373;
                    font-weight: 400;
                    font-family: SUIT;
                    width: 78px;
                    padding: 0px;
                    height: 27px;
                  "
                >
                  Gross Weight
                </th>
                <th
                  style="
                    border: 1px solid #737373;
                    font-weight: 400;
                    font-family: SUIT;
                    width: 78px;
                    height: 27px;
                    padding: 0px;
                  "
                >
                  CBM
                </th>
              </tr>
            </thead>
          </table>

          ${containerTable}
    
          <div
            style="
              padding-left: 346px;
              font-family: 'Roboto Mono';
              line-height: 13px;
              margin-bottom: 8px;
            "
          >
            SELLER NOTE CO., LTD
          </div>
    
          <div
            style="
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
            "
          >
            <div>*본 D/O를 제출한 수하인에게 화물 인도를 승락합니다.</div>
            <img src="${stamp}" alt="도장" width="201px" height="90px" />
          </div>
        </div>
      </body>
    </html>`;
  }, [
    arrivalDate,
    arrivalInfo,
    blNumber,
    consignee,
    containerTable,
    departureInfo,
    docNum,
    notiInfo,
    shipper,
    voyageNumber,
  ]);

  return houseDeliveryOrderHtml;
}
