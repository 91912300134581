import { Box, Typography } from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { AdminBidList } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { CustomsAdminBidList } from "@sellernote/_shared/src/types/forwarding/customsAdminBid";

function ShipmentApplyInfo({
  adminBidListData,
}: {
  adminBidListData: AdminBidList | CustomsAdminBidList;
}) {
  return (
    <Box>
      <Typography>
        운임보유: {adminBidListData.isFixed ? "O" : "X"}/ 오션티켓:{" "}
        {adminBidListData.serviceType === "oceanTicket" ? "O" : "X"}
      </Typography>

      {APP_NAME === "shipda-admin" &&
        "quotationForwarders" in adminBidListData && (
          <Typography>
            견적 지원 수: {adminBidListData.quotationForwarders.length}/
            자동견적: {adminBidListData.isFixed ? "O" : "X"}
          </Typography>
        )}
    </Box>
  );
}
export default ShipmentApplyInfo;
