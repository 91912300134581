import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  checkIfHaveAuthorityToChangeTrelloDetail,
  returnEndAddress,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../jotaiStates/auth";
import EndAddressFormModal from "./EndAddressFormModal";

function EndInland({
  trelloDetail,
  bidAccountPayableId,
}: {
  trelloDetail: TrelloBidDetail;
  bidAccountPayableId: number | undefined;
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [opensEndAddressFormModal, setOpensEndAddressFormModal] =
    useState(false);

  const getDisabledInlandUpdateButton = () => {
    if (trelloDetail.bidInvoice) {
      if (trelloDetail.bidInvoice.issuedInvoices.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <Box>
      {returnEndAddress(trelloDetail)}

      {APP_NAME === "shipda-admin" && (
        <Button
          disabled={
            checkIfHaveAuthorityToChangeTrelloDetail(
              currentAdminAuthInfo?.authority,
              trelloDetail.projectStatus
            ) || getDisabledInlandUpdateButton()
          }
          onClick={() => setOpensEndAddressFormModal(true)}
        >
          {trelloDetail.endAddress ? "수정" : "등록"}
        </Button>
      )}

      {opensEndAddressFormModal && (
        <EndAddressFormModal
          trelloDetail={trelloDetail}
          opensEndAddressFormModal={opensEndAddressFormModal}
          setOpensEndAddressFormModal={setOpensEndAddressFormModal}
          bidAccountPayableId={bidAccountPayableId}
        />
      )}
    </Box>
  );
}

export default EndInland;
