import React, { useCallback, useState } from "react";
import { Button, Input, Modal } from "antd";

import ADMIN_SLACK_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SLACK_QUERY";

const RejectSettlementModal = ({
  setShowRejectSettlementModal,
  showRejectSettlementModal,
  bidId,
}: {
  setShowRejectSettlementModal: React.Dispatch<React.SetStateAction<boolean>>;
  showRejectSettlementModal: boolean;
  bidId: number;
}) => {
  const [rejectReason, setRejectReason] = useState("");

  const {
    mutate: rejectSettlement,
    ResponseHandler: ResponseHandlerOfUseRejectSettlement,
  } = ADMIN_SLACK_QUERY.useRejectSettlement({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setShowRejectSettlementModal(false);
      },
      customizeMessage: () => ({
        title: "정산반려 슬랙 알람을 발송했습니다.",
      }),
    },
  });

  const handleModalClose = useCallback(() => {
    setShowRejectSettlementModal(false);
  }, [setShowRejectSettlementModal]);

  const handleRejectSettlementClick = useCallback(() => {
    rejectSettlement({
      bidId,
      rejectReason: !rejectReason ? "사유 미기재" : rejectReason,
    });
  }, [bidId, rejectReason, rejectSettlement]);

  const handleRejectReasonInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRejectReason(e.target.value);
    },
    []
  );

  return (
    <Modal
      destroyOnClose={true}
      onCancel={handleModalClose}
      bodyStyle={{ padding: "12px 16px 40px" }}
      width={"20%"}
      title="정산반려 요청 사유입력"
      footer={null}
      visible={showRejectSettlementModal}
    >
      <Input
        placeholder="반려 요청 사유를 입력하세요"
        value={rejectReason}
        onChange={handleRejectReasonInputChange}
      ></Input>

      <Button
        disabled={!rejectReason}
        onClick={handleRejectSettlementClick}
        style={{ marginTop: 16 }}
        type="primary"
      >
        요청하기
      </Button>

      {ResponseHandlerOfUseRejectSettlement}
    </Modal>
  );
};

export default RejectSettlementModal;
