import { Box, Button, Typography } from "@mui/material";

import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

import Modal from "../../../../../components/Modal";

export default function ManagerHistoryModal({
  opensModal,
  onModalClose,
  managerHistoryList,
  adminUserList,
}: {
  opensModal: boolean;
  onModalClose: () => void;
  managerHistoryList:
    | {
        changedAt: Date;
        forwardingManagerId: number;
      }[]
    | undefined;
  adminUserList: ForwardingAdminUserListItem[];
}) {
  const hasManagerHistory = managerHistoryList?.length;

  if (!hasManagerHistory) return null;

  const getAdminName = (adminId: number) => {
    const user = adminUserList.find(({ id }) => id === adminId);
    return user?.name || "-";
  };

  const History = ({ name, date }: { name: string; date: string }) => {
    return (
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography component={"span"}>{name}</Typography>

        <Typography component={"span"} color={"#808080"}>
          {date}
        </Typography>
      </Box>
    );
  };

  return (
    <Modal
      isOpened={opensModal}
      handleClose={onModalClose}
      modalBody={
        <Box display={"flex"} flexDirection={"column"} width={300} gap={1}>
          <Typography component={"span"} fontWeight={"bold"} sx={{ mb: 3 }}>
            담당자 변경 이력
          </Typography>

          {managerHistoryList?.map((manager) => (
            <History
              key={manager.forwardingManagerId}
              name={getAdminName(manager.forwardingManagerId)}
              date={toFormattedDate(manager.changedAt, "YYYY.MM.DD hh:mm:ss a")}
            />
          ))}

          <Button
            variant="contained"
            sx={{ mt: 3 }}
            size="small"
            onClick={onModalClose}
          >
            확인
          </Button>
        </Box>
      }
    />
  );
}
