import {
  ShipmentInfo,
  ShipmentScheduleRoute,
} from "@sellernote/_shared/src/types/forwarding/trello";

type ScheduleKey = keyof ShipmentScheduleRoute;

/**
 * @example
 * ET가 있을땐 ET, AT가 있을 땐 AT를 노출
 * ET, AT가 둘 다 있을 땐 AT를 노출
 *
 * @param ET Estimated Time (ETD, ATD)
 * @param AT Actual Time (ATD, ATA)
 *
 * @see useUpdateShipmentSchedule 스케줄 변경 API 요청에 필요한 데이터를 가공
 * @see Departure.tsx ETD, ATD 노출 조건 처리
 * @see Arrival.tsx ETA, ATA 노출 조건 처리
 */
function getScheduleTime({
  ET,
  AT,
  isDeparture,
}: {
  ET: string | null;
  AT: string | null;
  isDeparture?: boolean;
}): {
  type: string | null;
  date: string | null;
} {
  /** ET, AT의 값이 둘 다 null일 때 */
  if (!ET && !AT) {
    return { type: null, date: null };
  }

  /** ET, AT의 값이 둘 다 존재할 때 */
  if (ET && AT) {
    return {
      type: isDeparture ? "ATD" : "ATA",
      date: AT,
    };
  }

  const type = isDeparture ? (AT ? "ATD" : "ETD") : AT ? "ATA" : "ETA";
  const date = ET || AT;

  return { type, date };
}

/**
 * 스케줄을 변경했을 때 항차만 변경됐는지 확인하는 함수
 *
 * @param current API응답으로 받은 스케줄 리스트, 업데이트 가능
 * @param prev API응답으로 받은 스케줄 리스트, 스케줄 비교를 위해 저장, 업데이트 불가
 */
function checkOnlyVoyageNoChanged(
  current: readonly ShipmentScheduleRoute[],
  prev: readonly ShipmentScheduleRoute[]
): boolean {
  if (current.length !== prev.length) return false;

  return current.some((obj, index) => {
    const prevObj = prev[index];
    const changedKeys = Object.keys(obj).filter(
      (key) => obj[key as ScheduleKey] !== prevObj[key as ScheduleKey]
    );

    return changedKeys.length === 1 && changedKeys[0] === "voyageNo";
  });
}

/**
 * 스케줄 입력, 변경 payload에 필요한 데이터 형태로 가공
 *
 * @param list FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
 */
function getScheduleListForPayload(
  list: ShipmentScheduleRoute[]
): ShipmentInfo[] {
  return list.map((form) => {
    return {
      /** 출발지 정보 */
      departurePortId: form.departurePort.id,
      departureDate:
        getScheduleTime({ ET: form.ETD, AT: form.ATD }).date ?? form.ETD ?? "",
      /** 도착지 정보 */
      arrivalPortId: form.arrivalPort.id,
      arrivalDate:
        getScheduleTime({ ET: form.ETA, AT: form.ATA }).date ?? form.ETA ?? "",
      /** 선박 정보 */
      shipIMO: form.shipIMO,
      shipName: form.shipName,
      voyageNo: form.voyageNo,
    };
  });
}

export { getScheduleTime, checkOnlyVoyageNoChanged, getScheduleListForPayload };
