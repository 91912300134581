import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";

import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

function QuotationUserMailModal({
  showsQuotationUserMailModal,
  onQuotationUserMailModalClose,
  onQuotationUserUpdate,
}: {
  showsQuotationUserMailModal: boolean;
  onQuotationUserMailModalClose: () => void;
  onQuotationUserUpdate: (allowsSendmail: boolean) => void;
}) {
  const [allowsSendMail, setAllowsSendMail] = useState(false);

  return (
    <Modal
      isOpened={showsQuotationUserMailModal}
      handleClose={onQuotationUserMailModalClose}
      modalBody={
        <>
          <Box mb={2}>
            <Typography variant="h6">확정견적을 수정 하시겠습니까?</Typography>

            <FormControlLabel
              control={
                <Checkbox
                  checked={allowsSendMail}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setAllowsSendMail(e.target.checked);
                  }}
                />
              }
              label="화주에게 견적 수정사항을 안내하는 메일을 발송"
            />
          </Box>

          <Box display="flex" gap={1} justifyContent={"flex-end"}>
            <Button
              variant="outlined"
              color="error"
              onClick={onQuotationUserMailModalClose}
            >
              취소
            </Button>

            <Button
              variant="contained"
              onClick={() => onQuotationUserUpdate(allowsSendMail)}
            >
              수정
            </Button>
          </Box>
        </>
      }
    />
  );
}

export default QuotationUserMailModal;
