import { Grid, Typography } from "@mui/material";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { toThousandUnitFormatWithDecimal } from "@sellernote/_shared/src/utils/common/number";

function ConsolidationTotalInfo({ bidDetail }: { bidDetail: AdminBidDetail }) {
  return (
    <Grid container sx={{ marginTop: "32px" }}>
      <Grid item container alignItems="center" xs={12}>
        <Grid item>
          <Typography variant="h6" component="div">
            물품 총합
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              총 CBM :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {bidDetail.totalCBM}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              총 무게 :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {/* 데이터가 0.7055로 들어오는 경우 toThousandUnitFormat은 0.7,055로 변환하기 떄문에 소숫점 반영 함수로 변경 */}
              {toThousandUnitFormatWithDecimal(bidDetail.totalWeight)}TON
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              총 R.TON :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {bidDetail.supply}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ConsolidationTotalInfo;
