import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { useSetRecoilState } from "recoil";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_ADMIN_TRELLO_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminTrello";
import {
  Currency,
  FreightType,
  ResponseFailureInfo,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  WithdrawalFormDetail,
  WithdrawalRequestDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDateToUTCDate } from "@sellernote/_shared/src/utils/common/date";
import { changePurchaseDomainToTemplateCategory } from "@sellernote/_shared/src/utils/forwarding/trello";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

import TemplateSearch from "../../../../../../containers/TemplateSearch";
import useSnackbar from "../../../../../../hooks/useSnackbar";

import DatePicker from "../../../../../../components/DatePicker";
import Modal from "../../../../../../components/Modal";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../../jotaiStates/auth";
import CancelWithdrawModal from "./CancelWithdrawModal";
import CurrencyFilter from "./CurrencyFilter";
import PurchaseRequestForm from "./PurchaseRequestForm";

const PurchaseRequestModal = ({
  showsPurchaseInvoiceRequestModal,
  setShowsPurchaseInvoiceRequestModal,
  bidId,
  companyType,
  freightType,
  companyId,
  exchangeRateList,
  bidAccountPayableId,
  trelloDetailData,
}: {
  freightType: FreightType;
  bidId: number;
  companyType: PartnerBusinessArea;
  showsPurchaseInvoiceRequestModal: boolean;
  companyId: number;
  setShowsPurchaseInvoiceRequestModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  exchangeRateList: ExchangeRate[];
  bidAccountPayableId: number;
  trelloDetailData: TrelloBidDetail;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const setIsLoadedWithdrawalTemplateData = useSetRecoilState(
    FORWARDING_ADMIN_TRELLO_ATOMS.IS_LOADED_WITHDRAWAL_TEMPLATE_DATA
  );

  const queryClient = useQueryClient();

  const [currency, setCurrency] = useState<Currency>("USD");
  // 직접 입력하거나 갱신 등으로 변경하는 환율 상태값
  const [currentExchangeRate, setCurrentExchangeRate] = useState(0);
  // currentExchangeRate와 비교해 수정 및 갱신이 있었는지 확인하기 위한 환율 상태값
  const [baseExchangeRate, setBaseExchangeRate] = useState(0);
  const [showCancelWithdrawModal, setShowCancelWithdrawModal] = useState(false);
  const [withdrawalInvoiceId, setWithdrawalInvoiceId] = useState<string>("");
  const [withdrawalInvoiceDate, setWithdrawalInvoiceDate] = useState<
    null | string
  >(null);

  const {
    basicWithdrawalRequestList,
    isSave,
    withdrawalRequestData,
    withdrawalItemList,
  } = TRELLO_BID_QUERY.useGetWithdrawalBasicList({
    bidAccountPayableId,
  });

  /**
   * TODO: react-query 요청안에서 값을 만들고 있어서 useEffect 대신 onSuccess를 바로 적용할 수 없음 로직 수정이 필요
   */
  useEffect(() => {
    /** 출금액 요청 데이터가 있는 경우 */
    if (withdrawalRequestData) {
      setWithdrawalInvoiceId(withdrawalRequestData.withdrawalInvoiceId ?? "");
      setCurrency(withdrawalRequestData.currency);
      setCurrentExchangeRate(withdrawalRequestData.exchangeRate);
      setBaseExchangeRate(withdrawalRequestData.exchangeRate);

      if (!withdrawalRequestData.withdrawalInvoiceDate) {
        setWithdrawalInvoiceDate(null);
        return;
      }

      setWithdrawalInvoiceDate(
        toFormattedDateToUTCDate(withdrawalRequestData.withdrawalInvoiceDate)
      );

      return;
    }
    /** 요청 데이터가 없는 경우 당일 환율을 적용*/
    setCurrentExchangeRate(
      exchangeRateList.find((exchange) => exchange.currency === "USD")?.rate ||
        0
    );
    setBaseExchangeRate(
      exchangeRateList.find((exchange) => exchange.currency === "USD")?.rate ||
        0
    );
  }, [exchangeRateList, withdrawalRequestData]);

  const {
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ApplyBidFormData>();

  useEffect(() => {
    const getItemUnitMeasurement = () => {
      if (freightType === "FCL") {
        return "CNTR";
      }

      if (freightType === "LCL") {
        return "R.TON";
      }
      return "C/W";
    };

    if (basicWithdrawalRequestList) {
      const newBasicWithDrawalList =
        basicWithdrawalRequestList.map<WithdrawalRequestDetail>((v) => {
          if (!v.itemUnitMeasurement) {
            return {
              ...v,
              itemUnitMeasurement: getItemUnitMeasurement(),
            };
          }
          return v;
        });
      reset({ withdrawalData: newBasicWithDrawalList });
    }
  }, [basicWithdrawalRequestList, freightType, reset]);

  const { mutate: requestWithdrawal, isLoading } =
    TRELLO_BID_QUERY.useRequestWithdrawal();

  const withdrawalDataList = watch("withdrawalData");

  const isOnlyKrCurrency = useMemo(() => {
    return withdrawalDataList?.every((v) => {
      return v.currency === "KRW";
    });
  }, [withdrawalDataList]);

  /** 템플릿 적용 시 현재 환율과 기준환율도 화폐에 맞는 환율로 변경해준다. */
  const changeWithdrawalCurrencyAfterTemplateUpdate = (currency: Currency) => {
    const templateCurrencyExchangeRate =
      exchangeRateList.find((exchangeRate) => {
        return exchangeRate.currency === currency;
      })?.rate || 0;

    setCurrency(currency);
    setCurrentExchangeRate(templateCurrencyExchangeRate);
    setBaseExchangeRate(templateCurrencyExchangeRate);
  };

  const getPartnerNameKr = useCallback((text: PartnerBusinessArea) => {
    switch (text) {
      case "parcel":
        return "화물택배사 지불금액";
      case "foreign":
        return "해외파트너 지불금액";
      case "domestic":
        return "국내파트너 지불금액";
      case "customs":
        return "관세사 지불금액";
      case "customsDuty":
        return "세관 지불금액";
      case "inland":
        return "내륙운송사 지불금액";
      case "wareHouse":
        return "창고 지불금액";
      case "shipping":
        return "선사 지불금액";
      case "etc":
        return "기타1 지불금액";
      case "etc2":
        return "기타2 지불금액";
      default:
        return "-";
    }
  }, []);

  const isForeign = useMemo(() => {
    return companyType === "foreign" ? true : false;
  }, [companyType]);

  const handleWithdrawalRequestClick = useCallback(
    (requestWithdrawalFlag: boolean) => {
      // 인풋 value 환율이 기준환율과 다르다면 요청할 수 없음
      if (currentExchangeRate !== baseExchangeRate) {
        handleSnackbarOpen("변경된 환율이 적용되지 않았습니다.", "error");
        return;
      }
      const detailList = withdrawalDataList.map((v: WithdrawalFormDetail) => {
        return {
          name: v.name || "",
          currency: v.currency,
          unitPrice: v.unitPrice,
          itemUnitMeasurement: v.itemUnitMeasurement,
          amount: v.amount,
          totalPrice: v.totalPrice,
          vatPrice: v.vatPrice,
          finalPrice: v.finalPrice,
          note: v.note,
          isVAT: v.isVAT,
        };
      });

      //여러개인 각 항목들의 가격들을 합친다.
      let totalPrice = 0;
      let finalPrice = 0;
      let vatPrice = 0;
      let foreignFinalPrice = 0;

      withdrawalDataList.forEach((v: WithdrawalFormDetail) => {
        totalPrice += v.totalPrice;
        finalPrice += v.finalPrice;
        vatPrice += v.vatPrice;
        if (v.currency !== "KRW") {
          foreignFinalPrice += v.unitPrice * v.amount;
        }
      });

      requestWithdrawal(
        {
          detailList,
          bidId,
          companyType,
          requestWithdrawalFlag,
          foreignFinalPrice: isOnlyKrCurrency
            ? 0
            : Number(foreignFinalPrice.toFixed(2)),
          currency: isOnlyKrCurrency ? "KRW" : currency,
          exchangeRate: isOnlyKrCurrency ? 0 : currentExchangeRate,

          totalPrice: isForeign
            ? Number(finalPrice.toFixed(0))
            : Number(totalPrice.toFixed(0)),
          vatPrice: isForeign ? 0 : Number(vatPrice.toFixed(0)),
          finalPrice: Number(finalPrice.toFixed(0)),
          companyId: companyId,
          bidAccountPayableId,
          withdrawalInvoiceId,
          ...(withdrawalInvoiceDate && { withdrawalInvoiceDate }),
        },
        {
          onSuccess: () => {
            handleSnackbarOpen(
              requestWithdrawalFlag
                ? "출금을 요청했습니다."
                : "출금을 저장했습니다."
            );

            setWithdrawalInvoiceId("");
            setWithdrawalInvoiceDate(null);

            queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
          },

          onError: ({ response }) => {
            const failureInfo =
              response?.data as unknown as ResponseFailureInfo;

            if (failureInfo.errorCode === "E074") {
              handleSnackbarOpen("인보이스를 업데이트 해주세요.", "error");
              return;
            }

            if (failureInfo.errorCode === "E075") {
              handleSnackbarOpen("이미 요청한 상태입니다.", "error");
              return;
            }
            handleSnackbarOpen(
              "에러가 발생했습니다 개발자에게 문의해주세요.",
              "error"
            );
            return;
          },
        }
      );
    },
    [
      baseExchangeRate,
      bidAccountPayableId,
      bidId,
      companyId,
      companyType,
      currency,
      currentExchangeRate,
      handleSnackbarOpen,
      isForeign,
      isOnlyKrCurrency,
      queryClient,
      requestWithdrawal,
      withdrawalDataList,
      withdrawalInvoiceDate,
      withdrawalInvoiceId,
    ]
  );

  return (
    <Modal
      isOpened={showsPurchaseInvoiceRequestModal}
      handleClose={() => {
        setIsLoadedWithdrawalTemplateData(false);
        setShowsPurchaseInvoiceRequestModal(false);
      }}
      modalBodySx={{ width: "1440px" }}
      modalBody={
        <Box>
          <Typography variant="h6" mb={2}>
            {getPartnerNameKr(companyType)}
          </Typography>

          <Box display={"flex"} flexWrap={"nowrap"} mb={1} gap={2}>
            <Box display={"flex"} flexWrap={"nowrap"}>
              <DatePicker
                label={"INV. Date"}
                when="end"
                value={withdrawalInvoiceDate}
                setDate={setWithdrawalInvoiceDate}
              />

              <TextField
                sx={{ width: 280 }}
                label={"INV No."}
                value={withdrawalInvoiceId}
                size={"small"}
                onChange={(e) => {
                  setWithdrawalInvoiceId(e.target.value);
                }}
              />
            </Box>

            <TemplateSearch
              bidId={bidId}
              templateCategory={changePurchaseDomainToTemplateCategory(
                companyType
              )}
              reset={reset}
              watch={watch}
              setValue={setValue}
              templateFeeDataType={"withdrawalData"}
              templateType="withdrawal"
              bidAccountPayableId={bidAccountPayableId}
              exchangeRateList={exchangeRateList}
              changeWithdrawalCurrencyAfterTemplateUpdate={
                changeWithdrawalCurrencyAfterTemplateUpdate
              }
            />
          </Box>

          <CurrencyFilter
            currency={currency}
            exchangeRateList={exchangeRateList}
            setCurrency={setCurrency}
            currentExchangeRate={currentExchangeRate}
            setCurrentExchangeRate={setCurrentExchangeRate}
            withdrawalDataList={withdrawalDataList}
            reset={reset}
            companyType={companyType}
            setBaseExchangeRate={setBaseExchangeRate}
          />

          {withdrawalDataList && (
            <PurchaseRequestForm
              withdrawalDataList={withdrawalDataList}
              control={control}
              currency={currency}
              companyType={companyType}
              setValue={setValue}
              exchangeRateList={exchangeRateList}
              withdrawalItemList={withdrawalItemList}
              trelloDetailData={trelloDetailData}
              currentExchangeRate={currentExchangeRate}
            />
          )}

          <Box display="flex" justifyContent={"center"}>
            <Box>
              <Button
                variant="outlined"
                disabled={isSave || isLoading}
                sx={{ mr: 1 }}
                size="large"
                onClick={() => handleWithdrawalRequestClick(false)}
              >
                {isLoading ? <CircularProgress size={25} /> : "저장"}
              </Button>

              {isSave ? (
                <Button
                  variant="outlined"
                  color="error"
                  disabled={currentAdminAuthInfo?.authority === "finance"}
                  onClick={() => setShowCancelWithdrawModal(true)}
                  size="large"
                >
                  취소 요청
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  disabled={
                    isLoading ||
                    // 해외파트너일 때는 withdrawal 데이터 중 하나라도 입력이 없으면 요청 금지
                    (companyType === "foreign" &&
                      (!withdrawalInvoiceDate || !withdrawalInvoiceId))
                  }
                  onClick={() => handleWithdrawalRequestClick(true)}
                  size="large"
                >
                  {isLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    " 저장&출금요청"
                  )}
                </Button>
              )}
            </Box>
          </Box>

          {showCancelWithdrawModal && (
            <CancelWithdrawModal
              bidId={bidId}
              partnerCompanyId={companyId}
              setShowCancelWithdrawModal={setShowCancelWithdrawModal}
              showCancelWithdrawModal={showCancelWithdrawModal}
            />
          )}
        </Box>
      }
    />
  );
};

export default PurchaseRequestModal;
