import { useCallback } from "react";
import { ButtonUnstyled } from "@mui/material";
import {
  blue,
  brown,
  deepPurple,
  lime,
  orange,
  pink,
  red,
  teal,
} from "@mui/material/colors";
import { styled } from "@mui/system";

export default function TagForAdminBidList({
  color,
  children,
}: {
  color:
    | "red"
    | "pink"
    | "purple"
    | "orange"
    | "green"
    | "grey"
    | "blue"
    | "brown"
    | "teal";
  children: string | React.ReactNode;
}) {
  const changeColorToMulColor = useCallback(() => {
    switch (color) {
      case "red":
        return red;
      case "pink":
        return pink;
      case "purple":
        return deepPurple;
      case "orange":
        return orange;
      case "green":
        return lime;
      case "blue":
        return blue;
      case "brown":
        return brown;
      case "teal":
        return teal;
      default:
        return blue;
    }
  }, [color]);

  const CustomChip = styled(ButtonUnstyled)({
    borderColor: changeColorToMulColor()[300],
    backgroundColor: changeColorToMulColor()[50],
    color: changeColorToMulColor()[800],
    fontSize: "11.2px",
    borderRadius: "2.9px",
  });

  return <CustomChip>{children}</CustomChip>;
}
