import { atom } from "jotai";

import { AdminBidTransportMode } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  PartnerBusinessArea,
  PartnerSearchType,
} from "@sellernote/_shared/src/types/forwarding/partnerManagement";

const SEARCH_PARAMS = atom<{
  page: number;
  perPage: number;
  businessArea: PartnerBusinessArea | undefined;
  transportMode: AdminBidTransportMode[] | undefined;
  searchType: PartnerSearchType;
  searchValue: undefined | string;
}>({
  page: 0,
  perPage: 10,
  businessArea: undefined,
  transportMode: ["FCL", "LCL", "AIR", "EXPRESS"],
  searchType: "name",
  searchValue: undefined,
});

export default {
  SEARCH_PARAMS,
};
