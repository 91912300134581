import { Box } from "@mui/material";

import { ScheduleHistoryDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import CategoryAndDescription from "./CategoryAndDescription";
import DetailedScheduleChangeLog from "./DetailedScheduleChangeLog";

export default function ChangeLog({ row }: { row: ScheduleHistoryDetail }) {
  return (
    <Box>
      <CategoryAndDescription
        category={row.changeLog.type}
        subCategory={row.changeLog.actionType}
        description={row.changeReason}
      />

      <DetailedScheduleChangeLog row={row} />
    </Box>
  );
}
