import { useState } from "react";

function useGeneratorModal() {
  const [opensGeneratorModal, setOpensGeneratorModal] = useState(false);

  const handleGeneratorModalToggle = () => {
    setOpensGeneratorModal((prev) => !prev);
  };

  return {
    opensGeneratorModal,
    handleGeneratorModalToggle,
  };
}

export default useGeneratorModal;
