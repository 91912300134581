import { useState } from "react";
import { Button } from "@mui/material";

import Modal from "../../../../../../../components/Modal";

import ShipmentHistoryTable from "./ShipmentHistoryTable";

export default function ShipmentHistory({
  shipmentId,
}: {
  shipmentId: number;
}) {
  const [showsShipmentHistoryModal, setShowsShipmentHistoryModal] =
    useState(false);

  const handleModalOpen = () => setShowsShipmentHistoryModal(true);
  const handleModalClose = () => setShowsShipmentHistoryModal(false);

  return (
    <>
      <Button variant="contained" onClick={handleModalOpen}>
        변경 이력
      </Button>

      <Modal
        isOpened={showsShipmentHistoryModal}
        handleClose={handleModalClose}
        modalBody={<ShipmentHistoryTable shipmentId={shipmentId} />}
      />
    </>
  );
}
