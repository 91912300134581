import styled from "styled-components";

const cargoInfoContainer = styled.div``;

const cargoInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  flex: 0 1 auto;
`;

const cargoInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  flex-shrink: 0;

  > .border-top {
    border-top: 2px solid #5482d6;
    width: 550px;
  }

  > .title {
    color: #4d70c3;
    text-align: center;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
  }
`;

const cargoInfoDetail = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
  flex: 1;
`;

const marksOrUnitsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
`;

const marksOrUnitsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  flex-shrink: 0;
`;

const containerInfo = styled.div`
  color: #424242;
  display: flex;
  align-items: flex-start;
`;

const marksOrUnitsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 84px;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 84px;
  }

  > .title {
    color: #4d70c3;
    text-align: left;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.3px;
    font-weight: 700;
    text-transform: uppercase;
  }

  > .subtitle {
    color: #4d70c3;
    text-align: left;
    font-size: 6px;
    line-height: 8px;
    letter-spacing: -0.3px;
    font-weight: 700;
    text-transform: uppercase;
    width: 79px;
    display: flex;
    align-items: center;
  }

  > .content {
    color: #424242;
    text-align: left;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.054px;
    align-self: stretch;
  }
`;

const goodsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex: 1;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 184px;
  }

  > .title {
    color: #4d70c3;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  > .unknownClause-wrapper {
    width: 184px;
  }
`;

const freightPaymentType = styled.div`
  font-size: 9px;
  line-height: 12px;
  letter-spacing: -0.054px;
  margin-top: 50px;
`;

const grossWeightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 84px;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 84px;
  }

  > .title {
    color: #4d70c3;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.368px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const measurementColumn = styled(grossWeightColumn)``;

const signatureSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  gap: 16px;
`;

export default {
  cargoInfoContainer,
  cargoInfoSection,
  cargoInfoHeader,
  cargoInfoDetail,
  marksOrUnitsColumn,
  marksOrUnitsRow,
  containerInfo,
  marksOrUnitsItem,
  goodsInfo,
  grossWeightColumn,
  measurementColumn,
  signatureSection,
  freightPaymentType,
};
