import { Grid } from "@mui/material";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../../components/SADSTable";

type CellKey = "품명" | "컨테이너" | "위험물" | "CBM" | "중량";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명", width: 100 },
  { cellKey: "컨테이너", label: "컨테이너 X 수량", align: "right", width: 100 },
  { cellKey: "CBM", label: "CBM", align: "right", width: 100 },
  { cellKey: "중량", label: "중량", align: "right", width: 100 },
  { cellKey: "위험물", label: "위험물", align: "right", width: 100 },
];

function FCLItemList({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const bodyRowList: TableBodyRow<CellKey>[] = (
    bidDetail.itemGroupList || []
  ).map(({ item, containerInfo }) => {
    return {
      rowKey: item.id,
      품명: { value: item.name },
      컨테이너: {
        value: (
          <Grid container direction={"column"}>
            {containerInfo?.map((v, i) => (
              <Grid item key={i}>
                {v.containerType} X {v.quantity}
              </Grid>
            ))}
          </Grid>
        ),
      },
      CBM: { value: item.cbm || "-" },
      중량: { value: item.weight || "-" },
      위험물: {
        value: changeBooleanValueToKr(item.isDangerous),
      },
    };
  });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>
    </Grid>
  );
}

export default FCLItemList;
