/**
 * 적하목록 제출 가능한 ProjectStatus.
 * FCL의 한하여 출항준비 ~ 입항완료 및 통관준비에서만 버튼 활성화
 */
const AN_SUBMIT_AVAILABLE_STATUS_LIST: Record<string, string[]> = {
  import: [
    "scheduling",
    "containerCarryOut",
    "containerPickup",
    "domesticImportCFS",
    "gateIn",
    "loaded",
    "moving",
    "portEntryAndPrepareCustoms",
  ],
  export: ["scheduling"],
};

export default AN_SUBMIT_AVAILABLE_STATUS_LIST;
