import { TableColumnInfo } from "../types";

const TABLE_COLUMN_LIST: readonly TableColumnInfo[] = [
  {
    id: "updatedAt",
    label: "수정일시",
    minWidth: 140,
    align: "center",
  },
  {
    id: "statusAtTheTime",
    label: "당시 상태",
    minWidth: 200,
    align: "center",
  },
  {
    id: "updatedHistory",
    label: "수정내역",
    minWidth: 400,
    align: "center",
  },
  { id: "updater", label: "수정자", minWidth: 140, align: "center" },
];

export { TABLE_COLUMN_LIST };
