import { useCallback, useState } from "react";
import { Person } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";

import { CREATE_DOCUMENT_TYPE_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";

import HouseDeliveryOrderDocument from "./HouseDeliveryOrderDocument";
import ParcelRequestDocument from "./ParcelRequestDocument";
import WarehouseDocument from "./WarehouseDocument";
import Styled from "./index.styles";

const CreateDocument = ({
  bidId,
  userCompany,
}: {
  bidId: number;
  userCompany: string | undefined;
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [warehouseDocumentDrawerVisible, setWarehouseDocumentDrawerVisible] =
    useState(false);
  const [
    parcelRequestDocumentDrawerVisible,
    setParcelRequestDocumentDrawerVisible,
  ] = useState(false);
  const [
    houseDeliveryOrderDocumentVisible,
    setHouseDeliveryOrderDocumentVisible,
  ] = useState(false);

  const handleDocumentDrawerOpen = useCallback((event: SelectChangeEvent) => {
    switch (event.target.value) {
      case "releaseRequest":
        return setWarehouseDocumentDrawerVisible(true);
      case "parcelRequest":
        return setParcelRequestDocumentDrawerVisible(true);
      case "houseDo":
        return setHouseDeliveryOrderDocumentVisible(true);
      default:
        return;
    }
  }, []);

  return (
    <Styled.createDocumentContainer>
      <FormControl>
        <InputLabel>서류 생성</InputLabel>

        <Select
          sx={{ width: 150 }}
          value={selectedValue}
          onChange={handleDocumentDrawerOpen}
          label="서류 생성"
        >
          {CREATE_DOCUMENT_TYPE_OPTION_LIST.map((v) => {
            return (
              <MenuItem key={v.value} value={v.value}>
                <Tooltip
                  title="이 구분은 '쉽다-어드민'만 표시됩니다."
                  placement="right"
                >
                  <Grid container alignContent="flex-end">
                    {v.label}

                    <Person color="primary" />
                  </Grid>
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {warehouseDocumentDrawerVisible && (
        <WarehouseDocument
          bidId={bidId}
          warehouseDocumentDrawerVisible={warehouseDocumentDrawerVisible}
          setWarehouseDocumentDrawerVisible={setWarehouseDocumentDrawerVisible}
        />
      )}

      {parcelRequestDocumentDrawerVisible && (
        <ParcelRequestDocument
          bidId={bidId}
          parcelRequestDocumentDrawerVisible={
            parcelRequestDocumentDrawerVisible
          }
          setParcelRequestDocumentDrawerVisible={
            setParcelRequestDocumentDrawerVisible
          }
        />
      )}

      {houseDeliveryOrderDocumentVisible && (
        <HouseDeliveryOrderDocument
          bidId={bidId}
          userCompany={userCompany || ""}
          houseDeliveryOrderDocumentVisible={houseDeliveryOrderDocumentVisible}
          setHouseDeliveryOrderDocumentVisible={
            setHouseDeliveryOrderDocumentVisible
          }
        />
      )}
    </Styled.createDocumentContainer>
  );
};

export default CreateDocument;
