import styled from "styled-components";

const blInfoSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
`;

const blInfoColumn = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex: 1;
`;

const blInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex: 1;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 132px;
  }

  > .title {
    color: #4d70c3;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    align-self: stretch;
  }

  > .content {
    color: #424242;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.054px;
    font-weight: 400;
  }
`;

export default {
  blInfoSection,
  blInfoColumn,
  blInfoItem,
};
