import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IconButton, Tooltip } from "@mui/material";

// TODO: 파트너 어드민에도 사용한다면 상위 _components로 이동
function RefreshButton({ refetchData }: { refetchData: () => void }) {
  return (
    <Tooltip title="리스트 데이터 새로고침">
      <IconButton onClick={refetchData}>
        <RestartAltIcon />
      </IconButton>
    </Tooltip>
  );
}

export default RefreshButton;
