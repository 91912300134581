import { useCallback, useMemo, useState } from "react";

import {
  InvoiceType,
  TrelloBidDetail,
  TrelloBidInvoice,
} from "@sellernote/_shared/src/types/forwarding/trello";

export function useDepositCommentModalProps({
  trelloDetailData,
  invoiceType,
  invoiceId,
  paymentInvoiceId,
}: {
  trelloDetailData: TrelloBidDetail;
  invoiceType: InvoiceType;
  invoiceId: number;
  paymentInvoiceId: number;
}) {
  const [showsDepositCommentModal, setShowsDepositCommentModal] =
    useState(false);

  const getInvoiceData = useCallback(
    (invoiceData: TrelloBidInvoice[] | null) => {
      if (!invoiceData) {
        return;
      }

      return invoiceData.find((v) => {
        return v.id === invoiceId;
      });
    },
    [invoiceId]
  );

  const getPaymentSubInvoice = useCallback(() => {
    if (invoiceType === "invoice") {
      return trelloDetailData?.bidInvoice?.paymentSubInvoice;
    }

    if (invoiceType === "refund") {
      return trelloDetailData?.refundInvoice?.paymentSubInvoice;
    }

    if (invoiceType === "warehouseReceipt") {
      const warehouseInvoice = getInvoiceData(
        trelloDetailData?.warehouseInvoice
      );
      return warehouseInvoice?.paymentSubInvoice;
    }

    if (invoiceType === "etcDeposit") {
      const etcInvoice = getInvoiceData(trelloDetailData?.etcDepositInvoice);
      return etcInvoice?.paymentSubInvoice;
    }
  }, [
    getInvoiceData,
    invoiceType,
    trelloDetailData?.bidInvoice?.paymentSubInvoice,
    trelloDetailData?.etcDepositInvoice,
    trelloDetailData?.refundInvoice?.paymentSubInvoice,
    trelloDetailData?.warehouseInvoice,
  ]);

  const getPaymentInvoice = useCallback(() => {
    const paymentSubInvoice = getPaymentSubInvoice();

    return paymentSubInvoice?.find((v) => {
      return v.paymentInvoiceId === paymentInvoiceId;
    })?.paymentInvoice;
  }, [getPaymentSubInvoice, paymentInvoiceId]);

  const commentModalProps = useMemo(() => {
    const paymentInvoice = getPaymentInvoice();

    if (!paymentInvoice) {
      return;
    }

    // 서버에서 오는 데이터가 정리되지 않아서 온다 지원님이 알려준 방법으로 변경
    return {
      amount: Number(paymentInvoice.transactionAmount),
      name:
        paymentInvoice.giroCode.substring(0, 3) === "000"
          ? paymentInvoice.secondCustomerName.trim()
          : paymentInvoice.secondCustomerName.slice(2).trim(),
      comment: paymentInvoice.comment ? paymentInvoice.comment.reverse() : null,
    };
  }, [getPaymentInvoice]);

  const handleModalClose = () => {
    setShowsDepositCommentModal(false);
  };

  const handleModalOpen = () => {
    setShowsDepositCommentModal(true);
  };

  return {
    commentModalProps,
    showsDepositCommentModal,
    handleModalOpen,
    handleModalClose,
  };
}
