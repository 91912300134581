import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { AxiosResponse } from "axios";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import {
  PartnerBusinessArea,
  PartnerListItem,
} from "@sellernote/_shared/src/types/forwarding/partner";
import {
  ExchangeRate,
  TrelloBidDetail,
  TrelloPurchaseManagementTableData,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { TRELLO_PURCHASE_PARTNER_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import {
  omitWithEllipsis,
  replaceEmptyToDash,
} from "@sellernote/_shared/src/utils/common/string";
import {
  changePartnerBusinessAreaToUploadTarget,
  changePurchaseDomainToKr,
  getPartnerCompanyName,
  getTrelloPartnerAutoCompleteLabel,
  getTrelloPartnerAutoCompleteValue,
} from "@sellernote/_shared/src/utils/forwarding/trello";
import FileName from "@sellernote/_shared-for-forwarding-admin/src/containers/FileName";
import UploadModal from "@sellernote/_shared-for-forwarding-admin/src/containers/UploadModal";

import useSnackbar from "../../../../../hooks/useSnackbar";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "../../../../../components/LegacyTable";

import ChangeCustomsPartnerModal from "./ChangeCustomsPartnerModal";
import DeleteBidAccountPayableModal from "./DeleteBidAccountPayableModal";
import PurchaseRequestModal from "./PurchaseRequestModal";
import RegisterSettlementPartnerModal from "./RegisterSettlementPartnerModal";
import usePartnerNameAutoCompleteOptions, {
  PartnerListForAutoComplete,
} from "./usePartnerNameAutoCompleteOptions";
import useTrelloPurchaseManagementTableData from "./useTrelloPurchaseManagementTableData";

type CellId =
  | keyof TrelloPurchaseManagementTableData
  | "upload"
  | "fileName"
  | "fileDescription"
  | "delete";

function PurchaseManagement({
  trelloDetailData,
  partnerList,
  exchangeRateList,
}: {
  trelloDetailData: TrelloBidDetail;
  partnerList?: PartnerListItem[];
  exchangeRateList: ExchangeRate[];
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const queryClient = useQueryClient();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [showsUploadModal, setShowsUploadModal] = useState(false);
  const [invoiceType, setPartnerType] = useState("");
  const [bidAccountPayableId, setBidAccountPayableId] = useState(0);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [
    showsPurchaseInvoiceRequestModal,
    setShowsPurchaseInvoiceRequestModal,
  ] = useState(false);
  const [companyType, setCompanyType] =
    useState<PartnerBusinessArea>("foreign");
  const [companyId, setCompanyId] = useState(0);
  const [opensChangeCustomsPartnerModal, setOpensChangeCustomsPartnerModal] =
    useState(false);
  const [
    opensRegisterSettlementPartnerModal,
    setOpensRegisterSettlementPartnerModal,
  ] = useState(false);
  const [
    opensDeleteBidAccountPayableModal,
    setOpensDeleteBidAccountPayableModal,
  ] = useState(false);

  const { mutate: updateAccountPayable } =
    TRELLO_BID_QUERY.useUpdateAccountPayable(trelloDetailData.id);

  const { mutate: changePurchasePartner } =
    TRELLO_BID_QUERY.useChangePurchasePartner(trelloDetailData.id);

  const { mutate: changeForeignWithdrawalTarget } =
    TRELLO_BID_QUERY.useChangeForeignWithdrawalTarget();

  const { trelloPurchaseManagementTableData } =
    useTrelloPurchaseManagementTableData({ trelloDetailData });

  const {
    domesticPartnerListForAutoComplete,
    foreignPartnerListForAutoComplete,
    shippingPartnerListForAutoComplete,
    wareHousePartnerListForAutoComplete,
    customsPartnerListForAutoComplete,
    inlandPartnerListForAutoComplete,
    etcPartnerListForAutoComplete,
  } = usePartnerNameAutoCompleteOptions({ partnerList });

  const getPartnerNameAutoCompleteOption = useCallback(
    (domain: PartnerBusinessArea) => {
      switch (domain) {
        case "foreign":
          return foreignPartnerListForAutoComplete;
        case "domestic":
          return domesticPartnerListForAutoComplete;
        case "shipping":
          return shippingPartnerListForAutoComplete;
        case "wareHouse":
          return wareHousePartnerListForAutoComplete;
        case "customs" || "shipdaCustoms":
          return customsPartnerListForAutoComplete;
        case "inland":
          return inlandPartnerListForAutoComplete;
        default:
          return etcPartnerListForAutoComplete;
      }
    },
    [
      customsPartnerListForAutoComplete,
      domesticPartnerListForAutoComplete,
      etcPartnerListForAutoComplete,
      foreignPartnerListForAutoComplete,
      inlandPartnerListForAutoComplete,
      shippingPartnerListForAutoComplete,
      wareHousePartnerListForAutoComplete,
    ]
  );

  const processSuccessResponse = useCallback(
    (message: string) => {
      handleSnackbarOpen(message);
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
    },
    [handleSnackbarOpen, queryClient]
  );

  const handlePurchasePartnerChangeErrorRequest = useCallback(
    (response: AxiosResponse<ResponseFailureInfo, any> | undefined) => {
      if (response?.data.errorCode === "E067") {
        handleSnackbarOpen(
          "이 유저는 의뢰를 업데이트 할 수 없습니다.",
          "error"
        );
        return;
      }

      if (response?.data.errorCode === "E076") {
        handleSnackbarOpen(
          "보이스를 업로드한 파트너는 삭제할 수 없습니다.",
          "error"
        );
        return;
      }

      handleSnackbarOpen(
        "에러가 발생했습니다. 개발자에게 문의해주세요.",
        "error"
      );
    },
    [handleSnackbarOpen]
  );

  const handleChangeCustomsPartnerModalOpen = useCallback(
    (accountPayableId: number) => {
      setBidAccountPayableId(accountPayableId);
      setOpensChangeCustomsPartnerModal(true);
    },
    []
  );

  const handleChangeCustomsPartnerToShipper = useCallback(
    (accountPayableId) => {
      changePurchasePartner(
        {
          shipperMailingFlag: false,
          bidAccountPayableId: accountPayableId,
        },
        {
          onSuccess: () => {
            processSuccessResponse("화주 발송으로 변경했습니다.");
          },

          onError: ({ response }) => {
            handlePurchasePartnerChangeErrorRequest(response);
          },
        }
      );
    },
    [
      changePurchasePartner,
      handlePurchasePartnerChangeErrorRequest,
      processSuccessResponse,
    ]
  );

  const handlePurchasePartnerChange = useCallback(
    ({
      event,
      selectValue,
      accountPayableId,
      domain,
    }: {
      event: SyntheticEvent<Element, Event>;
      selectValue: {
        label: string;
        value: number | "shipper" | null;
      } | null;
      accountPayableId: number;
      domain: PartnerBusinessArea;
    }) => {
      if (selectValue?.value === "shipper") {
        if (trelloDetailData.management.documentMailingFlag) {
          return handleChangeCustomsPartnerModalOpen(accountPayableId);
        }

        return handleChangeCustomsPartnerToShipper(accountPayableId);
      }

      //AutoComplete에서 allClear클릭과 초기 라벨값"-"" 제거가 같은 값을 가져서 구별을 위해 event.type 조건 추가
      if (!selectValue?.value && event.type === "click") {
        return changePurchasePartner(
          {
            partnerCompanyId: null,
            companyType: domain === "shipDaCustoms" ? "customs" : domain,
            clearFlag: true,
            bidAccountPayableId: accountPayableId,
          },
          {
            onSuccess: () => {
              processSuccessResponse("파트너를 초기화 했습니다.");
            },

            onError: ({ response }) => {
              handlePurchasePartnerChangeErrorRequest(response);
            },
          }
        );
      }

      if (selectValue?.value) {
        return changePurchasePartner(
          {
            partnerCompanyId: selectValue.value,
            companyType: domain === "shipDaCustoms" ? "customs" : domain,
            clearFlag: false,
            bidAccountPayableId: accountPayableId,
          },
          {
            onSuccess: () => {
              processSuccessResponse("파트너를 등록했습니다.");
            },

            onError: ({ response }) => {
              handlePurchasePartnerChangeErrorRequest(response);
            },
          }
        );
      }
    },
    [
      trelloDetailData.management.documentMailingFlag,
      handleChangeCustomsPartnerToShipper,
      handleChangeCustomsPartnerModalOpen,
      changePurchasePartner,
      processSuccessResponse,
      handlePurchasePartnerChangeErrorRequest,
    ]
  );

  const getPartnerNameAutoComplete = useCallback(
    ({
      domain,
      partnerName,
      partnerId,
      accountPayableId,
    }: {
      domain: PartnerBusinessArea;
      partnerName: string;
      partnerId: number | null;
      accountPayableId: number;
    }) => {
      return (
        <Autocomplete
          onChange={(event, newValue: PartnerListForAutoComplete | null) => {
            handlePurchasePartnerChange({
              event,
              selectValue: newValue,
              accountPayableId,
              domain,
            });
          }}
          disabled={
            domain === "inland" ||
            domain === "parcel" ||
            APP_NAME === "partner-admin"
          }
          isOptionEqualToValue={(option, value) => option.value === value.value}
          disablePortal
          value={{
            label: getTrelloPartnerAutoCompleteLabel(
              trelloDetailData,
              partnerName,
              domain
            ),
            value: getTrelloPartnerAutoCompleteValue(
              trelloDetailData,
              partnerId,
              domain
            ) as number | "shipper" | null,
          }}
          options={getPartnerNameAutoCompleteOption(domain)}
          sx={{ width: 200 }}
          renderInput={(params) => (
            <TextField {...params} label={changePurchaseDomainToKr(domain)} />
          )}
        />
      );
    },
    [
      getPartnerNameAutoCompleteOption,
      handlePurchasePartnerChange,
      trelloDetailData,
    ]
  );

  const handleUploadModalOpen = useCallback(
    ({
      accountPayableId,
      partnerType,
    }: {
      accountPayableId: number;
      partnerType: PartnerBusinessArea;
    }) => {
      return () => {
        setBidAccountPayableId(accountPayableId);
        setPartnerType(changePartnerBusinessAreaToUploadTarget(partnerType));
        setShowsUploadModal(true);
      };
    },
    []
  );

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleAccountPayableUpdate = useCallback(
    (domain: PartnerBusinessArea) => {
      return () => {
        updateAccountPayable(
          { domain },
          {
            onSuccess: () => {
              handleSnackbarOpen("요청에 성공했습니다.");
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
              );
            },

            onError: () => {
              handleSnackbarOpen("요청에 실패했습니다.", "error");
            },
          }
        );
      };
    },
    [handleSnackbarOpen, queryClient, updateAccountPayable]
  );

  const withdrawalModalOpen = useCallback(
    (bidAccountPayableId: number, partnerId: number) => {
      setBidAccountPayableId(bidAccountPayableId);
      setShowsPurchaseInvoiceRequestModal(true);
      setCompanyId(partnerId);
    },
    []
  );

  const handleWithdrawalModalOpen = useCallback(
    (partnerTableData: TrelloPurchaseManagementTableData) => {
      /** 세관은 항상 입력/조회가 가능함 */
      if (partnerTableData.domain !== "customsDuty") {
        if (!partnerTableData.isFinancial) {
          handleSnackbarOpen("금전거래를 하지 않는 파트너입니다.", "error");
          return;
        }
      }

      if (partnerTableData.domain === "customsDuty") {
        setCompanyType("customsDuty");
        withdrawalModalOpen(partnerTableData.bidAccountPayableId, 0);
        return;
      }

      if (!partnerTableData.partnerId) {
        handleSnackbarOpen("파트너를 등록하지 않았습니다.", "warning");
        return;
      }

      if (partnerTableData.domain === "shipDaCustoms") {
        setCompanyType("customs");
        withdrawalModalOpen(
          partnerTableData.bidAccountPayableId,
          partnerTableData.partnerId
        );
        return;
      }

      setCompanyType(partnerTableData.domain);
      withdrawalModalOpen(
        partnerTableData.bidAccountPayableId,
        partnerTableData.partnerId
      );
    },
    [handleSnackbarOpen, withdrawalModalOpen]
  );

  const handleSwitchChange = useCallback(
    ({
      event,
      isForeignWithdrawalTarget,
      bidAccountPayableId,
    }: {
      event: React.ChangeEvent<HTMLInputElement>;
      isForeignWithdrawalTarget: boolean;
      bidAccountPayableId: number;
    }) => {
      if (isForeignWithdrawalTarget && event.target.checked === false) {
        setBidAccountPayableId(bidAccountPayableId);
        setOpensRegisterSettlementPartnerModal(true);
        return;
      }

      return changeForeignWithdrawalTarget(
        {
          isForeignWithdrawalTarget: true,
          pathParams: { bidAccountPayableId },
        },
        {
          onSuccess: () => {
            processSuccessResponse("정산대상을 예로 변경했습니다.");
          },
          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },
    [changeForeignWithdrawalTarget, handleSnackbarOpen, processSuccessResponse]
  );

  const getForeignWithdrawalTarget = useCallback(
    ({
      domain,
      isForeignWithdrawalTarget,
      bidAccountPayableId,
      isRequest,
    }: {
      domain: PartnerBusinessArea;
      isForeignWithdrawalTarget: boolean;
      bidAccountPayableId: number;
      isRequest: boolean;
    }) => {
      if (domain === "foreign") {
        return (
          <Switch
            disabled={isRequest}
            checked={isForeignWithdrawalTarget}
            onChange={(event) =>
              handleSwitchChange({
                event,
                isForeignWithdrawalTarget,
                bidAccountPayableId,
              })
            }
          />
        );
      }

      return undefined;
    },
    [handleSwitchChange]
  );

  const handleMoveToPurchaseManagement = useCallback(() => {
    setTrelloCardId(trelloDetailData.id);
    history.push("/settlement/purchaseManagement");
  }, [history, setTrelloCardId, trelloDetailData.id]);

  const handleDeleteBidAccountPayableModalOpen = useCallback(
    (bidAccountPayableId: number) => {
      return () => {
        setBidAccountPayableId(bidAccountPayableId);
        setOpensDeleteBidAccountPayableModal(true);
      };
    },
    []
  );

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    if (APP_NAME === "partner-admin") {
      return [
        {
          id: "partnerName",
          disablePadding: false,
          label: "회사명",
          width: 100,
        },
        {
          id: "upload",
          disablePadding: false,
          label: "새 파일",
          width: 80,
        },
        {
          id: "fileName",
          disablePadding: false,
          label: "파일 이름",
          width: 300,
        },
        {
          id: "fileDescription",
          disablePadding: false,
          label: "파일 설명",
          width: 150,
        },
        {
          id: "totalPrice",
          disablePadding: false,
          label: "공급액",
          width: 100,
          numeric: true,
        },
        {
          id: "vatPrice",
          disablePadding: false,
          label: "부가세",
          width: 100,
          numeric: true,
        },
        {
          id: "finalPrice",
          disablePadding: false,
          label: "합계",
          width: 100,
          numeric: true,
        },
        {
          id: "isRequest",
          disablePadding: false,
          label: "출금요청",
          width: 100,
        },
        {
          id: "isWithdrawn",
          disablePadding: false,
          label: "출금완료",
          width: 100,
        },
      ];
    }
    return [
      {
        id: "partnerName",
        disablePadding: false,
        label: "회사명",
        width: 100,
      },
      {
        id: "isForeignWithdrawalTarget",
        disablePadding: false,
        label: "정산 대상",
        width: 100,
      },
      {
        id: "upload",
        disablePadding: false,
        label: "새 파일",
        width: 80,
      },
      {
        id: "fileName",
        disablePadding: false,
        label: "파일 이름",
        width: 200,
      },
      {
        id: "fileDescription",
        disablePadding: false,
        label: "파일 설명",
        width: 150,
      },
      {
        id: "purchaseRequest",
        disablePadding: false,
        label: "출금액",
        width: 100,
      },
      {
        id: "totalPrice",
        disablePadding: false,
        label: "공급액",
        width: 100,
      },
      {
        id: "vatPrice",
        disablePadding: false,
        label: "부가세",
        width: 100,
      },
      {
        id: "finalPrice",
        disablePadding: false,
        label: "합계",
        width: 100,
      },
      {
        id: "isRequest",
        disablePadding: false,
        label: "출금요청",
        width: 100,
      },
      {
        id: "isWithdrawn",
        disablePadding: false,
        label: "출금완료",
        width: 100,
      },
      {
        id: "withdrawalDate",
        disablePadding: false,
        label: "출금일시",
        width: 100,
      },
      {
        id: "delete",
        disablePadding: false,
        label: "삭제",
        width: 80,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!trelloPurchaseManagementTableData) return [];

    if (APP_NAME === "partner-admin") {
      return trelloPurchaseManagementTableData.map((v) => {
        const row: LegacyTableBodyRow<CellId> = {
          partnerName:
            v.domain === "customsDuty"
              ? "세관"
              : getPartnerNameAutoComplete({
                  domain: v.domain,
                  partnerName: v.partnerName,
                  partnerId: v.partnerId,
                  accountPayableId: v.bidAccountPayableId,
                }),

          upload: (
            <Button
              size="small"
              disabled={v.isRequest}
              onClick={handleUploadModalOpen({
                partnerType: v.domain,
                accountPayableId: v.bidAccountPayableId,
              })}
              variant="text"
            >
              업로드
            </Button>
          ),
          fileName:
            v.attachment.length === 0 &&
            v.foreignWithdrawalTargetCompanyId &&
            partnerList ? (
              `(정산)${getPartnerCompanyName(
                partnerList,
                v.foreignWithdrawalTargetCompanyId
              )}`
            ) : (
              <List>
                {v.foreignWithdrawalTargetCompanyId && partnerList ? (
                  <ListItem
                    key={v.foreignWithdrawalTargetCompanyId}
                    disablePadding
                  >
                    <ListItemText
                      primary={
                        <Typography variant="body1" component="div">
                          {`(정산)${getPartnerCompanyName(
                            partnerList,
                            v.foreignWithdrawalTargetCompanyId
                          )}`}
                        </Typography>
                      }
                    />
                  </ListItem>
                ) : undefined}

                {v.attachment.map((n) => {
                  return (
                    <ListItem key={n.id} disablePadding>
                      <ListItemText
                        primary={
                          <FileName
                            isPurchaseRequest={v.isRequest}
                            projectStatus={trelloDetailData.projectStatus}
                            id={trelloDetailData.id}
                            attachment={n}
                            dataType={"trello"}
                            fileCategory={changePurchaseDomainToKr(v.domain)}
                          />
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            ),
          fileDescription:
            v.attachment.length === 0 &&
            v.foreignWithdrawalTargetCompanyId ? undefined : (
              <List>
                {v.foreignWithdrawalTargetCompanyId ? (
                  <ListItem
                    key={v.foreignWithdrawalTargetCompanyId}
                    disablePadding
                  >
                    <ListItemText
                      primary={
                        <Typography variant="body1" component="div">
                          {"-"}
                        </Typography>
                      }
                    />
                  </ListItem>
                ) : undefined}

                {v.attachment.map((n) => {
                  return (
                    <ListItem key={n.id} disablePadding>
                      <ListItemText
                        primary={
                          <Typography variant="body1" component="div">
                            {omitWithEllipsis({
                              src: n.description,
                              maxLength: 10,
                              ellipsis: "...",
                            })}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            ),
          totalPrice: v.purchaseRequest
            ? toThousandUnitFormat(v.totalPrice)
            : undefined,
          vatPrice: v.purchaseRequest
            ? toThousandUnitFormat(v.vatPrice)
            : undefined,
          finalPrice: v.purchaseRequest
            ? toThousandUnitFormat(v.finalPrice)
            : undefined,
          isRequest: v.isRequest ? "요청완료" : "-",
          isWithdrawn: v.isWithdrawn ? "출금완료" : "-",
        };

        return row;
      });
    }

    return trelloPurchaseManagementTableData.map((v) => {
      const row: LegacyTableBodyRow<CellId> = {
        partnerName:
          v.domain === "customsDuty"
            ? "세관"
            : getPartnerNameAutoComplete({
                domain: v.domain,
                partnerName: v.partnerName,
                partnerId: v.partnerId,
                accountPayableId: v.bidAccountPayableId,
              }),

        isForeignWithdrawalTarget: getForeignWithdrawalTarget({
          domain: v.domain,
          isForeignWithdrawalTarget: v.isForeignWithdrawalTarget,
          bidAccountPayableId: v.bidAccountPayableId,
          isRequest: v.isRequest,
        }),

        upload: (
          <Button
            size="small"
            disabled={
              v.isRequest ||
              (v.domain === "foreign" && !v.isForeignWithdrawalTarget)
            }
            onClick={handleUploadModalOpen({
              partnerType: v.domain,
              accountPayableId: v.bidAccountPayableId,
            })}
            variant="text"
          >
            업로드
          </Button>
        ),

        fileName:
          v.attachment.length === 0 &&
          v.foreignWithdrawalTargetCompanyId &&
          partnerList ? (
            `(정산)${getPartnerCompanyName(
              partnerList,
              v.foreignWithdrawalTargetCompanyId
            )}`
          ) : (
            <List>
              {v.foreignWithdrawalTargetCompanyId && partnerList ? (
                <ListItem
                  key={v.foreignWithdrawalTargetCompanyId}
                  disablePadding
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" component="div">
                        {`(정산)${getPartnerCompanyName(
                          partnerList,
                          v.foreignWithdrawalTargetCompanyId
                        )}`}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : undefined}

              {v.attachment.map((n) => {
                return (
                  <ListItem key={n.id} disablePadding>
                    <ListItemText
                      primary={
                        <FileName
                          isPurchaseRequest={v.isRequest}
                          projectStatus={trelloDetailData.projectStatus}
                          id={trelloDetailData.id}
                          attachment={n}
                          dataType={"trello"}
                          fileCategory={changePurchaseDomainToKr(v.domain)}
                        />
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          ),

        fileDescription:
          v.attachment.length === 0 &&
          v.foreignWithdrawalTargetCompanyId ? undefined : (
            <List>
              {v.foreignWithdrawalTargetCompanyId ? (
                <ListItem
                  key={v.foreignWithdrawalTargetCompanyId}
                  disablePadding
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1" component="div">
                        {"-"}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : undefined}

              {v.attachment.map((n) => {
                return (
                  <ListItem key={n.id} disablePadding>
                    <ListItemText
                      primary={
                        <Typography variant="body1" component="div">
                          {omitWithEllipsis({
                            src: n.description,
                            maxLength: 10,
                            ellipsis: "...",
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          ),

        purchaseRequest: (
          <Button
            size="small"
            disabled={v.domain === "foreign" && !v.isForeignWithdrawalTarget}
            onClick={() => handleWithdrawalModalOpen(v)}
            variant="outlined"
          >
            {v.purchaseRequest ? "조회" : "입력"}
          </Button>
        ),

        totalPrice: v.purchaseRequest
          ? toThousandUnitFormat(v.totalPrice)
          : undefined,

        vatPrice: v.purchaseRequest
          ? toThousandUnitFormat(v.vatPrice)
          : undefined,

        finalPrice: v.purchaseRequest
          ? toThousandUnitFormat(v.finalPrice)
          : undefined,

        isRequest: v.isRequest ? "요청완료" : "-",

        isWithdrawn: v.isWithdrawn ? "출금완료" : "-",

        withdrawalDate: replaceEmptyToDash(
          toFormattedDate(v.withdrawalDate, "YY.MM.DD HH:mm")
        ),

        delete: (
          <Button
            disabled={
              v.isRequest ||
              v.domain === "customs" ||
              v.domain === "inland" ||
              v.domain === "parcel"
            }
            size="small"
            onClick={handleDeleteBidAccountPayableModalOpen(
              v.bidAccountPayableId
            )}
            variant="outlined"
            color="error"
          >
            삭제
          </Button>
        ),
      };

      return row;
    });
  }, [
    getForeignWithdrawalTarget,
    getPartnerNameAutoComplete,
    handleDeleteBidAccountPayableModalOpen,
    handleUploadModalOpen,
    handleWithdrawalModalOpen,
    partnerList,
    trelloDetailData.id,
    trelloDetailData.projectStatus,
    trelloPurchaseManagementTableData,
  ]);

  return (
    <Grid container direction="column" sx={{ marginBottom: "16px" }}>
      <Grid item xs={1}>
        <Typography
          onClick={handleMoveToPurchaseManagement}
          variant="subtitle1"
          component="div"
          sx={{ color: blue[700], cursor: "pointer" }}
        >
          매입 관리
        </Typography>
      </Grid>

      <Grid item>
        <LegacyTable headCells={headCells} rows={rows} />
      </Grid>

      {showsUploadModal && (
        <UploadModal
          setShowsUploadModal={setShowsUploadModal}
          showsUploadModal={showsUploadModal}
          type={invoiceType}
          id={trelloDetailData.id}
          dataType={"trello"}
          isPermission={true}
          bidAccountPayableId={bidAccountPayableId}
          teamId={trelloDetailData.teamId}
        />
      )}

      {APP_NAME === "shipda-admin" && (
        <Grid item>
          <Button variant="text" onClick={handlePopoverOpen}>
            추가
          </Button>

          <Popover
            open={Boolean(popoverAnchorEl)}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Paper>
              <MenuList autoFocusItem>
                {TRELLO_PURCHASE_PARTNER_OPTION_LIST.map((v) => {
                  return (
                    <MenuItem
                      onClick={handleAccountPayableUpdate(v.value)}
                      key={v.value}
                      id={v.value}
                    >
                      {v.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Popover>
        </Grid>
      )}

      {showsPurchaseInvoiceRequestModal && (
        <PurchaseRequestModal
          showsPurchaseInvoiceRequestModal={showsPurchaseInvoiceRequestModal}
          setShowsPurchaseInvoiceRequestModal={
            setShowsPurchaseInvoiceRequestModal
          }
          bidId={trelloDetailData.id}
          companyType={companyType}
          freightType={trelloDetailData.freightType}
          companyId={companyId}
          exchangeRateList={exchangeRateList}
          bidAccountPayableId={bidAccountPayableId}
          trelloDetailData={trelloDetailData}
        />
      )}

      {opensChangeCustomsPartnerModal && (
        <ChangeCustomsPartnerModal
          handleChangeCustomsPartnerToShipper={
            handleChangeCustomsPartnerToShipper
          }
          bidAccountPayableId={bidAccountPayableId}
          opensChangeCustomsPartnerModal={opensChangeCustomsPartnerModal}
          setOpensChangeCustomsPartnerModal={setOpensChangeCustomsPartnerModal}
        />
      )}

      {opensRegisterSettlementPartnerModal && partnerList && (
        <RegisterSettlementPartnerModal
          bidAccountPayableId={bidAccountPayableId}
          opensRegisterSettlementPartnerModal={
            opensRegisterSettlementPartnerModal
          }
          setOpensRegisterSettlementPartnerModal={
            setOpensRegisterSettlementPartnerModal
          }
          etcPartnerListForAutoComplete={etcPartnerListForAutoComplete}
          processSuccessResponse={processSuccessResponse}
        />
      )}

      {opensDeleteBidAccountPayableModal && (
        <DeleteBidAccountPayableModal
          bidAccountPayableId={bidAccountPayableId}
          opensDeleteBidAccountPayableModal={opensDeleteBidAccountPayableModal}
          setOpensDeleteBidAccountPayableModal={
            setOpensDeleteBidAccountPayableModal
          }
          processSuccessResponse={processSuccessResponse}
        />
      )}
    </Grid>
  );
}

export default PurchaseManagement;
