import { useLocation } from "react-router-dom";

import GoogleMapWithCluster from "@sellernote/_shared/src/components/googleMap/GoogleMapWithCluster";
import useShipmentMapMarkerInfoList from "@sellernote/_shared/src/hooks/forwarding/useShipmentMapMarkerInfoList";
import useShipmentMapPolyline from "@sellernote/_shared/src/hooks/forwarding/useShipmentMapPolyline";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";

import Styled from "./index.styles";

export default function Map({
  trelloId,
  freightType,
}: {
  trelloId: number;
  freightType: FreightType;
}) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  // TODO: 맵데이터의 정확성 파악을 할 때까지 특정 쿼리로 항적을 활성화(기본 비활성화)
  const showsLCLPolyline = query.get("showsLCLPolyline");

  const { data: trackingShipResult, isError } =
    TRELLO_BID_QUERY.useGetTrelloTrackingShip({
      trelloId,
      enabled: Boolean(trelloId),
    });

  const { centerPosition, markerInfoList } = useShipmentMapMarkerInfoList({
    trackingShipResult,
    freightType,
  });

  const { polylineInfo, dashedPolylineInfo } = useShipmentMapPolyline({
    trackingShipResult,
  });

  return (
    <Styled.container>
      <GoogleMapWithCluster
        paddingTop={"400px"}
        zoom={5}
        centerPosition={centerPosition}
        markerInfoList={markerInfoList}
        isAutoZoom={true}
        polylineInfo={
          showsLCLPolyline === "true" || freightType === "FCL"
            ? polylineInfo
            : undefined
        }
        dashedPolylineInfo={
          showsLCLPolyline === "true" || freightType === "FCL"
            ? dashedPolylineInfo
            : undefined
        }
        hasError={isError}
      />
    </Styled.container>
  );
}
