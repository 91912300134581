import styled from "styled-components";

const container = styled.div`
  background-color: #f5f5f5;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
`;

const infoContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex: 1;

  > .title {
    color: #757575;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: -0.12px;
    font-weight: 700;
  }

  > .subtitle {
    color: #757575;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.048px;
  }

  > .blNumber {
    color: #424242;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.072px;
  }
`;

export default { container, infoContainer };
