import { useFormContext, useWatch } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { AdminShipmentInfoForm } from "@sellernote/_shared/src/types/forwarding/adminBid";

import RadioGroupWithReactHookForm from "../../../../../../components/RadioGroupWithReactHookForm";

import {
  containerAccessableRadioGroupOptionList,
  containerStuffingRadioGroupOptionList,
  inclusionConditionGroupOptionList,
} from "../constants";

function FCLCheckpoint({ isImport }: { isImport: boolean }) {
  const { control } = useFormContext<AdminShipmentInfoForm>();

  const endType = useWatch({
    control,
    name: "endType",
  });

  const startType = useWatch({
    control,
    name: "startType",
  });

  if (isImport) {
    return (
      <Box>
        <Box>
          <Typography variant="subtitle2">LSS</Typography>

          <RadioGroupWithReactHookForm
            name={"containLss"}
            control={control}
            radioGroupOption={inclusionConditionGroupOptionList}
            required={true}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2">Ocean Surcharge</Typography>

          <RadioGroupWithReactHookForm
            name={"containOceanSurcharge"}
            control={control}
            radioGroupOption={inclusionConditionGroupOptionList}
            required={true}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2">국내부대비용</Typography>

          <RadioGroupWithReactHookForm
            name={"containDomesticFee"}
            control={control}
            radioGroupOption={inclusionConditionGroupOptionList}
            required={true}
          />
        </Box>

        {endType === "inland" && (
          <Box>
            <Typography variant="subtitle2">
              도착지 컨테이너 진입여부
            </Typography>

            <RadioGroupWithReactHookForm
              name={"containerAccessable"}
              control={control}
              radioGroupOption={containerAccessableRadioGroupOptionList}
              required={true}
            />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box>
      {startType === "inland" && (
        <Box>
          <Typography variant="subtitle2">출발지 컨테이너 진입가능</Typography>

          <RadioGroupWithReactHookForm
            name={"containerStuffing"}
            control={control}
            required={true}
            radioGroupOption={containerStuffingRadioGroupOptionList}
          />
        </Box>
      )}
    </Box>
  );
}

export default FCLCheckpoint;
