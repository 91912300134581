import { ManagementStatus } from "@sellernote/_shared/src/types/forwarding/trello";

import ExtraSmallChip from "../../../../components/ExtraSmallChip";

type ManagementStatusWithoutNone = Exclude<ManagementStatus, "none">;

export default function CustomStatusChip({
  status,
}: {
  status: ManagementStatus;
}) {
  if (status === "none") return null;

  const label: Record<ManagementStatusWithoutNone, string> = {
    inProgress: "통관 중",
    reviewDocuments: "서류검토 요청",
    quarantine: "검역 진행 중",
    selectivity: "검사 선별",
  };

  const color: Record<
    ManagementStatusWithoutNone,
    "info" | "success" | "warning" | "error"
  > = {
    inProgress: "info",
    reviewDocuments: "success",
    quarantine: "warning",
    selectivity: "error",
  };

  return (
    <ExtraSmallChip size="small" label={label[status]} color={color[status]} />
  );
}
