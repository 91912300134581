import { useMemo } from "react";
import { UseFormWatch } from "react-hook-form";

import { ParcelRequestDocumentForm } from "@sellernote/_shared/src/types/forwarding/trello";

import filledLogo from "../../../../../../../images/createDocument/filledLogo.png";

export default function useParcelRequestDocumentPreview(
  watch: UseFormWatch<ParcelRequestDocumentForm>,
  docNum: string
) {
  const {
    blNumber,
    consignee,
    cbm,
    packageInfo,
    weight,
    warehouseName,
    warehouseAddress,
    warehouseContact,
    arrivalCompany,
    destinationAddress,
    recipient,
    phone,
    comment,
    adminName,
    adminPhone,
    prepaid,
  } = watch();

  const parcelRequestDocumentHtml = useMemo(() => {
    return `<!DOCTYPE html>
    <html lang="ko">
      <head>
        <link
          href="https://webfontworld.github.io/sunn/SUIT.css"
          rel="stylesheet"
        />
        <meta charset="UTF-8" />
        <title>Title</title>
        <style type="text/css"></style>
      </head>
    
      <body style="width: 595px; height: 842px; background-color: #ffffff">
        <div
          style="
            padding: 32px;
            letter-spacing: -0.001em;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
          "
        >
          <div style="text-align: right">
            <img alt="" src="${filledLogo}" style="width: 58.8px; height: 24px" />

            <div style="font-size: 12px;">Doc#${docNum}</div>
          </div>
    
          <div style="font-size: 18px; text-align: center; font-weight: 700">
            택배${prepaid ? "{선불)" : "(후불)"} 오더 접수장
          </div>
    
          <div style="margin-top: 40px; display: flex">
            <div>안녕하세요. 셀러노트(쉽다)입니다.</div>
          </div>
    
          <div style="border: 1px dashed #b0b0b0; margin-top: 24px"></div>
    
          <div style="margin-top: 24px; display: flex">
            <div>[BL#]</div>
    
            <div style="font-weight: 500; margin-left: 16px">${blNumber}</div>
          </div>
    
          <div style="margin-top: 12px; display: flex">
            <div>[화주]</div>
    
            <div style="font-weight: 500; margin-left: 16px">${consignee}</div>
          </div>
    
          <div style="margin-top: 10px; margin-left: 8px; display: flex">
            <div style="margin-right: 4px">&#8226;</div>
    
            <div>${packageInfo} / ${weight} / ${cbm}</div>
          </div>
    
          <div style="margin-top: 16px; margin-left: 8px; display: flex">
            <div style="margin-right: 4px">&#8226;</div>
    
            <div>픽업 창고: <b>${warehouseName}</b></div>
          </div>
    
          <div style="margin-top: 10px; margin-left: 16px">${warehouseAddress}</div>
    
          <div style="margin-top: 10px; margin-left: 16px">${warehouseContact}</div>
    
          <div style="margin-top: 16px; margin-left: 8px; display: flex">
            <div style="margin-right: 4px">&#8226;</div>
    
            <div>받는 곳: <b>${arrivalCompany}</b></div>
          </div>
    
          <div style="margin-top: 10px; margin-left: 16px">
            ${destinationAddress}
          </div>
    
          <div style="margin-top: 10px; margin-left: 16px">
            ${phone} (${recipient})
          </div>
    
          <div style="border: 1px dashed #b0b0b0; margin-top: 24px"></div>
    
          <div style="margin-top: 24px; margin-left: 8px; font-weight: 500">
            ${comment}
          </div>
    
          <div style="margin-top: 40px; text-align: right">
            <div style="font-weight: 700; font-size: 18px">${adminName}</div>
    
            <div style="margin-top: 8px"><b>Fax.</b> 0505-901-7210</div>
    
            <div style="margin-top: 8px"><b>E-mail.</b> cs.fw@ship-da.com</div>
    
            <div style="margin-top: 8px"><b>Tel.</b> ${adminPhone}</div>
          </div>
        </div>
      </body>
    </html>`;
  }, [
    adminName,
    adminPhone,
    arrivalCompany,
    blNumber,
    cbm,
    comment,
    consignee,
    destinationAddress,
    docNum,
    packageInfo,
    phone,
    prepaid,
    recipient,
    warehouseAddress,
    warehouseContact,
    warehouseName,
    weight,
  ]);

  return parcelRequestDocumentHtml;
}
