import { Tab, Tabs } from "@mui/material";

const COMMON_STYLE = { borderBottom: 1, borderColor: "divider" };

export default function PreviewTypeTab({
  tab,
  onTabChange,
  isImport,
}: {
  tab: "arrivalNotice" | "BL" | "exportDeclaration";
  onTabChange: (v: "arrivalNotice" | "BL" | "exportDeclaration") => void;
  isImport: boolean;
}) {
  return (
    <Tabs value={tab} onChange={(_, v) => onTabChange(v)}>
      {isImport ? (
        <Tab label="AN(적하목록)" value="arrivalNotice" sx={COMMON_STYLE} />
      ) : (
        <Tab label="수출신고필증" value="exportDeclaration" sx={COMMON_STYLE} />
      )}

      <Tab label="B/L" value="BL" sx={COMMON_STYLE} />
    </Tabs>
  );
}
