import { useMemo } from "react";

import { GET_TRELLO_ATTACHMENTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

export default function useCheckHasAttachment(
  attachments: GET_TRELLO_ATTACHMENTS_RES | undefined
) {
  const hasCIPLAttachment = useMemo(() => {
    if (!attachments) {
      return false;
    }

    return attachments.some((v) => {
      return v.domain === "CI_PL";
    });
  }, [attachments]);

  const hasCOAttachment = useMemo(() => {
    if (!attachments) {
      return false;
    }

    return attachments.some((v) => {
      return v.domain === "CO";
    });
  }, [attachments]);

  const hasBrnAttachment = useMemo(() => {
    if (!attachments) {
      return false;
    }

    return attachments.some((v) => {
      return v.domain === "brn";
    });
  }, [attachments]);

  const hasCertificateAttachment = useMemo(() => {
    if (!attachments) {
      return false;
    }

    return attachments.some((v) => {
      return v.domain === "certificate";
    });
  }, [attachments]);

  const hasEtcAttachment = useMemo(() => {
    if (!attachments) {
      return false;
    }

    return attachments.some((v) => {
      return v.domain === "etc";
    });
  }, [attachments]);

  return {
    hasCIPLAttachment,
    hasCOAttachment,
    hasBrnAttachment,
    hasCertificateAttachment,
    hasEtcAttachment,
  };
}
