import { useCallback, useMemo } from "react";
import { Box, Button } from "@mui/material";
import { useAtom } from "jotai";

import { SHIPMENT_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import {
  BidProjectStatus,
  BidServiceType,
  BidStatus,
} from "@sellernote/_shared/src/types/forwarding/bid";
import {
  BID_LIST_SEARCH_TYPE_OPTION_LIST,
  BID_PROJECT_STATUS_OPTION_LIST,
  BID_SERVICE_TYPE_OPTION_LIST,
  BID_STATUS_OPTION_LIST,
} from "@sellernote/_shared/src/utils/common/options";

import useMuiSelect from "../../../hooks/useMuiSelect";
import useSearchWithDate from "../../../hooks/useSearchWithDate";
import { SearchWithDateTypeOption } from "../../../hooks/useSearchWithDateWithNoUseEffect";
import useSearchWithTerm from "../../../hooks/useSearchWithTerm";

import { FORWARDING_ADMIN_BID_JOTAI_ATOMS } from "../../../jotaiStates/bid";

const dateSearchTypeOptions: SearchWithDateTypeOption<"createdAt">[] = [
  {
    label: "생성일",
    value: "createdAt",
  },
];

export default function useShipmentTablePanel() {
  const [filterData, setFilterData] = useAtom(
    FORWARDING_ADMIN_BID_JOTAI_ATOMS.SHIPMENT_TABLE_FILTER_LIST
  );

  const historyTermSearchType = useMemo(() => {
    return BID_LIST_SEARCH_TYPE_OPTION_LIST.find(
      (item) => filterData[item.value] && item
    );
  }, [filterData]);

  const resetCurrentPage = useCallback(() => {
    // 페이지가 0일 아닐때만 초기화 해준다.
    if (filterData.page !== 0) {
      setFilterData({
        ...filterData,
        page: 0,
      });
    }
  }, [filterData, setFilterData]);

  const {
    TermSearchPanel,
    debouncedSearchTermWithObject,
    reset: resetSearchWithTerm,
    searchTerm,
    debouncedSearchTerm,
  } = useSearchWithTerm({
    termSearchTypeOptions: BID_LIST_SEARCH_TYPE_OPTION_LIST,
    historyTermSearchType,
    historySearchTerm: historyTermSearchType
      ? filterData[historyTermSearchType.value]
      : undefined,
    resetCurrentPage,
  });

  const {
    DateSearchPanel,
    dateSearchType,
    startDate,
    endDate,
    handleDateReset,
  } = useSearchWithDate({
    dateSearchTypeOptions,
    historyStartDate: filterData?.fromDate,
    historyEndDate: filterData?.toDate,
    resetCurrentPage,
  });

  const {
    selectedValue: status,
    MuiSelect: statusSelect,
    setSelectedValue: setStatusSelectedValue,
  } = useMuiSelect({
    options: BID_STATUS_OPTION_LIST,
    title: "상태",
    minWidth: 120,
    defaultValue: filterData.status ?? "all",
    resetCurrentPage,
  });

  const {
    selectedValue: projectStatus,
    MuiSelect: projectStatusSelect,
    setSelectedValue: setProjectStatusSelectedValue,
  } = useMuiSelect({
    options: BID_PROJECT_STATUS_OPTION_LIST,
    title: "세부 상태",
    minWidth: 120,
    defaultValue: filterData.projectStatus ?? "all",
    resetCurrentPage,
  });

  const {
    selectedValue: serviceType,
    MuiSelect: serviceTypeSelect,
    setSelectedValue: setServiceTypeSelectedValue,
  } = useMuiSelect({
    options: BID_SERVICE_TYPE_OPTION_LIST,
    title: "서비스",
    minWidth: 120,
    defaultValue: filterData.serviceType ?? "all",
    resetCurrentPage,
  });

  const {
    selectedValue: shipmentType,
    MuiSelect: ShipmentTypeSelect,
    setSelectedValue: setShipmentTypeSelectedValue,
  } = useMuiSelect({
    options: SHIPMENT_TYPE_OPTION_LIST,
    title: "수출/입",
    minWidth: 120,
    defaultValue: filterData.shipmentType ?? "all",
    resetCurrentPage,
  });

  const statusFilterValue = useMemo(() => {
    if (status === "all") return;

    return status as BidStatus;
  }, [status]);

  const serviceTypeFilterValue = useMemo(() => {
    if (serviceType === "all") return;
    return serviceType as BidServiceType;
  }, [serviceType]);

  const projectStatusFilterValue = useMemo(() => {
    if (projectStatus === "all") return;
    return projectStatus as BidProjectStatus;
  }, [projectStatus]);

  const isImport = useMemo(() => {
    // 전체인 경우 payload에 담지 않기 위해 undefined를 리턴
    if (shipmentType === "all") return;

    // 수출입 payload가 isImport: boolean 이라서 boolean값을 리턴
    return shipmentType === "importation";
  }, [shipmentType]);

  const debouncedSearchTermVerifiedByNumberOfCharacters = useMemo(() => {
    if (!debouncedSearchTermWithObject) {
      return undefined;
    }

    if ("BL" in debouncedSearchTermWithObject) {
      if (debouncedSearchTermWithObject["BL"].length >= 5) {
        return debouncedSearchTermWithObject;
      }
      return undefined;
    }

    return debouncedSearchTermWithObject;
  }, [debouncedSearchTermWithObject]);

  /**
   * 페이지 초기화 시 요청이 중복으로 가는걸 방지하기 위한 API 요청 enabled 값
   * 초기화 시 페이지 변경을 먼저 감지해서 요청을 하고 이후 검색어 리셋을 한번 더 감지해서 결국 두번 요청을 함
   * 페이지, 검색어 초기화 시 한번만 요청하기 위해서 검색어가 확실히 변경된 것을 확인하기 위해 체크
   */
  const isEqualSearchTerm = searchTerm === debouncedSearchTerm;

  const handleReset = useCallback(() => {
    resetSearchWithTerm(event);
    setStatusSelectedValue("all");
    setProjectStatusSelectedValue("all");
    setServiceTypeSelectedValue("all");
    setShipmentTypeSelectedValue("all");
    handleDateReset();
  }, [
    handleDateReset,
    resetSearchWithTerm,
    setProjectStatusSelectedValue,
    setServiceTypeSelectedValue,
    setShipmentTypeSelectedValue,
    setStatusSelectedValue,
  ]);

  const TablePanel = (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box>{ShipmentTypeSelect}</Box>

          <Box>{statusSelect}</Box>

          <Box>{projectStatusSelect}</Box>

          <Box>{serviceTypeSelect}</Box>
        </Box>

        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {DateSearchPanel}
          {TermSearchPanel}
        </Box>
      </Box>

      <Button
        variant="outlined"
        onClick={handleReset}
        sx={{ alignSelf: "flex-start", flex: "0 0 auto", height: "40px" }}
      >
        초기화
      </Button>
    </Box>
  );
  return {
    debouncedSearchTermWithObject:
      debouncedSearchTermVerifiedByNumberOfCharacters,
    status: statusFilterValue,
    serviceType: serviceTypeFilterValue,
    projectStatus: projectStatusFilterValue,
    TablePanel,
    dateSearchType,
    startDate,
    endDate,
    isEqualSearchTerm,
    isImport,
  };
}
