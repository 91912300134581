import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useSetAtom } from "jotai";

import ADMIN_SLACK_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SLACK_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { InvoiceType } from "@sellernote/_shared/src/types/forwarding/trello";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

import { SETTLEMENT_ADMIN_ATOMS } from "../../../../../../jotaiStates/settlement";

function CancelInvoiceModal({
  showsCancelInvoiceModal,
  setShowsCancelInvoiceModal,
  bidId,
  invoiceId,
  setInvoiceId,
  invoiceType,
  isRequestedAndNotIssued,
}: {
  showsCancelInvoiceModal: boolean;
  setShowsCancelInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidId: number;
  invoiceId: number;
  setInvoiceId: (val: number) => void;
  invoiceType: InvoiceType;
  isRequestedAndNotIssued: boolean;
}) {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [cancelReason, setCancelReason] = useState("직접 입력");
  const [etcReason, setEtcReason] = useState("");

  const setSalesManagementShipmentIdFilter = useSetAtom(
    SETTLEMENT_ADMIN_ATOMS.SALES_MANAGEMENT_SHIPMENT_ID_FILTER
  );

  const commonRequestFunction = () => {
    queryClient.invalidateQueries(
      TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
        bidId: bidId,
      })
    );

    setInvoiceId(0);
    setShowsCancelInvoiceModal(false);
  };

  const {
    mutate: cancelInvoice,
    ResponseHandler: ResponseHandlerOfCancelInvoice,
  } = ADMIN_SLACK_QUERY.useCancelInvoice({
    ...(isRequestedAndNotIssued
      ? {
          onSuccess: () => {
            commonRequestFunction();
            setSalesManagementShipmentIdFilter(bidId);

            /** 발행되지 않은 계산서의 발행요청 취소 시 슬랙알림 전송하지 않고 매출관리 링크로 이동 (#14041) */
            if (isRequestedAndNotIssued)
              history.push("/settlement/salesManagement");
          },
        }
      : {
          successModalInfo: {
            handleConfirmSuccess: (initQuery) => {
              initQuery();
              commonRequestFunction();
            },
            customizeMessage: () => ({
              title: "계산서 발행 취소 슬랙 알람을 발송했습니다.",
            }),
          },
        }),
  });

  const handleModalClose = useCallback(() => {
    setShowsCancelInvoiceModal(false);
  }, [setShowsCancelInvoiceModal]);

  const handleCancelReasonSelect = useCallback((event: SelectChangeEvent) => {
    setCancelReason(event.target.value);
  }, []);

  const handleEtcReasonChange = useCallback(
    (e) => setEtcReason(e.target.value),
    []
  );

  const handleCancelInvoiceClick = useCallback(() => {
    cancelInvoice({
      invoiceId,
      bidId,
      cancelReason: cancelReason === "직접 입력" ? etcReason : cancelReason,
      invoiceType,
    });
  }, [bidId, cancelInvoice, cancelReason, etcReason, invoiceType, invoiceId]);

  return (
    <Modal
      handleClose={handleModalClose}
      isOpened={showsCancelInvoiceModal}
      modalBody={
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ color: blue[700] }}
            >
              계산서 발행 취소 요청
            </Typography>
          </Grid>

          <Grid item>
            <Select
              value={cancelReason}
              onChange={handleCancelReasonSelect}
              label="서류 생성"
            >
              <MenuItem value="직접 입력">직접 입력</MenuItem>
              <MenuItem value="입금일변경">입금일변경</MenuItem>
              <MenuItem value="금액변경">금액변경</MenuItem>
              <MenuItem value="화주명 변경">화주명 변경</MenuItem>
              <MenuItem value="발행요청 실수">발행요청 실수</MenuItem>
            </Select>
          </Grid>

          {cancelReason === "직접 입력" && (
            <Grid item>
              <TextField
                sx={{ width: 280 }}
                label={"직접입력"}
                onChange={handleEtcReasonChange}
                value={etcReason}
              />
            </Grid>
          )}

          <Grid item>
            <Button
              disabled={cancelReason === "직접 입력" && !etcReason}
              onClick={handleCancelInvoiceClick}
            >
              요청하기
            </Button>
          </Grid>

          {ResponseHandlerOfCancelInvoice}
        </Grid>
      }
    />
  );
}

export default CancelInvoiceModal;
