import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import FilePreview from "./FilePreview";
import Form from "./Form";

function GeneratorModal({
  opensGeneratorModal,
  onGeneratorModalToggle,
  shipmentId,
  HBLData,
}: {
  opensGeneratorModal: boolean;
  onGeneratorModalToggle: () => void;
  shipmentId: number;
  HBLData: HBLInfo;
}) {
  const { data: attachmentFileList } = TRELLO_BID_QUERY.useGetTrelloAttachments(
    {
      bidId: shipmentId,
    }
  );

  const commonStyle = {
    background: "white",
    borderRadius: 2,
    overflow: "hidden",
    height: 900,
  };

  return (
    <Modal open={opensGeneratorModal} onClose={onGeneratorModalToggle}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          gap: 1,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onGeneratorModalToggle}
          sx={{
            position: "absolute",
            top: 0,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            ...commonStyle,
            width: 800,
          }}
        >
          <FilePreview attachmentFileList={attachmentFileList} />
        </Box>

        <Box
          sx={{
            ...commonStyle,
            width: 700,
            display: "flex",
            justifyContent: "center",
            overflow: "auto",
          }}
        >
          <Form shipmentId={shipmentId} HBLData={HBLData} />
        </Box>
      </Box>
    </Modal>
  );
}

export default GeneratorModal;
