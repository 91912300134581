import { Box, Typography } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";

import Modal from "../../../../../../components/Modal";

import Map from "./Map";

function TrackingShipModal({
  showsTrackingShipModal,
  setShowsTrackingShipModal,
  trelloId,
  freightType,
}: {
  showsTrackingShipModal: boolean;
  setShowsTrackingShipModal: (val: boolean) => void;
  trelloId: number;
  freightType: FreightType;
}) {
  return (
    <Modal
      isOpened={showsTrackingShipModal}
      handleClose={() => {
        setShowsTrackingShipModal(false);
      }}
      modalBody={
        <Box sx={{ width: "800px", height: "450px" }}>
          <Typography variant="h4">선박 트랙킹</Typography>

          <Map trelloId={trelloId} freightType={freightType} />
        </Box>
      }
    />
  );
}

export default TrackingShipModal;
