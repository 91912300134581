import { useEffect, useRef } from "react";

/**
 * 컨테이너 정보 폼 > 마우스 휠로 좌우 스크롤 가능하도록 하는 커스텀 훅
 */
export default function useContainerBoxHorizontalScroll() {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const onWheel = (e: { deltaY: number; preventDefault: () => void }) => {
        if (e.deltaY === 0) return;
        e.preventDefault();

        container.scrollTo({
          left: container.scrollLeft + e.deltaY,
        });
      };

      container.addEventListener("wheel", onWheel);

      return () => container.removeEventListener("wheel", onWheel);
    }
  }, []);

  return { containerRef };
}
