import { useState } from "react";
import { FieldNamesMarkedBoolean, UseFormReset } from "react-hook-form";
import { useQueryClient } from "react-query";

import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  AdminShipmentItemGroupForm,
  ShipmentItem,
} from "@sellernote/_shared/src/types/forwarding/adminBid";

import useSnackbar from "../../../../../../hooks/useSnackbar";
import { getRequestPayload } from "./utils";

export default function useItemUpdateRequest({
  shipmentDetail,
  consolidationExporterGroupId,
  cargoInputMode,
  itemGroupList,
  dirtyFields,
  resetFormState,
}: {
  shipmentDetail: AdminBidDetail;
  consolidationExporterGroupId?: number;
  cargoInputMode: ShipmentItem["mode"];
  itemGroupList: AdminShipmentItemGroupForm[];
  dirtyFields: {
    itemGroupList?: FieldNamesMarkedBoolean<AdminShipmentItemGroupForm>[];
  };
  resetFormState: UseFormReset<{
    itemGroupList: AdminShipmentItemGroupForm[];
  }>;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [showsQuotationResetModal, setShowsQuotationResetModal] =
    useState(false);

  const { mutate: updateShipmentItemCargo } =
    ADMIN_BID_QUERY.useUpdateShipmentItemCargo();

  const { mutate: checkNeedToChangeQuotation } =
    ADMIN_BID_QUERY.useCheckNeedToChangeQuotationByUpdateShipmentItemCargo();

  /**
   * '화물정보 변경시 견적도 변경필요한 것인지' 체크한 후 화물정보를 업데이트
   */
  const checkNeedToChangeQuotationAndUpdateCargo = () => {
    // TODO: `handleShipmentItemCargoUpdate`의 로직과 중복이지만, 공통으로 분리할 방법이 떠오르지 않아 일단 둠.
    const { deletedItemIdList, updatedItemGroupList } = getRequestPayload({
      shipmentDetail,
      consolidationExporterGroupId,
      itemGroupList,
      dirtyFields,
      cargoInputMode,
    });
    if (updatedItemGroupList.length === 0 && deletedItemIdList.length === 0) {
      handleSnackbarOpen("수정한 내용이 없습니다.", "warning");
      return;
    }

    const hasQuotation = !!shipmentDetail.quotationsUser.length;

    if (hasQuotation) {
      // 확정 견적이 있는 경우는 견적수정이 필요한건지 체크한다.
      checkNeedToChangeQuotation(
        {
          pathParams: { shipmentId: shipmentDetail.id },
          itemGroupList: updatedItemGroupList,
          deleteBidItemIds: deletedItemIdList,
          exporterGroup: consolidationExporterGroupId,
        },
        {
          onSuccess: ({ data }) => {
            if (data) {
              /** 견적을 수정 해야하는 경우로, 견적 리셋 방법 선택 모달 오픈 */
              setShowsQuotationResetModal(true);
            } else {
              handleShipmentItemCargoUpdate();
            }
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    } else {
      handleShipmentItemCargoUpdate();
    }
  };

  /**
   * 화물정보를 업데이트
   */
  const handleShipmentItemCargoUpdate = (successCallback?: () => void) => {
    const { deletedItemIdList, updatedItemGroupList } = getRequestPayload({
      shipmentDetail,
      consolidationExporterGroupId,
      itemGroupList,
      dirtyFields,
      cargoInputMode,
    });

    if (updatedItemGroupList.length === 0 && deletedItemIdList.length === 0) {
      handleSnackbarOpen("수정한 내용이 없습니다.", "warning");
      return;
    }

    updateShipmentItemCargo(
      {
        pathParams: { shipmentId: shipmentDetail.id },
        itemGroupList: updatedItemGroupList,
        deleteBidItemIds: deletedItemIdList,
        exporterGroup: consolidationExporterGroupId,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentDetail.id,
            })
          );

          if (successCallback) {
            successCallback();
          }

          /** dirty 상태를 초기화한다 */
          resetFormState({}, { keepValues: true });
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return {
    checkNeedToChangeQuotationAndUpdateCargo,
    handleShipmentItemCargoUpdate,
    showsQuotationResetModal,
    setShowsQuotationResetModal,
  };
}
