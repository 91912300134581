import styled from "styled-components";

const container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  padding: 32px 24px 0px;
  width: 600px;
  font-family: "Spoqa Han Sans", sans-serif;
`;

export default {
  container,
};
