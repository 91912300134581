import { Box, Button, Typography } from "@mui/material";

import Modal from "../../../../../../components/Modal";
import TextField from "../../../../../../components/TextField";

export default function AddMemoModal({
  currentMemo,
  addMemo,
  memo,
  setMemo,
  showsMemoModal,
  setShowsMemoModal,
}: {
  currentMemo: string;
  addMemo: (val: { memo: string }) => void;
  memo: string;
  setMemo: (val: string) => void;
  showsMemoModal: boolean;
  setShowsMemoModal: (val: boolean) => void;
}) {
  const exceedsLength = memo.length > 28;

  return (
    <Modal
      isOpened={showsMemoModal}
      handleClose={() => {
        setShowsMemoModal(false);
      }}
      modalBody={
        <Box
          sx={{
            display: "flex",
            width: "280px",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="button"
            fontSize="medium"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >
            한 줄 메모
          </Typography>

          <Box sx={{ marginBottom: 5 }}>
            <TextField
              autoFocus
              defaultValue={currentMemo}
              error={exceedsLength}
              onChange={(e) => setMemo(e.currentTarget.value)}
              fullWidth
            />

            <Typography
              fontSize="small"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                color: exceedsLength ? "#f44336" : "#2196f3",
              }}
            >
              {memo.length}/28
            </Typography>
          </Box>

          <Button
            variant="contained"
            sx={{
              width: "fit-content",
            }}
            disabled={exceedsLength || !memo.length}
            onClick={() => addMemo({ memo })}
          >
            저장
          </Button>
        </Box>
      }
    />
  );
}
