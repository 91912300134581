import styled from "styled-components";

const basicDescContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

export default {
  basicDescContainer,
};
