import { useFormContext } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { AdminShipmentInfoForm } from "@sellernote/_shared/src/types/forwarding/adminBid";
import CheckboxWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/CheckboxWithReactHookForm";

function BusinessAgency({
  isImport,
  freightType,
}: {
  isImport: boolean;
  freightType: FreightType;
}) {
  const { control, watch } = useFormContext<AdminShipmentInfoForm>();

  const incoterms = watch("incoterms");

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle1" fontWeight={700}>
        업무대행
      </Typography>

      {isImport && (
        <CheckboxWithReactHookForm
          name={"importCustoms"}
          control={control}
          label="통관"
        />
      )}

      {!isImport && (
        <CheckboxWithReactHookForm
          name={"exportCustoms"}
          control={control}
          label="수출통관"
        />
      )}

      <CheckboxWithReactHookForm
        name={"hopeCargoInsurance"}
        control={control}
        label="적하보험"
      />

      {isImport && (
        <CheckboxWithReactHookForm
          name={"needFTACertificateAgency"}
          control={control}
          label="FTA C/O"
        />
      )}

      {!isImport && (
        <CheckboxWithReactHookForm
          name={"useVGM"}
          control={control}
          label="VGM"
          disabled={freightType !== "FCL"}
        />
      )}

      {!isImport && (
        <CheckboxWithReactHookForm
          name={"importCustoms"}
          control={control}
          label="수입통관"
          disabled={incoterms !== "DDP" && incoterms !== "DAP"}
        />
      )}
    </Box>
  );
}

export default BusinessAgency;
