import { useFormContext } from "react-hook-form";

import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import TextFieldWithReactHookForm from "../../../../../../../../../../../components/TextFieldWithReactHookForm";

import filledLogo from "../../../../../../../../../../../images/createDocument/filledLogo.png";
import Styled from "./index.styles";

function ShipperInfo() {
  const { control } = useFormContext<HBLInfo>();

  return (
    <Styled.shipperInfoContainer>
      <Styled.shipperInfoColumn>
        <Styled.shipperInfoItem>
          <div className="border-top"></div>
          <div className="title">Shipper</div>

          <TextFieldWithReactHookForm
            name="shipperName"
            control={control}
            placeholder="shipperName"
            required={true}
            fullWidth
          />
          <TextFieldWithReactHookForm
            name="shipperAddress"
            control={control}
            fullWidth
            multiline={true}
            placeholder="shipperAddress"
            required={true}
          />
        </Styled.shipperInfoItem>

        <Styled.shipperInfoItem>
          <div className="border-top"></div>

          <div className="title">Consignee or order</div>

          <TextFieldWithReactHookForm
            name="consigneeName"
            control={control}
            placeholder="consigneeName"
            required={true}
            fullWidth
          />
          <TextFieldWithReactHookForm
            name="consigneeAddress"
            control={control}
            fullWidth
            multiline={true}
            required={true}
            placeholder="consigneeAddress"
          />
        </Styled.shipperInfoItem>

        <Styled.shipperInfoItem>
          <div className="border-top"></div>

          <div className="title">Notify Party</div>

          <TextFieldWithReactHookForm
            name="notifyParty"
            control={control}
            placeholder="notifyParty"
            required={true}
            fullWidth
          />
          <TextFieldWithReactHookForm
            name="notifyPartyAddress"
            control={control}
            fullWidth
            multiline={true}
            placeholder="notifyPartyAddress"
          />
        </Styled.shipperInfoItem>
      </Styled.shipperInfoColumn>

      <Styled.logoContainer>
        <Styled.companyInfo>
          <Styled.logo src={filledLogo} alt="Shipda Logo" />
          <Styled.companyDetails>
            <Styled.companyName>SELLER-NOTE Co.,Ltd.</Styled.companyName>

            <Styled.addressContainer>
              <Styled.address>
                9F-901, 89, SAEMUNAN-RO, JONGNO-GU, SEOUL, REPUBLIC OF KOREA
              </Styled.address>
              <Styled.contactInfo>
                <Styled.label>TEL</Styled.label>
                <Styled.phoneNumber>+82-2-6956-7225</Styled.phoneNumber>
              </Styled.contactInfo>
            </Styled.addressContainer>
          </Styled.companyDetails>
        </Styled.companyInfo>

        <Styled.carrierInfo>
          Received by the Carrier, the Goods specified herein in apparent good
          order and condition unless otherwise stated, to be transported to such
          place as agreed, authorized or permitted herein and subject to all the
          terms and conditions appearing on the front and reverse of this
          Multimodal Transport Bill of Lading (hereinafter called the 'B/L') to
          which the Merchant agrees by accepting this B/L, notwithstanding any
          local privileges, custom or any other agreements between the parties.
          <br />
          The particulars of the Goods provided herein were stated by the
          shipper and the weight, measurements, quantity, condition, contents
          and value of the Goods are unknown to the Carrier.
          <br />
          In witness whereof three (3) original B/L(s) have been signed unless
          otherwise stated herein. If two (2) or more original B/L(s) have been
          issued and either one (1) has been surrendered, all the other(s) shall
          be null and void. If required by the Carrier one (1) duly endorsed
          original B/L must be surrendered in exchanged for the Goods or
          delivered order.
        </Styled.carrierInfo>
      </Styled.logoContainer>
    </Styled.shipperInfoContainer>
  );
}

export default ShipperInfo;
