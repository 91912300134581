import styled from "styled-components";

const checkPaymentModalWrapper = styled.div`
  .header {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    .topButtonWrapper {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 16px;
    }

    .bottomButtonWrapper {
      font-size: 16px;
      line-height: 26px;
      display: flex;
      justify-content: center;
    }
  }

  .button {
    text-align: center;
    color: #757575;
    cursor: pointer;
  }

  .ok-button {
    margin-left: 32px;
    text-align: center;
    color: #5482d6;
    cursor: pointer;
  }
`;

export default {
  checkPaymentModalWrapper,
};
