import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Grid, Skeleton, TextField, Typography } from "@mui/material";

import { CHANGE_ADMIN_BID_CONSOLIDATION_INFO_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import ZoneAddressTextFiled from "@sellernote/_shared-for-forwarding-admin/src/containers/ZoneAddressTextField";

import useSnackbar from "../../../../hooks/useSnackbar";

function ConsolidationEndAddress({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const { handleSnackbarOpen } = useSnackbar();

  const hasDestinationButNoEndAddressDetail = (() => {
    const { zoneId, endAddress, endAddressDetail, serviceType } = bidDetail;

    if (
      serviceType === "consolidation" &&
      zoneId &&
      endAddress &&
      endAddressDetail === null
    )
      return true;

    return false;
  })();

  const queryClient = useQueryClient();

  const [updateData, setUpdateData] =
    useState<CHANGE_ADMIN_BID_CONSOLIDATION_INFO_REQ>({
      zoneId: bidDetail.zoneId || 0,
    });

  // 특송에서 endAddressDetail 없이 도착지 주소가 셋팅되는 경우가 있음 이때는 endAddress가 detail 역할을 함
  const [endAddressDetail, setEndAddressDetail] = useState(
    hasDestinationButNoEndAddressDetail
      ? bidDetail.endAddress || ""
      : bidDetail.endAddressDetail || ""
  );
  const [canEdit, setCanEdit] = useState(false);

  const { mutate: changeConsolidationInfo, isLoading } =
    ADMIN_BID_QUERY.useChangeAdminBidConsolidationInfo(bidDetail.id);

  // 특송에서 endAddressDetail 없이 도착지 주소가 셋팅되는 경우가 있음 이때는 zone.name이 endAddress 역할을 함
  const endAddress = useMemo(() => {
    if (hasDestinationButNoEndAddressDetail) {
      return bidDetail.zone?.name || "";
    }
    return bidDetail.endAddress || "";
  }, [
    bidDetail.endAddress,
    bidDetail.zone?.name,
    hasDestinationButNoEndAddressDetail,
  ]);

  const handleEndAddressChange = () => {
    if (!updateData.zoneId) {
      return handleSnackbarOpen("주소를 선택해주세요.", "warning");
    }

    changeConsolidationInfo(
      {
        ...updateData,
        endAddressDetail,
        isProductInfoChanged: true,
        endType: "inland",
        endViaPortId: bidDetail.arrivalPortId,
      },
      {
        onSuccess: () => {
          setCanEdit(false);
          handleSnackbarOpen("도착지 정보가 수정되었습니다.");

          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: ({ response }) => {
          if (response?.data?.errorCode === "E50") {
            handleSnackbarOpen("현재상테에서는 변경할 수 없습니다.", "error");
            return;
          }
          handleSnackbarOpen("도착지 정보 수정에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleEditModeClose = () => {
    setCanEdit(false);
    setEndAddressDetail(
      hasDestinationButNoEndAddressDetail
        ? bidDetail.endAddress || ""
        : bidDetail.endAddressDetail || ""
    );
    setUpdateData({});
  };

  return (
    <Grid container sx={{ marginTop: "32px" }}>
      <Grid item container alignItems="center" xs={12}>
        <Grid item xs={10}>
          <Typography variant="h6" component="div">
            도착지 정보
          </Typography>
        </Grid>

        {!canEdit && APP_NAME === "shipda-admin" && (
          <Grid item>
            <Button onClick={() => setCanEdit(true)}>수정</Button>
          </Grid>
        )}

        {canEdit && (
          <Grid item container xs={2}>
            <Grid item>
              <Button disabled={isLoading} onClick={handleEndAddressChange}>
                확인
              </Button>
            </Grid>

            <Grid item>
              <Button
                disabled={isLoading}
                color="error"
                onClick={handleEditModeClose}
              >
                취소
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item container xs={12}>
        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              도착지 :
            </Typography>
          </Grid>

          <Grid item xs={10}>
            {canEdit ? (
              isLoading ? (
                <Skeleton variant="text" height={40} />
              ) : (
                <ZoneAddressTextFiled
                  zoneName={endAddress}
                  pageType="bid"
                  setBidUpdateState={setUpdateData}
                />
              )
            ) : (
              <Typography variant="body1" component="div">
                {endAddress}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              상세주소 :
            </Typography>
          </Grid>

          <Grid item xs={10}>
            {canEdit ? (
              isLoading ? (
                <Skeleton variant="text" height={40} />
              ) : (
                <TextField
                  type="text"
                  size="small"
                  label="상세 주소"
                  fullWidth
                  value={endAddressDetail}
                  onChange={(e) => setEndAddressDetail(e.target.value)}
                  autoComplete="off"
                />
              )
            ) : (
              <Typography variant="body1" component="div">
                {endAddressDetail}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default ConsolidationEndAddress;
