const checkUpdatedField = <T, U>({
  form,
  originalData,
  keysToCheck,
}: {
  form: T;
  originalData: T;
  keysToCheck: (keyof T)[];
}): Partial<Record<keyof T, U | null>> => {
  return keysToCheck.reduce((acc: Partial<Record<keyof T, U | null>>, key) => {
    const formValue = form[key] as U;

    if (form[key] !== originalData[key]) {
      acc[key] = formValue;
    }

    return acc;
  }, {});
};

export { checkUpdatedField };
