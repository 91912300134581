import { keyframes } from "@mui/material";

/**
 * 저장/안내메일 발송 버튼 애니메이션
 */
const glowing = keyframes`
  0% {
    box-shadow: 0 0 0 0 #1478dc;
  }
  
  50% {
    box-shadow: 0 0 15px 0 #1478dc;
  }
`;

export { glowing };
