import { Checkbox, FormControlLabel, Typography } from "@mui/material";

export default function AutoRenewalRadio({
  stopsAutoRenewal,
  setStopsAutoRenewal,
}: {
  stopsAutoRenewal: boolean;
  setStopsAutoRenewal: (val: boolean) => void;
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={stopsAutoRenewal}
          onChange={(e) => setStopsAutoRenewal(e.target.checked)}
        />
      }
      label={
        <Typography variant="caption" color={"error"}>
          자동갱신을 중단하시겠습니까?
        </Typography>
      }
      labelPlacement="end"
      sx={{ mt: 1 }}
    />
  );
}
