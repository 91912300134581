import { useState } from "react";

export default function usePagination() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = (newPage: number) => setPage(newPage);

  /** rowsPerPage 변경시 페이지 초기화 */
  const handlePaginationOptionReset = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return {
    page,
    rowsPerPage,
    handlePageChange,
    handlePaginationOptionReset,
  };
}
