import { Dispatch, SetStateAction, useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button, Grid, Typography } from "@mui/material";

import { QueryResponseHandlerCustomMessage } from "@sellernote/_shared/src/components/QueryResponseHandler";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

const BidDeleteModal = ({
  showsBidDeleteModal,
  setShowsBidDeleteModal,
  pagination,
  bidId,
}: {
  showsBidDeleteModal: boolean;
  setShowsBidDeleteModal: Dispatch<SetStateAction<boolean>>;
  pagination: {
    rowsPerPageOptions: number[];
    totalCount: number;
    perPage: number;
    setPerPage: (value: number) => void;
    currentPage: number;
    setCurrentPage: (value: number) => void;
  };
  bidId: number;
}) => {
  //
  const queryClient = useQueryClient();

  const {
    mutate: deleteBidList,
    ResponseHandler: ResponseHandlerOfDeleteBidList,
  } = ADMIN_BID_QUERY.useDeleteBidList({
    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const getErrorMessage = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E081: {
              messageType: "titleOnly" as const,
              title: "계산서 발행 된 매출관리 항목은 삭제가 안됩니다.",
            },
          }[errorCode ?? ""] ?? {
            title: "삭제 에러가 있습니다 개발자에게 문의해주세요.",
          });

        return getErrorMessage({ errorCode: failureInfo?.errorCode });
      },
    },
  });

  const handleBidListDelete = useCallback(
    (bidId: number) => {
      return () => {
        deleteBidList(
          {
            pathParams: {
              bidId,
            },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminBidList({
                  perPage: pagination.perPage,
                  page: pagination.currentPage,
                  order: "DESC",
                })
              );
              setShowsBidDeleteModal(false);
            },
          }
        );
      };
    },
    [
      deleteBidList,
      queryClient,
      pagination.currentPage,
      pagination.perPage,
      setShowsBidDeleteModal,
    ]
  );

  const handleModalClose = () => {
    setShowsBidDeleteModal(false);
  };

  return (
    <>
      {ResponseHandlerOfDeleteBidList}

      <Modal
        isOpened={showsBidDeleteModal}
        handleClose={() => handleModalClose()}
        modalBody={
          <Grid
            container
            spacing={4}
            direction="column"
            justifyContent="center"
            justifyItems="center"
            sx={{ minWidth: "300px" }}
          >
            <Grid item>
              <Typography variant="h6">리스트를 삭제하시겠습니까?</Typography>
            </Grid>

            <Grid container item justifyContent={"flex-end"}>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleBidListDelete(bidId)}
                >
                  예
                </Button>
              </Grid>

              <Grid item xs={4}>
                <Button variant="outlined" onClick={handleModalClose}>
                  아니오
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default BidDeleteModal;
