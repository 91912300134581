import { Box } from "@mui/material";

import {
  FreightType,
  Liner,
} from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { TrelloBidManagement } from "@sellernote/_shared/src/types/forwarding/trello";

import HblGenerator from "./HblGenerator";
import UpdateBLNumberAndLiner from "./UpdateBLNumberAndLiner";

function BL({
  management,
  projectStatus,
  freightType,
  linerData,
  BLType,
  isExport,
}: {
  management: TrelloBidManagement;
  projectStatus: BidProjectStatus;
  freightType: FreightType;
  linerData: Liner[] | undefined;
  BLType: string;
  isExport: boolean;
}) {
  return (
    <Box>
      {linerData && (
        <UpdateBLNumberAndLiner
          management={management}
          projectStatus={projectStatus}
          freightType={freightType}
          linerData={linerData}
          BLType={BLType}
          isExportation={isExport}
        />
      )}

      {/* 수출일때만 HBL 생성을 노출 */}
      {isExport && <HblGenerator management={management} />}
    </Box>
  );
}

export default BL;
