import { Dispatch, SetStateAction } from "react";
import { useQueryClient } from "react-query";
import { AlertColor } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { ContainerInfoForAN } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../../../../../../hooks/useSnackbar";
import validateForm from "../../utils";
import { checkUpdatedField } from "../utils";
import { COMMON_KEYS, EXPORT_KEYS, IMPORT_KEYS } from "../utils/constants";

export default function useSaveCargoManifest({
  form,
  cargoManifestInfo,
  shipmentId,
  isImport,
  isEditMode,
  setIsEditMode,
}: {
  form: CargoManifestInfoProps;
  cargoManifestInfo: CargoManifestInfoProps;
  shipmentId: number;
  isImport: boolean;
  isEditMode: boolean;
  /** 함수형 업데이트를 위해 SetStateAction 타입 사용 */
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const onSuccess = {
    onSuccess: () => {
      queryClient.invalidateQueries(
        TRELLO_BID_QUERY_KEY_GEN.getCargoManifest({ shipmentId })
      );
      handleSnackbarOpen("적하목록 정보를 성공적으로 변경했습니다.");
    },
  };

  const { mutate: updateCargoManifest } =
    TRELLO_BID_QUERY.useUpdateCargoManifest<AlertColor>({
      shipmentId,
      onSnackbarOpen: handleSnackbarOpen,
      setIsEditMode,
    });

  const handleManifestInfoUpdate = () => {
    if (!validateForm(form, handleSnackbarOpen, isImport)) {
      return;
    }

    if (isEditMode) {
      const updatedCommonFields = checkUpdatedField<
        CargoManifestInfoProps,
        string | number | ContainerInfoForAN[]
      >({
        form,
        originalData: cargoManifestInfo,
        keysToCheck: COMMON_KEYS,
      });

      const updatedFields = isImport
        ? checkUpdatedField<CargoManifestInfoProps["importation"], string>({
            form: form.importation,
            originalData: cargoManifestInfo.importation,
            keysToCheck: IMPORT_KEYS,
          })
        : checkUpdatedField<CargoManifestInfoProps["exportation"], string>({
            form: form.exportation,
            originalData: cargoManifestInfo.exportation,
            keysToCheck: EXPORT_KEYS,
          });

      const hasContainer = Boolean(form.containers?.length);

      /** 수출에서는 containerPackageCount를 보내지 않음 */
      const containerPayload = (() => {
        if (isImport) return form.containers;

        return form.containers?.map((container) => ({
          containerNumber: container.containerNumber,
          sealNo: container.sealNo,
          containerType: container.containerType,
        }));
      })();

      const payload = {
        ...updatedCommonFields,
        ...updatedFields,
        ...(hasContainer ? { containers: containerPayload } : {}),
        ...(hasContainer ? { packageCount: form.packageCount } : {}),
      };

      updateCargoManifest(payload, onSuccess);
    }

    setIsEditMode((prev) => !prev);
  };

  return {
    handleManifestInfoUpdate,
  };
}
