import { useState } from "react";
import { Button } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { BidProjectStatus } from "@sellernote/_shared/src/types/forwarding/bid";

import Modal from "../../../../../../../components/Modal";

import GraphicPreviewModalBody from "./GraphicPreviewModalBody";

export default function GraphicPreview({
  projectStatus,
  freightType,
  shipmentId,
}: {
  projectStatus: BidProjectStatus;
  freightType: FreightType;
  shipmentId: number;
}) {
  const [showsGraphicPreviewModal, setShowsGraphicPreviewModal] =
    useState(false);

  const handleModalOpen = () => setShowsGraphicPreviewModal(true);
  const handleModalClose = () => setShowsGraphicPreviewModal(false);

  return (
    <>
      <Button variant="contained" onClick={handleModalOpen} sx={{ mr: 1 }}>
        그래픽 미리보기
      </Button>

      <Modal
        isOpened={showsGraphicPreviewModal}
        handleClose={handleModalClose}
        modalBody={
          <GraphicPreviewModalBody
            projectStatus={projectStatus}
            freightType={freightType}
            shipmentId={shipmentId}
          />
        }
      />
    </>
  );
}
