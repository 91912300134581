import { Box, Button, Typography } from "@mui/material";

import Modal from "../../../../../../../../components/Modal";

export default function ExitModal({
  opensModal,
  onModalClose,
  onButtonClick,
}: {
  opensModal: boolean;
  onModalClose: () => void;
  onButtonClick: () => void;
}) {
  return (
    <Modal
      isOpened={opensModal}
      handleClose={onModalClose}
      modalBody={
        <Box display={"flex"} alignContent={"center"} flexDirection={"column"}>
          <Typography component="span" fontWeight={"bold"}>
            적하목록 신고 모달을 닫으시겠습니까?
          </Typography>

          <Button
            variant="contained"
            onClick={onButtonClick}
            fullWidth
            sx={{ mt: 3 }}
          >
            닫기
          </Button>
        </Box>
      }
    />
  );
}
