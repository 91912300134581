import { useEffect } from "react";
import { Box } from "@mui/material";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { ExportTrelloList } from "@sellernote/_shared/src/types/forwarding/trello";

import useHandleTrelloCardOpCheckPoint from "../../../../hooks/useHandleTrelloCardOpCheckPoint";

import TrelloHeaderFilter from "../../_components/TrelloHeaderfilter";
import TrelloCardList from "../../_components/TrelloList/TrelloCardList";
import useCommonTrelloListLogic from "../../_hooks/useCommonTrelloListLogic";
import useExportCardListData from "../../_hooks/useExportCardListData";
import useRenewTrelloListQueryData from "../../_hooks/useRenewTrelloListQueryData";
import ExportTrelloDetail from "../../TrelloDetail/ExportTrelloDetail";
import RefreshButton from "../RefreshButton";

const ShipdaExportationTrello = ({
  trelloListData,
  adminData,
  isLoading,
  refetchData,
}: {
  trelloListData?: ExportTrelloList[];
  adminData?: ForwardingAdminUserListItem[];
  isLoading: boolean;
  refetchData: () => void;
}) => {
  const {
    sessionStorageTrelloIssueFilter,
    showsTrelloDetailModal,
    bidId,
    handleTrelloCardClick,
    serviceType,
    salesNameList,
    handleSessionStorageTrelloIssueFilterChange,
    setShowsTrelloDetailModal,
    fetchTrelloListParams,
    setSessionStorageTrelloIssueFilter,
  } = useCommonTrelloListLogic({ adminData });

  // 수입에서 수출 페이지로 변경하는 경우 수출 이슈 필터에서 없는 상태라면 "none"으로 변경한다.
  useEffect(() => {
    if (
      sessionStorageTrelloIssueFilter !== "scheduleChanged" &&
      sessionStorageTrelloIssueFilter !== "statusChanged" &&
      sessionStorageTrelloIssueFilter !== "issuedInvoice" &&
      sessionStorageTrelloIssueFilter !== "unissuedInvoice"
    ) {
      setSessionStorageTrelloIssueFilter("none");
    }
  }, [sessionStorageTrelloIssueFilter, setSessionStorageTrelloIssueFilter]);

  const { renewTrelloListQueryData } = useRenewTrelloListQueryData({
    trelloListData: trelloListData ?? [],
    fetchTrelloListParams,
    serviceType,
  });

  const { handleTrelloCardOpCheckPoint } = useHandleTrelloCardOpCheckPoint({
    renewTrelloListQueryData,
  });

  const { exportCardListData } = useExportCardListData({
    trelloListData: trelloListData ?? [],
    handleTrelloCardClick,
    handleTrelloCardOpCheckPoint,
    sessionStorageTrelloIssueFilter,
  });

  return (
    <>
      <Box display="flex" paddingLeft="16px">
        <RefreshButton refetchData={refetchData} />

        <TrelloHeaderFilter
          salesNameList={salesNameList ?? []}
          sessionStorageTrelloIssueFilter={sessionStorageTrelloIssueFilter}
          onSessionStorageTrelloIssueFilterChange={
            handleSessionStorageTrelloIssueFilterChange
          }
        />
      </Box>

      {isLoading ? (
        <Box
          display="flex"
          overflow="auto"
          padding={1}
          position="relative"
          height="calc(100vh - 130px)"
        >
          <Loading active={true} uiType="parentBy" />
        </Box>
      ) : (
        <TrelloCardList cardListData={exportCardListData} />
      )}

      {showsTrelloDetailModal && (
        <ExportTrelloDetail
          showsExportTrelloDetailModal={showsTrelloDetailModal}
          setShowsExportTrelloDetailModal={setShowsTrelloDetailModal}
          bidId={bidId}
          trelloListData={trelloListData}
          fetchTrelloListParams={fetchTrelloListParams}
        />
      )}
    </>
  );
};

export default ShipdaExportationTrello;
