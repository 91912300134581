import { Box, Typography } from "@mui/material";

import { FreightType } from "@sellernote/_shared/src/types/common/common";

import AIRCheckpoint from "./AIRCheckpoint";
import FCLCheckpoint from "./FCLCheckpoint";
import LCLCheckpoint from "./LCLCheckpoint";

function Checkpoint({
  freightType,
  isImport,
}: {
  freightType: FreightType;
  isImport: boolean;
}) {
  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={700}>
        체크포인트
      </Typography>

      {freightType === "FCL" && <FCLCheckpoint isImport={isImport} />}

      {/* 수출 LCL에서는 체크포인트가 존재하지 않음 */}
      {freightType === "LCL" && isImport && <LCLCheckpoint />}

      {/* 수출 AIR에서는 체크포인트가 존재하지 않음 */}
      {freightType === "AIR" && isImport && <AIRCheckpoint />}
    </Box>
  );
}

export default Checkpoint;
