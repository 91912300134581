import { Box, Button, Typography } from "@mui/material";

import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import { QuotationChangeTypeAfterCargoUpdate } from "../../types";

function QuotationResetModal({
  showsQuotationResetModal,
  onQuotationResetModalClose,
  onShipmentItemCargoUpdate,
}: {
  showsQuotationResetModal: boolean;
  onQuotationResetModalClose: () => void;
  onShipmentItemCargoUpdate: (
    type: QuotationChangeTypeAfterCargoUpdate
  ) => void;
}) {
  return (
    <Modal
      isOpened={showsQuotationResetModal}
      handleClose={onQuotationResetModalClose}
      modalBody={
        <>
          <Box mb={2}>
            <Typography variant="h6">
              운송정보 수정 시 견적을 수정하셔야합니다.
            </Typography>

            <Typography variant="body2">
              **'24.06.04'부로, 운송정보를 수정하셔도 견적이 초기화되지
              않습니다.
            </Typography>
          </Box>

          <Box display="flex" gap={1} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              color="success"
              onClick={() => onShipmentItemCargoUpdate("quotationReset")}
            >
              견적 재작성하기
            </Button>

            <Button
              variant="contained"
              onClick={() => onShipmentItemCargoUpdate("quotationUpdate")}
            >
              견적 수정하기
            </Button>
          </Box>
        </>
      }
    />
  );
}

export default QuotationResetModal;
