import { useContext } from "react";

import SaveButtonGroup from "../components/SaveButtonGroup";

import { FormDisabledContext } from "..";
import useHandleCargoReadyUpdate from "./useHandleCargoReadyUpdate";

export default function SaveAndEmailButton({
  cargoReady,
  shipmentId,
  isCargoReadyChanged,
  hasSavedCargoReady,
}: {
  cargoReady: string | null;
  shipmentId: number;
  isCargoReadyChanged: boolean;
  hasSavedCargoReady: boolean;
}) {
  const isDisabled = useContext(FormDisabledContext);

  const { handleCargoReadyUpdate, ResponseHandlerOfCargoReady } =
    useHandleCargoReadyUpdate({
      shipmentId,
      isCargoReadyChanged,
      hasSavedCargoReady,
    });

  return (
    <>
      <SaveButtonGroup
        saveButton={{
          onClick: () =>
            handleCargoReadyUpdate({ sendsEmail: false, cargoReady }),
          isDisabled,
        }}
        saveAndEmailButton={{
          onClick: () =>
            handleCargoReadyUpdate({ sendsEmail: true, cargoReady }),
          isDisabled,
        }}
      />

      {ResponseHandlerOfCargoReady}
    </>
  );
}
