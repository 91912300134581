import styled from "styled-components";

const container = styled.div`
  margin-top: 54px;
  width: 552px;
  height: 900px;
  padding-bottom: 12px;
  position: relative;
`;

const title = styled.div`
  color: #4d70c3;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.096px;
  font-weight: 700;
`;

const topDivider = styled.div`
  border-top: 1px solid #5482d6;
  width: 552px;
  height: 0px;
  margin-top: 6px;
`;

const routeContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ship = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 176px;

  > .title {
    color: #9e9e9e;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    text-transform: uppercase;
    width: 55px;
  }

  > .content {
    color: #424242;
    text-align: left;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.054px;
    align-self: stretch;
  }
`;

const blNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  width: 176px;

  > .title {
    color: #9e9e9e;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    text-transform: uppercase;
    width: 55px;
  }

  > .desc {
    color: #424242;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.048px;
    align-self: stretch;
  }
`;

const route = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;

  > .title {
    color: #9e9e9e;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    text-transform: uppercase;
    width: 55px;
  }

  > .content {
    color: #424242;
    text-align: left;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.054px;
    align-self: stretch;
  }
`;

const goodsAndContainerSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  width: 552px;
  margin-top: 16px;
  height: 765px;
`;

const goodsAndContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
`;

const goodsAndContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  flex-shrink: 0;
`;

const containerNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex-shrink: 0;
  width: 84px;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 84px;
  }

  > .title {
    color: #4d70c3;
    text-align: left;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  > .subtitle {
    color: #4d70c3;
    text-align: left;
    font-size: 6px;
    line-height: 8px;
    letter-spacing: -0.3px;
    font-weight: 700;
    text-transform: uppercase;
    width: 79px;
    display: flex;
    align-items: center;
  }

  > .content {
    color: #424242;
    text-align: left;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.054px;
    align-self: stretch;
  }
`;

const unit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex-shrink: 0;
  width: 180px;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 180px;
  }

  > .title {
    color: #4d70c3;
    text-align: left;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.24px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const descriptionOfGoods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex: 1;
  position: relative;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 272px;
  }

  > .title {
    color: #4d70c3;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    text-transform: uppercase;
    align-self: stretch;
  }

  > .content {
    color: #424242;
    text-align: left;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.054px;
    align-self: stretch;
  }
`;

const bottomDivider = styled.div`
  border-top: 1px solid #5482d6;
  width: 552px;
  height: 0px;
`;

export default {
  title,
  container,
  topDivider,
  routeContainer,
  ship,
  route,
  goodsAndContainerSection,
  goodsAndContainerColumn,
  goodsAndContainerRow,
  containerNumber,
  descriptionOfGoods,
  bottomDivider,
  unit,
  blNumber,
};
