import styled from "styled-components";

const vesselSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
`;

const vesselColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex: 1;
`;

const vesselItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 272px;
  }

  > .title {
    color: #4d70c3;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    text-transform: uppercase;
    align-self: stretch;
  }

  > .content {
    color: #424242;
    text-align: left;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.054px;
    align-self: stretch;
  }
`;

const carrier = styled.div`
  text-align: center;
  color: #4d70c3;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.072px;
  font-weight: 700;
  text-transform: uppercase;
  align-self: stretch;
`;

const carrierSignature = styled.div`
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;

  > .company-name {
    color: #424242;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.288px;
    font-weight: 700;
  }
`;

const termsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 552px;
  height: 12px;
`;

const termsBorder = styled.div`
  border-top: 1px solid #5482d6;
  width: 552px;
`;

const termsText = styled.div`
  color: #4d70c3;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: -0.015em;
  font-weight: 700;
  text-transform: uppercase;
  align-self: stretch;
  text-align: center;
`;

export default {
  vesselSection,
  vesselColumn,
  vesselItem,
  carrier,
  carrierSignature,
  termsSection,
  termsBorder,
  termsText,
};
