import { Grid, Typography } from "@mui/material";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { getCustomsPartnerName } from "@sellernote/_shared/src/utils/forwarding/adminBid";

const ConsolidationCheckPointDesc = ({
  bidDetail,
}: {
  bidDetail: AdminBidDetail;
}) => {
  return (
    <Grid container sx={{ marginTop: "32px" }}>
      <Grid item container alignItems="center" xs={12}>
        <Grid item>
          <Typography variant="h6" component="div">
            통관 & FTA C/O & 유저코멘트
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              FTA C/O :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {changeBooleanValueToKr(bidDetail.needFTACertificateAgency)}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              통관 업무 의뢰 :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {bidDetail.containCustoms
                ? "포함"
                : `미포함 (${getCustomsPartnerName(
                    bidDetail.accountPayables
                  )})`}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" xs={4} spacing={1}>
          <Grid item>
            <Typography variant="body2" component="div">
              유저 코멘트 :
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="div">
              {bidDetail.userNote}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsolidationCheckPointDesc;
