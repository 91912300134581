import styled from "styled-components";

const container = styled.div`
  > .button-container {
    display: flex;
    justify-content: left;
    margin-top: 16px;

    > .button {
      width: 154px;
      height: 32px;
      margin-left: 16px;
      background-color: #74c041;
      border-radius: 2;
      color: #ffffff;

      &:disabled {
        background-color: #e0e0e0;
        border: 1px solid #e0e0e0;

        &:hover {
          background-color: #e0e0e0;
          border: 1px solid #e0e0e0;
          color: #ffffff;
        }
      }

      &:hover {
        background: #ffffff;
        border: 1px solid #74c041;
        color: #74c041;
      }
    }
  }
`;

const profitTable = styled.table`
  width: 560px;

  th {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #fafafa;
  }

  td {
    text-align: center;
    line-height: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px solid #1f1f1f;
    border-bottom: 1px solid #1f1f1f;
  }

  .sales {
    td {
      background-color: #e6f7ff;
      border: none;
      font-weight: bold;
    }
  }
`;

export default {
  container,
  profitTable,
};
