import { useState } from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ExporterInfo } from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { checkCanEditBidItem } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import {
  getConsolidationItemListForImport,
  getPackageLabel,
} from "@sellernote/_shared/src/utils/forwarding/bid";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../components/SADSTable";
import ItemUpdateModal from "../components/ItemUpdateModal";

import ChangeExporterInfoModal from "./ChangeExporterInfoModal";

type TableHeadCellKey =
  | "품명"
  | "출발지 주소"
  | "W"
  | "L"
  | "H"
  | "UNIT"
  | "WEIGHT"
  | "NUMBER OF BOXES"
  | "CBM"
  | "PACKING";

const headCellList: TableHeadCell<TableHeadCellKey>[] = [
  { cellKey: "품명", label: "품명" },
  { cellKey: "W", label: "W", align: "right" },
  { cellKey: "L", label: "L", align: "right" },
  { cellKey: "H", label: "H", align: "right" },
  { cellKey: "UNIT", label: "UNIT", align: "right" },
  { cellKey: "WEIGHT", label: "WEIGHT", align: "right" },
  { cellKey: "NUMBER OF BOXES", label: "NUMBER OF BOXES", align: "right" },
  { cellKey: "CBM", label: "CBM", align: "right" },
  { cellKey: "PACKING", label: "PACKING", align: "right" },
  { cellKey: "출발지 주소", label: "출발지 주소" },
];

function ConsolidationCargoInfo({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  // TODO: import/export 분리하면서 정리
  const consolidationItemList = getConsolidationItemListForImport(
    bidDetail.itemGroupList || []
  );

  const [showsItemUpdateModal, setShowsItemUpdateModal] = useState(false);
  const [consolidationExporterGroupId, setConsolidationExporterGroupId] =
    useState<number | undefined>(undefined);
  const [showsChangeExporterInfoModal, setShowsChangeExporterInfoModal] =
    useState(false);
  const [exporterInfo, setExporterInfo] = useState<ExporterInfo>({
    companyName: "",
    personName: "",
    personPhone: "",
    personEmail: "",
    exporterGroup: 0,
  });

  const handleChangeExporterInfoModalOpen = (
    exporterInfo: ExporterInfo,
    exporterGroup: number
  ) => {
    return () => {
      setExporterInfo({
        companyName: exporterInfo.companyName,
        personEmail: exporterInfo.personEmail,
        personName: exporterInfo.personName,
        personPhone: exporterInfo.personPhone,
        exporterGroup: exporterGroup,
      });

      setShowsChangeExporterInfoModal(true);
    };
  };

  const handleChangeExporterInfoModalClose = () => {
    setExporterInfo({
      companyName: "",
      personEmail: "",
      personName: "",
      personPhone: "",
      exporterGroup: 0,
    });

    setShowsChangeExporterInfoModal(false);
  };

  const handleItemUpdateModalOpen = (consolidationExporterGroupId: number) => {
    setConsolidationExporterGroupId(consolidationExporterGroupId);
    setShowsItemUpdateModal(true);
  };

  const handleItemUpdateModalClose = () => {
    setConsolidationExporterGroupId(undefined);
    setShowsItemUpdateModal(false);
  };

  return (
    <Grid container direction="column" spacing={2}>
      {consolidationItemList.map((consolidationItem, index) => {
        const bodyRowList: TableBodyRow<TableHeadCellKey>[] =
          consolidationItem.itemList.map((item) => {
            return {
              rowKey: item.id,
              품명: { value: item.name },
              W: { value: item.horizontal },
              L: { value: item.vertical },
              H: { value: item.height },
              UNIT: { value: item.volumeUnit },
              WEIGHT: {
                // TODO: 특송 시 무게 중량에 대한 기준을 정하면 무게 단위 추가
                value: item.weight,
              },
              "NUMBER OF BOXES": { value: toThousandUnitFormat(item.quantity) },
              CBM: { value: item.cbm },
              PACKING: { value: getPackageLabel(item.packingType) },
              "출발지 주소": { value: item.address || "-" },
            };
          });

        return (
          <Grid item key={consolidationItem.exporterGroup}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    sx={{ backgroundColor: `${grey[50]}`, padding: 1 }}
                    xs={12}
                    spacing={1}
                  >
                    {/* TODO: 콘솔 EXW일 때 수출자 정보에 출발지가 추가되어야 함 */}
                    <Grid item container alignItems="center" xs={1.2}>
                      <Grid item>
                        <Typography
                          sx={{ color: `${blue[300]}` }}
                          variant="body1"
                          component="div"
                        >
                          {`수출자 ${index + 1} 정보`}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container alignItems="center" xs={3}>
                      <Grid item>
                        <Typography variant="body2" component="div">
                          COMPANY NAME :
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {consolidationItem.exporterInfo.companyName}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container alignItems="center" xs={2}>
                      <Grid item>
                        <Typography variant="body2" component="div">
                          NAME :
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {consolidationItem.exporterInfo.personName}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container alignItems="center" xs={3}>
                      <Grid item>
                        <Typography variant="body2" component="div">
                          EMAIL :
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {consolidationItem.exporterInfo.personEmail}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container alignItems="center" xs={2}>
                      <Grid item>
                        <Typography variant="body2" component="div">
                          TEL :
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="body1" component="div">
                          {consolidationItem.exporterInfo.personPhone}
                        </Typography>
                      </Grid>
                    </Grid>

                    {APP_NAME === "shipda-admin" && (
                      <Grid item container alignItems="center" xs={0.8}>
                        <Grid item>
                          <Button
                            onClick={handleChangeExporterInfoModalOpen(
                              consolidationItem.exporterInfo,
                              consolidationItem.exporterGroup
                            )}
                          >
                            수정
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <SADSTable
                    headCellList={headCellList}
                    bodyRowList={bodyRowList}
                  />

                  {APP_NAME === "shipda-admin" && (
                    <Grid item xs={3}>
                      <Grid item>
                        <Button
                          disabled={checkCanEditBidItem(
                            bidDetail.projectStatus,
                            currentAdminAuthInfo?.authority
                          )}
                          onClick={() =>
                            handleItemUpdateModalOpen(
                              consolidationItem.exporterGroup
                            )
                          }
                        >
                          수정
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      })}

      {showsItemUpdateModal && consolidationExporterGroupId && (
        <ItemUpdateModal
          bidDetail={bidDetail}
          onItemUpdateModalClose={handleItemUpdateModalClose}
          consolidationExporterGroupId={consolidationExporterGroupId}
        />
      )}

      {showsChangeExporterInfoModal && (
        <ChangeExporterInfoModal
          handleChangeExporterInfoModalClose={
            handleChangeExporterInfoModalClose
          }
          showsChangeExporterInfoModal={showsChangeExporterInfoModal}
          exporterInfo={exporterInfo}
          bidId={bidDetail.id}
        />
      )}
    </Grid>
  );
}

export default ConsolidationCargoInfo;
