import { ChangeEvent } from "react";
import { Box, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { grey } from "@mui/material/colors";

const TrelloCardCheckBox = ({
  label,
  checkedValue,
  onChange,
}: {
  label: string;
  checkedValue?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", marginTop: "2px" }}>
      <Checkbox
        size="small"
        sx={{
          width: "16px",
          height: "16px",
        }}
        checked={checkedValue}
        onChange={onChange}
      />

      <Typography
        sx={{
          fontSize: "14px",
          color: grey[700],
          marginLeft: "8px",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default TrelloCardCheckBox;
