import { Box, Typography } from "@mui/material";

export default function CategoryAndDescription({
  category,
  subCategory,
  description,
}: {
  category: "routes" | "cargoReady";
  subCategory: "change" | "input";
  description: string | null;
}) {
  return (
    <Box>
      <Typography style={{ fontWeight: "bold" }} variant="h6">
        {category === "routes" ? "스케줄" : "카고레디"}{" "}
        {subCategory === "change" ? "변경" : "입력"}
      </Typography>

      {description && (
        <Typography style={{ fontWeight: "bold" }}>
          (사유: {description})
        </Typography>
      )}
    </Box>
  );
}
