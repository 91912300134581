import { Box, Typography } from "@mui/material";

import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import GraphicPreview from "./GraphicPreview";
import ShipmentHistory from "./ShipmentHistory";

export default function TitleAndShipmentInfo({
  trelloDetail,
}: {
  trelloDetail: TrelloBidDetail;
}) {
  const isShipNameExist =
    trelloDetail.freightType !== "AIR" && // 항공 운송의 경우 노출 X
    !trelloDetail.management.shipIMO && // shipIMO가 없을 때 노출
    trelloDetail.management.firstScheduleEmailSent; // 스케줄 입력시에는 노출 X

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5">운송 스케줄</Typography>

        {isShipNameExist && (
          <Typography variant="button" color="error">
            &nbsp; *존재하지 않는 모선명입니다. 변경해 주세요
          </Typography>
        )}
      </Box>

      <Box>
        <GraphicPreview
          projectStatus={trelloDetail.projectStatus}
          freightType={trelloDetail.freightType}
          shipmentId={trelloDetail.id}
        />

        <ShipmentHistory shipmentId={trelloDetail.id} />
      </Box>
    </Box>
  );
}
