/* eslint-disable no-useless-escape */
import { AlertColor } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

function hasMoreThanThreeDecimalPlaces(value: string | null) {
  if (!value) return false;

  return value.toString().split(".")[1]?.length > 3;
}

const validateClassification = (form: CargoManifestInfoProps) => {
  return (
    form.classification === "I" ||
    form.classification === "T" ||
    form.classification === "E" ||
    form.classification === "R"
  );
};

/** 각 컨테이너 포장 개수 총합이 전체 포장개수 총합과 맞는지 확인 */
const validatePackageCount = (form: CargoManifestInfoProps) => {
  if (!form.containers?.length) return true;

  const totalPackageCount = form.packageCount;

  const containerPackageCountSum = form.containers?.reduce((acc, cur) => {
    return acc + (cur.containerPackageCount ?? 0);
  }, 0);

  return totalPackageCount === containerPackageCountSum;
};

const validateBRN = (form: CargoManifestInfoProps, isNecessary: boolean) => {
  if (!form.consigneeBRN) return isNecessary;
  if (!form.notifyPartyBRN) return isNecessary;

  if (form.consigneeBRN.length !== 10) return true;
  if (form.notifyPartyBRN.length !== 10) return true;

  return false;
};

const validateContainerSizeType = (form: CargoManifestInfoProps) => {
  if (!form.containers?.length) return true;

  return form.containers?.every((container) => {
    return container.containerType?.length === 4;
  });
};

const validateContainerSealNo = (form: CargoManifestInfoProps) => {
  if (!form.containers?.length) return true;

  return form.containers?.every((container) => {
    return container.sealNo;
  });
};

function validateAddress(input: string | null, isNecessary: boolean) {
  if (!input) return isNecessary;

  const specialCharPattern = /[~'@#`\$|^{}_!\[\]\\]/g;
  return specialCharPattern.test(input);
}

const validateForm = (
  form: CargoManifestInfoProps,
  handleSnackbarOpen: (message: string, color?: AlertColor) => void,
  isImport: boolean
) => {
  const commonValidationList = [
    {
      condition: form.carrierCode?.length !== 4,
      message: "선사 코드를 확인해주세요. (4자리)",
    },
    {
      condition: form.MRN?.length !== 11,
      message: "MRN을 확인해주세요. (11자리)",
    },
    {
      condition: form.packageSign?.length !== 2,
      message: "포장부호 확인해주세요. (2자리)",
    },
    {
      condition: !validateContainerSizeType(form),
      message: "컨테이너의 Size/Type을 확인해주세요. (4자리)",
    },
    {
      condition: !validateClassification(form),
      message: "화물구분을 확인해주세요. (I, T, E, R)",
    },
    {
      condition: hasMoreThanThreeDecimalPlaces(form.totalWeight),
      message: "총 중량은 소수점 3자리까지 입력 가능합니다.",
    },
    {
      condition: hasMoreThanThreeDecimalPlaces(form.totalCBM),
      message: "총 용적은 소수점 3자리까지 입력 가능합니다.",
    },
    {
      condition: !form.mBL,
      message: "MBL을 입력해주세요.",
    },
    {
      condition: !form.hBL,
      message: "HBL를 입력해주세요.",
    },
    {
      condition: !form.itemName,
      message: "품명을 입력해주세요.",
    },
    {
      condition: !form.callSign,
      message: "선박호출부호를 입력해주세요.",
    },
    {
      condition: !validateContainerSealNo(form),
      message: "컨테이너 씰 번호를 입력해주세요.",
    },
    {
      condition: !form.shipper,
      message: "송하인 상호 및 성명을 입력해주세요.",
    },
    {
      condition: !form.consignee,
      message: "수하인 상호 및 성명을 입력해주세요.",
    },
    {
      condition: !form.notifyParty,
      message: "통지처 상호 및 성명을 입력해주세요.",
    },
    {
      condition: validateAddress(form.shipperAddress, true),
      message:
        "송하인 주소를 입력하거나, 특수문자 [~'@#$|^{}_!`]를 제거해주세요.",
    },
    {
      condition: validateAddress(form.consigneeAddress, false),
      message: "수하인 주소에 [~'@#$|^{}_!`]를 입력할 수 없습니다.",
    },
    {
      condition: validateAddress(form.notifyPartyAddress, false),
      message: "통지처 주소에 [~'@#$|^{}_!`]를 입력할 수 없습니다.",
    },
  ];

  const importValidationList = [
    {
      condition: !validatePackageCount(form),
      message: "총 포장개수를 확인해주세요.",
    },
    {
      condition: form.importation?.MSN?.length !== 4,
      message: "MSN을 확인해주세요. (4자리)",
    },
    {
      condition:
        form.importation?.spclCode?.length < 2 ||
        form.importation?.spclCode?.length > 4,
      message: "특수화물코드를 확인해주세요. (2~4자리)",
    },
    {
      condition: form.importation?.assignCode?.length !== 1,
      message: "배정구분코드를 확인해주세요. (1자리)",
    },
    {
      condition: form.importation?.terminalCode?.length !== 8,
      message: "배정장소를 확인해주세요. (8자리)",
    },
    {
      condition: !form.importation?.ETA,
      message: "ETA를 입력해주세요.",
    },
    {
      condition: validateBRN(form, true),
      message: "사업자번호가 10자리가 맞는지 확인해주세요. (- 생략)",
    },
    {
      condition: form.MRN?.slice(-1) !== "I",
      message: "수입 MRN은 'I'로 끝나야 합니다.",
    },
  ];

  const exportValidationList = [
    {
      condition:
        form.exportation?.partialShipment !== "Y" &&
        form.exportation?.partialShipment !== "N",
      message: "분할선적여부를 확인해주세요. (Y, N)",
    },
    {
      condition: !form.packageCount,
      message: "포장개수를 입력해주세요.",
    },
    {
      condition: !form.exportation?.ETD,
      message: "ETD를 입력해주세요.",
    },
    {
      condition: !form.exportation?.declarationNumber,
      message: "수출신고번호를 입력해주세요.",
    },
    {
      condition: validateBRN(form, false),
      message: "사업자번호가 10자리가 맞는지 확인해주세요. (- 생략)",
    },
    {
      condition: form.MRN?.slice(-1) !== "E",
      message: "수출 MRN은 'E'로 끝나야 합니다.",
    },
  ];

  const validationList = [
    ...commonValidationList,
    ...(isImport ? importValidationList : exportValidationList),
  ];

  for (const { condition, message } of validationList) {
    if (condition) {
      handleSnackbarOpen(message, "warning");
      return false;
    }
  }

  return true;
};

export default validateForm;
