import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import SHIP_QUERY from "@sellernote/_shared/src/queries/forwarding/SHIP_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  ExportTrelloList,
  FetchTrelloListParams,
} from "@sellernote/_shared/src/types/forwarding/trello";

import ExportTrelloDetailModal from "./ExportTrelloDetailModal";

function ExportTrelloDetail({
  showsExportTrelloDetailModal,
  setShowsExportTrelloDetailModal,
  bidId,
  trelloListData,
  fetchTrelloListParams,
}: {
  showsExportTrelloDetailModal: boolean;
  setShowsExportTrelloDetailModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  bidId: number | undefined;
  trelloListData?: ExportTrelloList[];
  fetchTrelloListParams?: FetchTrelloListParams;
}) {
  const { data: trelloDetailData } = TRELLO_BID_QUERY.useGetExportTrelloDetail({
    bidId,
  });

  const { data: shipList } = SHIP_QUERY.useGetShipList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const { partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: 0,
    enabled: true,
    pageFlag: false,
    region: trelloDetailData?.locale ?? "KR",
  });

  return (
    <ExportTrelloDetailModal
      trelloDetailData={trelloDetailData}
      shipList={shipList}
      exchangeRateList={exchangeRateList}
      partnerList={partnerList}
      showsExportTrelloDetailModal={showsExportTrelloDetailModal}
      setShowsExportTrelloDetailModal={setShowsExportTrelloDetailModal}
      trelloListData={trelloListData}
      fetchTrelloListParams={fetchTrelloListParams}
    />
  );
}

export default ExportTrelloDetail;
