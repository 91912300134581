const FINANCE_USER_DISABLED_OPTION_LIST = [
  "beforeContactPartner",
  "contactingPartner",
  "scheduling",
  "moving",
];

const DISABLED_OPTION_LIST = [
  "inSettlement",
  "settlementConfirmed",
  "settlementSecondConfirmed",
  "settlementComplete",
  "portEntryAndPrepareCustoms",
  "payment",
  "completeCustoms",
  "delivering",
  "finished",
  "canceled",
  "failed",
];

export { FINANCE_USER_DISABLED_OPTION_LIST, DISABLED_OPTION_LIST };
