import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div`
  padding: 0;
  background-color: #fff;

  ${mobile(css`
    margin: 0;
    display: flex;
    justify-content: center;
  `)}

  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw.gm-style-iw-c {
    border-radius: 2px;
    background-color: ${COLOR.bk_40};
    padding: 0px !important;
    transform: none;

    .gm-style-iw-ch {
      padding-top: 0px;
    }

    .gm-ui-hover-effect {
      display: none !important;
    }
  }

  .gm-style-iw-t {
    right: -10px !important;
    top: -4px !important;
  }

  .gm-style-iw-tc {
    display: none;
  }

  .info-container {
    border-radius: 2px;
    padding: 0px !important;
    transform: translate(65%, 100%);
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);

    .departure,
    .destination {
      background-color: ${COLOR.bk};
      padding: 2px 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 2px;
    }

    .waypoint {
      background-color: ${COLOR.bk_40};
      padding: 2px 4px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 2px;
    }

    .title {
      ${setFontStyle("SubHead4")};
      color: ${TEXT_COLOR.white_1};
      display: flex;
      gap: 4px;
      align-items: center;
    }

    .port {
      ${setFontStyle("Body4")};
      padding-left: 16px;
      color: ${TEXT_COLOR.white_1};
    }
  }
`;

export default {
  container,
};
