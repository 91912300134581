import { Box, Divider } from "@mui/material";

import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";

import Schedule from "./Schedule";
import TitleAndShipmentInfo from "./TitleAndShipmentInfo";

export default function ShipmentSchedule({
  trelloDetail,
}: {
  trelloDetail: TrelloBidDetail;
}) {
  return (
    <Box sx={{ background: "#e8e8e8", padding: "10px", marginTop: "12px" }}>
      <TitleAndShipmentInfo trelloDetail={trelloDetail} />

      <Divider sx={{ marginTop: "8px" }} />

      <Schedule trelloDetail={trelloDetail} />
    </Box>
  );
}
