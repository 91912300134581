import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";

import { atomWithReset } from "jotai/utils";

const TRADING_STATEMENT_DEFAULT_EXCHANGE_RATE_LIST = atomWithReset<
  ExchangeRate[]
>([]);

const TRADING_STATEMENT_DEFAULT_CBM = atomWithReset(0);

const TRADING_STATEMENT_DEFAULT_TON = atomWithReset(0);

const TRADING_STATEMENT_DEFAULT_PACKAGE = atomWithReset("");

const TRADING_STATEMENT_DEFAULT_DIRECT_RTON = atomWithReset(0);

const TRADING_STATEMENT_EXCHANGE_DATE = atomWithReset("");

export default {
  TRADING_STATEMENT_DEFAULT_EXCHANGE_RATE_LIST,
  TRADING_STATEMENT_DEFAULT_CBM,
  TRADING_STATEMENT_DEFAULT_TON,
  TRADING_STATEMENT_DEFAULT_PACKAGE,
  TRADING_STATEMENT_DEFAULT_DIRECT_RTON,
  TRADING_STATEMENT_EXCHANGE_DATE,
};
