import { useCallback } from "react";
import { useQueryClient } from "react-query";

import {
  GET_TRELLO_BID_FINISHED_LIST_REQ,
  GET_TRELLO_BID_LIST_REQ,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  ExportTrelloList,
  FetchTrelloListParams,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";

export default function useRenewTrelloListQueryData<T>({
  trelloListData,
  fetchTrelloListParams,
  serviceType,
}: {
  trelloListData: TrelloBidList[] | ExportTrelloList[];
  fetchTrelloListParams: FetchTrelloListParams;
  serviceType: string;
}) {
  const queryClient = useQueryClient();

  const { region, corpSizeType, blKind, ...trelloParams } =
    fetchTrelloListParams || {};

  const renewTrelloListQueryData = useCallback(
    ({
      shipmentId,
      renewData,
      renewOpCheckPointData,
      isFinished,
      isImport,
    }: {
      shipmentId: number;
      renewData?: T;
      renewOpCheckPointData?: T;
      isFinished: boolean;
      isImport: boolean;
    }) => {
      const trelloListParams = {
        ...trelloParams,
        ...(region === "all" ? [] : { region }),
        ...(corpSizeType === "all" ? [] : { corpSizeType }),
        ...(blKind === "all" ? [] : { blKind }),
        status: "inProgress",
        perPage: 300,
        serviceType:
          serviceType === "consolidation" ? "consolidation" : undefined,
      } as GET_TRELLO_BID_LIST_REQ;

      const finishedTrelloListParams = {
        ...trelloParams,
        ...(region === "all" ? [] : { region }),
        ...(corpSizeType === "all" ? [] : { corpSizeType }),
        ...(blKind === "all" ? [] : { blKind }),
        status: "finished",
        projectStatus: "finished",
        perPage: 200,
        serviceType:
          serviceType === "consolidation" ? "consolidation" : undefined,
      } as GET_TRELLO_BID_FINISHED_LIST_REQ;

      // 상태에 따라 데이터를 분리
      const filteredTrelloListData = [...trelloListData].filter((v) => {
        if (isFinished) {
          return v.projectStatus === "finished";
        }
        return v.projectStatus !== "finished";
      });

      // 데이터가 있는지 확인
      const targetTrelloListItem = filteredTrelloListData.find((v) => {
        return v.id === shipmentId;
      });

      // 일치하는 데이터가 있다면 이전 쿼리 데이터를 가져와 반영한 뒤 갱신
      if (targetTrelloListItem) {
        // API 요청으로 인해 변경된 값을 반영한 새로운 데이터
        const newTrelloListData = filteredTrelloListData.map((v) => {
          if (v.id === shipmentId) {
            return {
              ...v,
              management: { ...v.management, ...renewData },
              opCheckPoint: { ...v.opCheckPoint, ...renewOpCheckPointData },
            };
          }
          return v;
        });

        // 종료 트렐로 리스트일 때
        if (isFinished) {
          if (isImport) {
            const oldQueryData = queryClient.getQueryData(
              TRELLO_BID_QUERY_KEY_GEN.getTrelloBidFinishedList({
                ...finishedTrelloListParams,
              })
            );

            if (typeof oldQueryData === "object") {
              queryClient.setQueryData(
                TRELLO_BID_QUERY_KEY_GEN.getTrelloBidFinishedList({
                  ...finishedTrelloListParams,
                }),
                { ...oldQueryData, data: newTrelloListData }
              );
            }

            return;
          }

          const oldQueryData = queryClient.getQueryData(
            TRELLO_BID_QUERY_KEY_GEN.getExportTrelloFinishedList({
              ...finishedTrelloListParams,
            })
          );

          if (typeof oldQueryData === "object") {
            queryClient.setQueryData(
              TRELLO_BID_QUERY_KEY_GEN.getExportTrelloFinishedList({
                ...finishedTrelloListParams,
              }),
              { ...oldQueryData, data: newTrelloListData }
            );
          }

          return;
        }

        if (isImport) {
          // 진행 중 트렐로 리스트
          const oldQueryData = queryClient.getQueryData(
            TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
              ...trelloListParams,
            })
          );

          if (typeof oldQueryData === "object") {
            queryClient.setQueryData(
              TRELLO_BID_QUERY_KEY_GEN.getTrelloBidList({
                ...trelloListParams,
              }),
              { ...oldQueryData, data: newTrelloListData }
            );
          }

          return;
        }

        const oldQueryData = queryClient.getQueryData(
          TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
            ...trelloListParams,
          })
        );

        if (typeof oldQueryData === "object") {
          queryClient.setQueryData(
            TRELLO_BID_QUERY_KEY_GEN.getExportTrelloList({
              ...trelloListParams,
            }),
            { ...oldQueryData, data: newTrelloListData }
          );
        }

        return;
      }

      // 일치하는 데이터가 없을 시에는 API 재요청
      queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloBidList());

      return;
    },
    [
      blKind,
      corpSizeType,
      queryClient,
      region,
      serviceType,
      trelloListData,
      trelloParams,
    ]
  );

  return { renewTrelloListQueryData };
}
