import { Grid } from "@mui/material";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "../../../../../../components/SADSTable";

type CellKey =
  | "품명"
  | "포장유형"
  | "부피"
  | "중량"
  | "수량"
  | "위험물"
  | "냉동/냉장여부"
  | "2단적재";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명" },
  { cellKey: "포장유형", label: "포장유형" },
  { cellKey: "부피", label: "부피", align: "right", width: 240 },
  { cellKey: "중량", label: "중량", align: "right" },
  { cellKey: "수량", label: "수량", align: "right" },
  { cellKey: "위험물", label: "위험물", align: "right" },
  { cellKey: "냉동/냉장여부", label: "냉동/냉장여부", align: "right" },
  { cellKey: "2단적재", label: "2단적재", align: "right" },
];

function LCLAndAIRItemList({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const bodyRowList: TableBodyRow<CellKey>[] = (
    bidDetail.itemGroupList || []
  ).map(({ item, containerInfo }) => {
    return {
      rowKey: item.id,
      품명: { value: item.name },
      포장유형: { value: item.packingType },
      부피: {
        value: item.horizontal
          ? `${item.cbm}CBM(${item.horizontal}x${item.vertical}x${item.height}) ${item.volumeUnit}`
          : `${item.cbm}CBM`,
      },
      중량: {
        value: (
          <>
            {item.weight}
            {item.weightUnit}
          </>
        ),
      },
      수량: { value: toThousandUnitFormat(item.quantity) },
      위험물: {
        value: changeBooleanValueToKr(item.isDangerous),
      },
      "냉동/냉장여부": {
        value: changeBooleanValueToKr(item.needRefrigeration),
      },
      "2단적재": { value: changeBooleanValueToKr(item.canStack) },
    };
  });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>
    </Grid>
  );
}

export default LCLAndAIRItemList;
