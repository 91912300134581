import styled from "styled-components";

const drawerBody = styled.div`
  width: 1200px;
`;

const header = styled.div`
  padding: 20px;
  display: flex;
`;

const documentContainer = styled.div`
  display: flex;

  .create-container {
    width: 50%;
    > .title {
      margin-top: 32px;
      justify-content: center;
      display: flex;
      align-items: flex-end;
    }

    > .input-container {
      margin-top: 32px;

      > .warehouse-name {
        margin-bottom: 32px;
      }

      > .company {
        margin-bottom: 32px;
      }

      > .bl-number {
        margin-bottom: 32px;
      }

      > .package-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
      }

      > .comment {
        width: 400px;
        margin-bottom: 32px;
      }

      > .partner-name {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
`;

const uploadButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const downloadButton = styled.div`
  position: absolute;
  top: 10px;
  right: 50%;
`;

const updateWarehouseInfoButton = styled.div`
  position: absolute;
  top: 10px;
  right: 75%;
`;

const preview = styled.div`
  width: 595px;
`;

export default {
  drawerBody,
  header,
  documentContainer,
  uploadButton,
  downloadButton,
  preview,
  updateWarehouseInfoButton,
};
