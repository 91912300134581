import styled from "styled-components";

const drawerBody = styled.div`
  width: 1200px;
`;

const header = styled.div`
  padding: 20px;
  display: flex;
`;

const documentContainer = styled.div`
  display: flex;

  .create-container {
    width: 50%;

    > .title {
      margin-top: 32px;
      text-align: center;
    }

    > .input-container {
      margin-top: 32px;

      > .text-field-wrapper {
        margin-bottom: 24px;
      }

      > .text-field-container {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 24px;
      }
    }
  }
`;

const uploadButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const downloadButton = styled.div`
  position: absolute;
  top: 10px;
  right: 50%;
`;

const preview = styled.div`
  width: 595px;
`;
export default {
  drawerBody,
  header,
  documentContainer,
  uploadButton,
  downloadButton,
  preview,
};
