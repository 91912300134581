import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { AdminShipmentInfoForm } from "@sellernote/_shared/src/types/forwarding/adminBid";
import AutoCompleteWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/AutoCompleteWithReactHookForm";

function IncotermsForm({
  incotermsDefaultValueAndOptions,
}: {
  incotermsDefaultValueAndOptions: {
    defaultValue: string;
    options: string[];
  };
}) {
  const { control, setValue } = useFormContext<AdminShipmentInfoForm>();

  const [prevIncotermsDefaultValue, setPrevIncotermsDefaultValue] = useState(
    incotermsDefaultValueAndOptions.defaultValue
  );

  if (
    incotermsDefaultValueAndOptions.defaultValue !== prevIncotermsDefaultValue
  ) {
    setPrevIncotermsDefaultValue(incotermsDefaultValueAndOptions.defaultValue);
    setValue("incoterms", incotermsDefaultValueAndOptions.defaultValue, {
      shouldDirty: true,
    });
  }

  return (
    <Box>
      <Typography variant="subtitle1" fontWeight={700}>
        인코텀즈
      </Typography>

      <Box>
        <AutoCompleteWithReactHookForm
          name={"incoterms"}
          control={control}
          options={incotermsDefaultValueAndOptions.options}
          required={true}
        />
      </Box>
    </Box>
  );
}

export default IncotermsForm;
