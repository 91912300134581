import { atomWithReset } from "jotai/utils";

const ADMIN_LOGGED_IN = atomWithReset<boolean | undefined>(undefined);

const PARTNER_ADMIN_LOGGED_IN = atomWithReset<boolean | undefined>(undefined);

export default {
  ADMIN_LOGGED_IN,
  PARTNER_ADMIN_LOGGED_IN,
};
