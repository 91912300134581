import { Box, TextField, Typography } from "@mui/material";
import blue from "@mui/material/colors/blue";

import { useUpdatedScheduleForm } from "../hooks";
import getCommonTitleStyle from "../utils";

export default function VoyageNumber({
  index,
  voyageNo,
  isFormDisabled,
  highlightsVoyageNo,
  isAIR,
}: {
  index: number;
  voyageNo: undefined | string;
  isFormDisabled: boolean;
  highlightsVoyageNo: boolean;
  isAIR: boolean;
}) {
  const { handleFormUpdate } = useUpdatedScheduleForm();

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: 140 }}>
      <Typography style={getCommonTitleStyle(index)} variant="h6">
        {isAIR ? "편명" : "항차"}
      </Typography>

      <TextField
        style={{ marginTop: 40 }}
        sx={{
          input: highlightsVoyageNo
            ? { color: blue[500], fontWeight: "bold" }
            : {},
        }}
        size="small"
        label={isAIR ? "편명" : "항차"}
        value={voyageNo ?? ""}
        variant="outlined"
        onChange={(e) => {
          handleFormUpdate({
            index,
            updateFunction: (prev) => ({
              ...prev,
              voyageNo: e.target.value,
            }),
          });
        }}
        disabled={isFormDisabled}
      />
    </Box>
  );
}
