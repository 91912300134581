import { TableCell, TableHead, TableRow } from "@mui/material";

import { TABLE_COLUMN_LIST } from "./constants";

export default function HistoryTableHead() {
  return (
    <TableHead>
      <TableRow>
        {TABLE_COLUMN_LIST.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{
              minWidth: column.minWidth,
              background: "#2e3135",
              color: "white",
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
