import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../hooks/useSnackbar";

import LegacyTable from "../../../../../components/LegacyTable";

import CancelInvoiceModal from "./CancelInvoiceModal";
import DepositCommentModal from "./DepositCommentModal";
import { useDepositCommentModalProps } from "./DepositCommentModal/useDepositCommentModalProps";
import DepositHistoryModal from "./DepositHistoryModal";
import useSalesManagementTableRows from "./useSalesManagementTableRows";
import useTrelloSalesManagementTableData from "./useTrelloSalesManagementTableData";

function SalesManagement({
  trelloDetailData,
  isExport,
}: {
  trelloDetailData: TrelloBidDetail;
  isExport?: boolean;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const queryClient = useQueryClient();

  const history = useHistory();

  /** 세금계산서 요청 상태인지 확인하는 상태 (발행 X) */
  const [isRequestedAndNotIssued, setIsRequestedAndNotIssued] = useState(false);
  const [showsCancelInvoiceModal, setShowsCancelInvoiceModal] = useState(false);
  const [invoiceType, setInvoiceType] = useState<InvoiceType>("invoice");
  const [showsInvoicePopover, setShowsInvoicePopover] =
    useState<HTMLButtonElement | null>(null);
  const [showsDepositHistoryModal, setShowsDepositHistoryModal] =
    useState(false);
  const [invoiceId, setInvoiceId] = useState(0);
  const [billingAmount, setBillingAmount] = useState(0);
  const [paymentInvoiceId, setPaymentInvoiceId] = useState(0);

  const { trelloSalesManagementTableData } =
    useTrelloSalesManagementTableData(trelloDetailData);

  const {
    commentModalProps,
    showsDepositCommentModal,
    handleModalOpen,
    handleModalClose,
  } = useDepositCommentModalProps({
    trelloDetailData,
    invoiceType,
    invoiceId,
    paymentInvoiceId,
  });

  const [_, setSessionInvoiceId] = useSessionStorage<string | null>(
    "invoiceId"
  );

  const {
    mutate: requestInvoiceIssue,
    ResponseHandler: ResponseHandlerOfRequestInvoiceIssue,
  } = TRELLO_BID_QUERY.useRequestInvoiceIssue({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.trelloDetail());
      },
      customizeMessage: () => ({
        title: "발행을 요청했습니다.",
      }),
    },
  });

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const handleFileDownload = useCallback(
    (downloadKey: string | undefined) => {
      if (!downloadKey) {
        return;
      }

      getFileDownloadUrl(
        {
          pathParams: {
            key: downloadKey,
          },
        },
        {
          onSuccess: ({ data }) => {
            window.location.href = data.url;
          },

          onError: () => {
            handleSnackbarOpen("파일 다운로드에 실패했습니다.", "error");
          },
        }
      );
    },
    [getFileDownloadUrl, handleSnackbarOpen]
  );

  const handleInvoiceIssueClick = useCallback(
    (invoiceId: number | undefined) => {
      return () => {
        if (!invoiceId) {
          handleSnackbarOpen("거래명세서 아이디가 없습니다.", "error");
          return;
        }

        requestInvoiceIssue({ pathParams: { invoiceId } });
      };
    },
    [handleSnackbarOpen, requestInvoiceIssue]
  );

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setTrelloCardId(trelloDetailData.id);
    setSessionInvoiceId(null);

    history.push(
      `/tradingStatement/${e.currentTarget.id}/${trelloDetailData.id}/${
        isExport ? "export" : "import"
      }`
    );
  };

  const handlePopoverClose = () => {
    setShowsInvoicePopover(null);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowsInvoicePopover(event.currentTarget);
  };

  const handleMoveToSalesManagement = useCallback(() => {
    setTrelloCardId(trelloDetailData.id);
    history.push("/settlement/salesManagement");
  }, [history, setTrelloCardId, trelloDetailData.id]);

  const { headCells, rows } = useSalesManagementTableRows({
    trelloSalesManagementTableData,
    trelloDetailData,
    handleFileDownload,
    handleInvoiceIssueClick,
    setInvoiceType,
    setShowsCancelInvoiceModal,
    setBillingAmount,
    setInvoiceId,
    setShowsDepositHistoryModal,
    setPaymentInvoiceId,
    handleModalOpen,
    setIsRequestedAndNotIssued,
  });

  return (
    <Grid container direction="column" sx={{ marginBottom: "16px" }}>
      <Grid item xs={1}>
        <Typography
          variant="subtitle1"
          component="div"
          onClick={handleMoveToSalesManagement}
          sx={{ color: blue[700], cursor: "pointer" }}
        >
          매출 관리
        </Typography>
      </Grid>

      <Grid item>
        <LegacyTable headCells={headCells} rows={rows} />
      </Grid>

      {APP_NAME === "shipda-admin" && (
        <Grid item>
          <Button variant="text" onClick={handlePopoverOpen}>
            추가
          </Button>

          <Popover
            disableScrollLock={true}
            open={Boolean(showsInvoicePopover)}
            anchorEl={showsInvoicePopover}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Paper>
              <MenuList autoFocusItem>
                {/* 수출일때는 기타입금만 추가 가능 */}
                {!isExport && (
                  <MenuItem id="warehouseReceipt" onClick={handleMenuItemClick}>
                    창고료
                  </MenuItem>
                )}
                <MenuItem id="etcDeposit" onClick={handleMenuItemClick}>
                  기타 입금
                </MenuItem>
              </MenuList>
            </Paper>
          </Popover>
        </Grid>
      )}

      {showsCancelInvoiceModal && invoiceType && (
        <CancelInvoiceModal
          showsCancelInvoiceModal={showsCancelInvoiceModal}
          setShowsCancelInvoiceModal={setShowsCancelInvoiceModal}
          setInvoiceId={setInvoiceId}
          invoiceId={invoiceId}
          bidId={trelloDetailData.id}
          invoiceType={invoiceType}
          isRequestedAndNotIssued={isRequestedAndNotIssued}
        />
      )}

      {showsDepositHistoryModal && (
        <DepositHistoryModal
          setShowsDepositHistoryModal={setShowsDepositHistoryModal}
          showsDepositHistoryModal={showsDepositHistoryModal}
          invoiceId={invoiceId}
          billingAmount={billingAmount}
          bidId={trelloDetailData.id}
        />
      )}

      {showsDepositCommentModal && commentModalProps && (
        <DepositCommentModal
          showsDepositCommentModal={showsDepositCommentModal}
          handleModalClose={handleModalClose}
          paymentInvoiceId={paymentInvoiceId}
          amount={commentModalProps?.amount}
          name={commentModalProps?.name}
          currency={"KRW"}
          commentData={commentModalProps?.comment}
          refetchListData={() =>
            queryClient.invalidateQueries(
              TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
            )
          }
        />
      )}

      {ResponseHandlerOfRequestInvoiceIssue}
    </Grid>
  );
}

export default SalesManagement;
