import { atom } from "jotai";

import {
  FetchTrelloListParams,
  ShipmentScheduleRoute,
} from "@sellernote/_shared/src/types/forwarding/trello";

import { atomWithReset } from "jotai/utils";

const TRELLO_LIST_FILTER_DATA = atomWithReset<FetchTrelloListParams>({
  region: "all",
  adminId: 0,
  corpSizeType: "all",
  BL: undefined,
  bidId: undefined,
  company: undefined,
  blKind: "all",
});

const SHIPMENT_SCHEDULE_LIST = atom<ShipmentScheduleRoute[]>([]);

/**
 * 운송 스케줄 변경사항 체크를 위한 전역 상태.
 * 운송 스케줄 저장시 SHIPMENT_SCHEDULE_LIST 값과 비교하여 변경사항이 있는지 체크
 *
 * @see ScheduleForm 컴포넌트 Mount시에 API 응답값 바인딩
 */
const PREVIOUS_SHIPMENT_SCHEDULE_LIST = atom<ShipmentScheduleRoute[]>([]);

export default {
  TRELLO_LIST_FILTER_DATA,
  SHIPMENT_SCHEDULE_LIST,
  PREVIOUS_SHIPMENT_SCHEDULE_LIST,
};
