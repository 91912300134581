import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function TrelloModalExitDialog({
  opensDialog,
  onDialogClose,
  onModalClose,
}: {
  opensDialog: boolean;
  onDialogClose: () => void;
  onModalClose: () => void;
}) {
  return (
    <Dialog
      open={opensDialog}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        저장하지 않은 스케줄 변경사항이 있습니다.
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          그래도 닫으시겠습니까?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={onDialogClose}>
          아니오
        </Button>

        <Button onClick={onModalClose} autoFocus>
          네
        </Button>
      </DialogActions>
    </Dialog>
  );
}
