import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Input } from "antd";

import ADMIN_SLACK_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SLACK_QUERY";

import Modal from "../../../../../../../components/Modal";

const CancelWithdrawModal = ({
  setShowCancelWithdrawModal,
  showCancelWithdrawModal,
  partnerCompanyId,
  bidId,
}: {
  setShowCancelWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>;
  showCancelWithdrawModal: boolean;
  partnerCompanyId: number;
  bidId: number;
}) => {
  const [cancelReason, setCancelReason] = useState("직접 입력");
  const [etcReason, setEtcReason] = useState("");

  const {
    mutate: cancelWithdraw,
    ResponseHandler: ResponseHandlerOfUseCancelWithdraw,
  } = ADMIN_SLACK_QUERY.useCancelWithdraw({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setShowCancelWithdrawModal(false);
      },
      customizeMessage: () => ({
        title: "출금액 취소 슬랙 알람을 요청했습니다.",
      }),
    },
  });

  const handleModalClose = useCallback(() => {
    setShowCancelWithdrawModal(false);
  }, [setShowCancelWithdrawModal]);

  const handleEtcReasonInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEtcReason(e.target.value);
    },
    []
  );

  const handleCancelReasonChange = useCallback((value: string) => {
    setCancelReason(value);
  }, []);

  const handleCancelWithDrawClick = useCallback(() => {
    cancelWithdraw({
      bidId,
      partnerCompanyId,
      cancelReason: cancelReason === "직접 입력" ? etcReason : cancelReason,
    });
  }, [cancelWithdraw, bidId, partnerCompanyId, cancelReason, etcReason]);

  return (
    <>
      <Modal
        isOpened
        handleClose={handleModalClose}
        modalBody={
          <Box>
            <Typography variant="h6">출금요청 취소 사유입력</Typography>

            <FormControl fullWidth size="small">
              <Select
                value={cancelReason}
                onChange={(e) => handleCancelReasonChange(e.target.value)}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  mt: 2,
                }}
              >
                <MenuItem value="직접 입력">{"직접 입력"}</MenuItem>
                <MenuItem value="항목 추가">{"항목 추가"}</MenuItem>
                <MenuItem value="항목 삭제">{"항목 삭제"}</MenuItem>
                <MenuItem value="출금요청 실수">{"출금요청 실수"}</MenuItem>
              </Select>
            </FormControl>

            {cancelReason === "직접 입력" && (
              <Input
                placeholder="취소 요청 사유를 입력하세요"
                onChange={handleEtcReasonInputChange}
                value={etcReason}
                style={{ marginTop: 10 }}
              />
            )}

            <Button
              fullWidth
              disabled={cancelReason === "직접 입력" && !etcReason}
              onClick={handleCancelWithDrawClick}
              sx={{ mt: 2 }}
              variant="contained"
            >
              요청하기
            </Button>
          </Box>
        }
      />

      {ResponseHandlerOfUseCancelWithdraw}
    </>
  );
};

export default CancelWithdrawModal;
