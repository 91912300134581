/** 폼 수정, 저장이 가능한 Project Status(운송 완료 이전) */
const ACTIVE_STATUS_LIST = [
  /** 수출자 컨택 중 */
  "contactingPartner",
  /** 화물 준비 중 */
  "waitingFreight", // 수출 전용
  /** 출항 준비 중 */
  "scheduling",
  /** 공 컨테이너 반출 */
  "containerCarryOut",
  /** 현지 화물 픽업 완료 */
  "containerPickup",
  /** 국내 CFS 픽업 완료 */
  "domesticImportCFS", // 수출 전용
  /** 현지 터미널 반입 완료 */
  "gateIn",
  /** 선적 완료 */
  "loaded",
  /** 출항 완료 */
  "moving",
];

export default ACTIVE_STATUS_LIST;
