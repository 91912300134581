import { Box, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  ExportTrelloList,
  PartnerTrelloList,
  ScheduleUpdatedItems,
  TrelloBidList,
  TrelloIssueFilter,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getDifferentDate,
  toFormattedDate,
} from "@sellernote/_shared/src/utils/common/date";
import { toFormattedDifferentDate } from "@sellernote/_shared/src/utils/forwarding/freeTime";
import {
  checkETAExpires,
  checkIfCustomsClearanceHasStarted,
  checkIfETDExpiresWhenScheduling,
  checkIsInlandFarePaymentRequest,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import koreaFlag from "../../images/regionFlag/korea.webp";

const getTrelloCardTitle = (
  cardData: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  return (
    <Box>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: 14,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: "bold",
          }}
        >
          {cardData.team.company ?? "-"}
        </Typography>

        <img
          src={koreaFlag}
          alt={"KR"}
          style={{ width: "22px", height: "15px" }}
        />
      </div>

      <Typography
        sx={{
          fontSize: 12,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        ({cardData.team.name ?? "-"})
      </Typography>
    </Box>
  );
};

const getShipmentSchedule = (
  scheduleUpdatedItems: ScheduleUpdatedItems[] | null,
  ATA: string | null,
  ATD: string | null,
  ETA: string | null,
  ETD: string | null
) => {
  const highlightsText = (target: ScheduleUpdatedItems) => {
    if (!scheduleUpdatedItems?.length) return {};

    if (scheduleUpdatedItems.includes(target)) {
      return { color: blue[500] };
    }

    return {};
  };

  const COMMON_STYLE = {
    fontWeight: "bold",
    fontSize: 14,
    letterSpacing: -0.5,
  } as const;

  const showsSchedule = Boolean(ATD || ETD);

  if (!showsSchedule) return null;

  return (
    <>
      <span style={{ ...COMMON_STYLE, ...highlightsText("ETD") }}>
        {toFormattedDate(ATD, "YY-MM-DD") || toFormattedDate(ETD, "YY-MM-DD")}
      </span>

      {`${" "}~${" "}`}

      <span style={{ ...COMMON_STYLE, ...highlightsText("ETA") }}>
        {toFormattedDate(ATA, "YY-MM-DD") || toFormattedDate(ETA, "YY-MM-DD")}
      </span>
    </>
  );
};

const getTrelloCardCommonBody = (
  trelloListData: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  return (
    <>
      <Typography sx={{ fontSize: 12 }} variant="body2">
        {trelloListData.management.memo}
      </Typography>

      <Typography
        variant="body2"
        component={"div"}
        sx={{ fontWeight: "bold", color: grey[700] }}
      >
        {trelloListData.id}{" "}
        {trelloListData?.poNumber &&
          trelloListData.poNumber.length > 0 &&
          `${
            trelloListData.poNumber.length > 1
              ? `/ ${trelloListData.poNumber[0]} 외 ${trelloListData.poNumber.length}`
              : `/ ${trelloListData.poNumber[0]}`
          }`}
      </Typography>

      {getShipmentSchedule(
        trelloListData.management.scheduleUpdatedItems,
        trelloListData.management.fullATA,
        trelloListData.management.fullATD,
        trelloListData.management.fullETA,
        trelloListData.management.fullETD
      )}
    </>
  );
};

const getServiceType = ({ pathname }: { pathname: string }) => {
  if (pathname.includes("consolidation")) return "consolidation";

  if (pathname.includes("export")) return "export";

  return "import";
};

const checkScheduleUpdate = (updatedList: ScheduleUpdatedItems[] | null) => {
  if (APP_NAME !== "shipda-admin") return false;

  if (!updatedList) return false;

  return Boolean(updatedList.length);
};

const checkStatusUpdate = (externalApiUpdatedAt: string | null) => {
  if (APP_NAME !== "shipda-admin") return false;

  return Boolean(externalApiUpdatedAt);
};

/** 반납, 반출일 데드라인 체크. 프리타임 남은일자 3일부터 표기. */
const checkDeadlineFreeTime = (data: TrelloBidList) => {
  const {
    freeTimeLFD,
    opCheckPoint: { isFreeTimeChecking },
  } = data;

  // 이미 매니저가 체크한 경우 (트렐로 상세에서 프리타임 주의 해제)
  if (isFreeTimeChecking) return false;

  const today = new Date();

  return freeTimeLFD
    ?.map((container) => {
      const { storageLFD, demurrageLFD, detentionLFD, combinedLFD } = container;

      if (!storageLFD) return false;

      const isCombined = !!combinedLFD;

      const isStorageDeadline =
        getDifferentDate(
          toFormattedDifferentDate(today),
          toFormattedDifferentDate(storageLFD),
          "day"
        ) <= 3;

      // combined - DET + DEM 으로 combinedLFD 만 확인.
      // combine이 아닐 때, DET, DEM 각각 확인.
      const isDETAndDEMDeadline = (() => {
        if (isCombined) return getDifferentDate(today, combinedLFD, "day") <= 3;

        if (!demurrageLFD || !detentionLFD) return false;

        const isDEMDeadline =
          getDifferentDate(
            toFormattedDifferentDate(today),
            toFormattedDifferentDate(demurrageLFD),
            "day"
          ) <= 3;
        const isDETDeadline =
          getDifferentDate(
            toFormattedDifferentDate(today),
            toFormattedDifferentDate(detentionLFD),
            "day"
          ) <= 3;

        return isDEMDeadline || isDETDeadline;
      })();

      return isStorageDeadline || isDETAndDEMDeadline;
    })
    .some((isDeadline) => isDeadline);
};

/** 트렐로 카드의 이슈값을 구하는 함수를 모은 객체 */
const trelloIssueFunctionRecord: {
  [key in TrelloIssueFilter]: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => boolean;
} = {
  /**  이슈 없음 */
  none: () => true,

  /** ETD 임박 */
  ETAExpiration: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => {
    return (
      trelloShipmentItem.projectStatus === "moving" &&
      checkETAExpires(trelloShipmentItem)
    );
  },

  /** ETA 임박 */
  ETDExpiration: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => {
    return (
      (trelloShipmentItem.projectStatus === "scheduling" ||
        trelloShipmentItem.projectStatus === "containerCarryOut" ||
        trelloShipmentItem.projectStatus === "containerPickup" ||
        trelloShipmentItem.projectStatus === "gateIn" ||
        trelloShipmentItem.projectStatus === "loaded") &&
      checkIfETDExpiresWhenScheduling(trelloShipmentItem)
    );
  },

  /** 스케줄 변경 */
  scheduleChanged: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => {
    return checkScheduleUpdate(
      trelloShipmentItem.management.scheduleUpdatedItems
    );
  },

  /**  상태 변경 */
  statusChanged: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => checkStatusUpdate(trelloShipmentItem.management.externalApiUpdatedAt),

  /** 프리 타임 */
  deadlineFreeTime: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => {
    return (
      (trelloShipmentItem.projectStatus === "moving" ||
        trelloShipmentItem.projectStatus === "portEntryAndPrepareCustoms" ||
        trelloShipmentItem.projectStatus === "payment" ||
        trelloShipmentItem.projectStatus === "completeCustoms" ||
        trelloShipmentItem.projectStatus === "delivering" ||
        trelloShipmentItem.projectStatus === "finished") &&
      checkDeadlineFreeTime(trelloShipmentItem as TrelloBidList)
    );
  },

  /** 계산서 발행 */
  issuedInvoice: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => {
    return (
      "closingInvoice" in trelloShipmentItem &&
      (trelloShipmentItem.projectStatus === "moving" ||
        trelloShipmentItem.projectStatus === "portEntryAndPrepareCustoms" ||
        trelloShipmentItem.projectStatus === "payment" ||
        trelloShipmentItem.projectStatus === "completeCustoms" ||
        trelloShipmentItem.projectStatus === "delivering" ||
        trelloShipmentItem.projectStatus === "finished") &&
      trelloShipmentItem.closingInvoice === true
    );
  },

  /** 계산서 미발행 */
  unissuedInvoice: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => {
    return (
      "closingInvoice" in trelloShipmentItem &&
      (trelloShipmentItem.projectStatus === "moving" ||
        trelloShipmentItem.projectStatus === "portEntryAndPrepareCustoms" ||
        trelloShipmentItem.projectStatus === "payment" ||
        trelloShipmentItem.projectStatus === "completeCustoms" ||
        trelloShipmentItem.projectStatus === "delivering" ||
        trelloShipmentItem.projectStatus === "finished") &&
      trelloShipmentItem.closingInvoice === false
    );
  },

  /** 통관 완료 */
  completedCustoms: (
    trelloShipmentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => {
    if (
      APP_NAME === "shipda-admin" &&
      trelloShipmentItem.projectStatus === "completeCustoms"
    ) {
      return checkIsInlandFarePaymentRequest(trelloShipmentItem) || false;
    }

    if (
      APP_NAME === "partner-admin" &&
      (trelloShipmentItem.projectStatus === "moving" ||
        trelloShipmentItem.projectStatus === "portEntryAndPrepareCustoms" ||
        trelloShipmentItem.projectStatus === "completeCustoms")
    ) {
      return checkIfCustomsClearanceHasStarted(trelloShipmentItem);
    }

    return false;
  },
};

/** 트렐로 카드 이슈 필터에 선택된 값에 따라 리스트를 필터한다. */
function filterByTrelloCardIssue(
  filter: TrelloIssueFilter
): (
  trelloItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => boolean {
  const filterFunction = trelloIssueFunctionRecord[filter];

  return filterFunction;
}

/** 리스트를 스케줄 날짜 역순으로 정렬한다. */
function sortByShipmentScheduleDateDesc(
  dateType: "fullETD" | "fullETA" | "fullATA"
) {
  return (
    currentItem: TrelloBidList | ExportTrelloList | PartnerTrelloList,
    nextItem: TrelloBidList | ExportTrelloList | PartnerTrelloList
  ) => {
    const currentDate = currentItem.management[dateType];
    const nextDate = nextItem.management[dateType];

    /** 스케줄 날짜가 null인 경우 리스트 밑으로 내리기 위해 추가 */
    if (currentDate === null) return 1;
    if (nextDate === null) return -1;

    return new Date(nextDate).getTime() - new Date(currentDate).getTime();
  };
}

export {
  getTrelloCardTitle,
  getTrelloCardCommonBody,
  getServiceType,
  checkScheduleUpdate,
  checkStatusUpdate,
  checkDeadlineFreeTime,
  filterByTrelloCardIssue,
  sortByShipmentScheduleDateDesc,
};
