import styled from "styled-components";

const routeInfoSection = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
`;

const routeInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 272px;
`;

const routeInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
  height: 40px;
`;

const routeInfoItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex: 1;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 132px;
  }

  > .title {
    color: #4d70c3;
    text-align: left;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    text-transform: uppercase;
    align-self: stretch;
  }

  > .content {
    color: #424242;
    text-align: left;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: -0.054px;
  }
`;

const goodsApplySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 272px;
`;

const goodsApplyTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  align-self: stretch;

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 272px;
  }

  > .title {
    color: #4d70c3;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    align-self: stretch;
  }
`;

const goodsApplyContent = styled.div`
  color: #424242;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: -0.054px;
  width: 272px;
`;

export default {
  routeInfoSection,
  routeInfoColumn,
  routeInfoRow,
  routeInfoItemColumn,
  goodsApplySection,
  goodsApplyTitle,
  goodsApplyContent,
};
