import {
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

function AmountTable({
  billingAmount,
  totalDepositAmount,
}: {
  billingAmount: number;
  totalDepositAmount: number;
}) {
  const getDifferenceAmount = () => {
    if (billingAmount === totalDepositAmount) {
      return 0;
    }
    if (billingAmount < totalDepositAmount) {
      return toThousandUnitFormat(Math.abs(billingAmount - totalDepositAmount));
    }
    return (
      <Typography variant="body2" color="error">
        ({toThousandUnitFormat(Math.abs(billingAmount - totalDepositAmount))})
      </Typography>
    );
  };
  return (
    <TableContainer component={Paper}>
      <MUITable sx={{ minWidth: 400 }}>
        <TableBody>
          <TableRow>
            <TableCell sx={{ backgroundColor: blue[100], width: 100 }}>
              청구금액
            </TableCell>

            <TableCell align="right">{`${toThousandUnitFormat(
              billingAmount
            )}`}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ backgroundColor: blue[100], width: 100 }}>
              입금액
            </TableCell>

            <TableCell align="right">{`${toThousandUnitFormat(
              totalDepositAmount
            )}`}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ backgroundColor: blue[100], width: 100 }}>
              차액
            </TableCell>

            <TableCell align="right">{getDifferenceAmount()}</TableCell>
          </TableRow>
        </TableBody>
      </MUITable>
    </TableContainer>
  );
}

export default AmountTable;
