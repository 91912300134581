import { useFormContext } from "react-hook-form";
import { Box, Typography } from "@mui/material";

import { AdminShipmentInfoForm } from "@sellernote/_shared/src/types/forwarding/adminBid";

import RadioGroupWithReactHookForm from "../../../../../../components/RadioGroupWithReactHookForm";

import { inclusionConditionGroupOptionList } from "../constants";

function AIRCheckpoint() {
  const { control } = useFormContext<AdminShipmentInfoForm>();

  return (
    <Box>
      <Typography variant="subtitle2">국내부대비용</Typography>

      <RadioGroupWithReactHookForm
        name={"containDomesticFee"}
        control={control}
        radioGroupOption={inclusionConditionGroupOptionList}
        required={true}
      />
    </Box>
  );
}

export default AIRCheckpoint;
