import { atom } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ADMIN_FORWARDING_MANAGERS } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import { FetchTrelloListParams } from "@sellernote/_shared/src/types/forwarding/trello";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../auth";
import { FORWARDING_ADMIN_TRELLO_JOTAI_ATOMS } from ".";

const FETCH_TRELLO_LIST_PARAMS = atom((get) => {
  const currentAdminAuthInfo = get(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const trelloListFilterData = get(
    FORWARDING_ADMIN_TRELLO_JOTAI_ATOMS.TRELLO_LIST_FILTER_DATA
  );

  /** sessionStorage에 저장한 필터 데이터 */
  const sessionStorageFilterData = sessionStorage.getItem(
    "trelloListFilterData"
  );

  /** 파트너 어드민은 accountId를 따로 저장하지 않음 */
  const isForwardingManager =
    APP_NAME === "shipda-admin"
      ? Object.values(ADMIN_FORWARDING_MANAGERS).some(
          (manager) => manager.accountId === currentAdminAuthInfo.accountId
        )
      : false;

  const parsedFilterData = sessionStorageFilterData
    ? JSON.parse(sessionStorageFilterData)
    : undefined;

  /** sessionStorage에 데이터가 있다면 parse하고 없다면 jotai 전역 필터데이터로 정의 */
  const fetchTrelloListParams: FetchTrelloListParams = parsedFilterData
    ? {
        ...parsedFilterData,
      }
    : {
        ...trelloListFilterData,
        /** 처음 진입 시 포워딩 매니저가 아니라면 undefined로 아니라면 등록된 유저 id로 */
        adminId:
          trelloListFilterData.adminId === 0
            ? isForwardingManager
              ? currentAdminAuthInfo.id
              : undefined
            : trelloListFilterData.adminId,
      };

  return fetchTrelloListParams;
});

export default {
  FETCH_TRELLO_LIST_PARAMS,
};
