import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";

export default function ClearButton({
  isVisible,
  onValueClear,
}: {
  isVisible: boolean;
  onValueClear: () => void;
}) {
  return (
    <IconButton
      sx={{ visibility: isVisible ? "visible" : "hidden" }}
      onClick={onValueClear}
    >
      <ClearIcon />
    </IconButton>
  );
}
