const CONTAINER_STYLE = { display: "flex", alignItems: "center" };

const TITLE_STYLE = { fontWeight: 400, minWidth: 130, fontSize: 14 };

const CUSTOM_TEXT_FIELD_STYLE = {
  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
  "& input": { padding: "1.5px 5px", fontSize: 14 },
  "& .MuiInputBase-multiline": { padding: "1.5px 5px", fontSize: 14 },
  minWidth: 200,
};

export { CONTAINER_STYLE, TITLE_STYLE, CUSTOM_TEXT_FIELD_STYLE };
