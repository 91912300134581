import { useMemo } from "react";
import { useAtom } from "jotai";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import { ShipmentScheduleRoute } from "@sellernote/_shared/src/types/forwarding/trello";

import { PortOption } from "../types";

import { FORWARDING_ADMIN_TRELLO_ATOMS } from "../../../../../../../../../jotaiStates/trello";

function useUpdatedScheduleForm() {
  const [shipmentScheduleList, setShipmentScheduleList] = useAtom(
    FORWARDING_ADMIN_TRELLO_ATOMS.SHIPMENT_SCHEDULE_LIST
  );

  const handleFormUpdate = ({
    index,
    updateFunction,
  }: {
    index: number;
    updateFunction: (prev: ShipmentScheduleRoute) => ShipmentScheduleRoute;
  }) => {
    const copiedScheduleList = [...shipmentScheduleList];

    copiedScheduleList[index] = updateFunction(copiedScheduleList[index]);

    setShipmentScheduleList(copiedScheduleList);
  };

  return { handleFormUpdate };
}

function usePortOptionList() {
  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  /** 출발지/도착지 항구 Mui SelectBox에서 사용되는 항구 리스트 */
  const portOptionList: PortOption[] = useMemo(() => {
    if (!portList) return [];

    return portList.map((v) => {
      /** 한글 항구명이 없으면 영문 항구명을 노출 */
      return {
        value: v.name ?? v.nameEN,
        label: v.name ?? v.nameEN,
        id: v.id,
        name: v.name ?? v.nameEN,
        nameEN: v.nameEN,
      };
    });
  }, [portList]);

  return {
    portOptionList,
  };
}

export { useUpdatedScheduleForm, usePortOptionList };
