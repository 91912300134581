import { useState } from "react";
import { Box } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

import PreviewContent from "./PreviewContent";
import PreviewFooter from "./PreviewFooter";
import PreviewTypeTab from "./PreviewTypeTab";

export default function ArrivalNoticePreview({
  shipmentId,
  isImport,
}: {
  shipmentId: number;
  isImport: boolean;
}) {
  const [tab, setTab] = useState<"arrivalNotice" | "BL" | "exportDeclaration">(
    isImport ? "arrivalNotice" : "exportDeclaration"
  );
  const [previewIndex, setPreviewIndex] = useState(0);

  const handlePreviewIndexChange = (v: "+" | "-") => {
    if (v === "+") return setPreviewIndex((prev) => prev + 1);

    return setPreviewIndex((prev) => prev - 1);
  };

  const handleTabChange = (v: "arrivalNotice" | "BL" | "exportDeclaration") => {
    setPreviewIndex(0);
    setTab(v);
  };

  const { data } = TRELLO_BID_QUERY.useGetTrelloAttachments({
    bidId: shipmentId,
  });

  const previewContentList = data?.filter((v) => v.domain === tab);

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <PreviewTypeTab
        tab={tab}
        onTabChange={handleTabChange}
        isImport={isImport}
      />

      <PreviewContent previewContent={previewContentList?.[previewIndex]} />

      <PreviewFooter
        onPreviewIndexChange={handlePreviewIndexChange}
        isPreviousButtonDisabled={previewIndex === 0}
        /** 더 이상 파일이 없는 경우  */
        isNextButtonDisabled={
          !previewContentList?.length ||
          previewIndex + 1 === previewContentList?.length
        }
      />
    </Box>
  );
}
